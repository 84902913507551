import React, {useEffect, useState} from "react";

const useIsMobileScreen = (widthLimit) => {
    const [width, setWidth] = useState(typeof window !== "undefined" ? window.innerWidth : null)

    const handleWindowSizeChange = () => {
      setWidth(window.innerWidth)
    }

    useEffect(() => {
        window?.addEventListener('resize', handleWindowSizeChange)
        return () => {
            window?.removeEventListener('resize', handleWindowSizeChange)
        }
    }, [])

    return (width <= widthLimit)
}

export default useIsMobileScreen