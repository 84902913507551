import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { propTypes } from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';

import css from './TopbarDesktop.module.css';
import Spacer, { HORIZONTALL_AXIS, SMALL_SPACER } from '../../Spacer/Spacer';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import MessageIcon from '../../../assets/icons/message.png'
import { LOCALES } from '../../../translations/locales';
import Dropdown from '../../Dropdown/Dropdown';

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    history,
    onLocaleChange,
    currentLocale
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      inputRootClassName={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const options = [
    { 
      value: LOCALES.ENGLISH, 
      label: intl.formatMessage({ id: 'locales.english' }), 
    },
    { 
      value: LOCALES.LITHUANIAN, 
      label: intl.formatMessage({ id: 'locales.lithuanian' }), 
    },
    { 
      value: LOCALES.POLISH, 
      label: intl.formatMessage({ id: 'locales.polish' }), 
    },
  ]

  const notificationCountBadge =
    notificationCount > 0 ? <div className={css.notificationDot} /> : null

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ lastLocation: history.location.pathname }}
      state={{ prevPath: history.location.pathname }}
    >
      <div className={css.inboxIconWrapper}>
        <img className={css.inboxIcon} src={MessageIcon}/>
        {notificationCountBadge}
      </div>
      <h5 className={css.inboxLabel}>{intl.formatMessage({ id: 'top_bar.inbox_label' })}</h5>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem className={css.menuLinkWrapper} key="ProfilePage">
          <NamedLink 
            name="ProfilePage"
            params={{ id: currentUser?.id?.uuid || 1 }}
          >
            <span className={css.menuLinkText}>{intl.formatMessage({ id: 'top_bar.profile' })}</span>
          </NamedLink>
        </MenuItem>
        <MenuItem className={css.menuLinkWrapper} key="FavoriteListingsPage">
          <NamedLink 
            name="FavoriteListingsPage"
            params={{ id: currentUser?.id?.uuid || 1 }}
            state={{ prevPath: history.location.pathname }}
          >
            <span className={css.menuLinkText}>{intl.formatMessage({ id: 'top_bar.mobile_menu.favorite_listings' })}</span>
          </NamedLink>
        </MenuItem>
        <MenuItem className={css.menuLinkWrapper} key="AccountSettingsPage">
          <NamedLink name="AccountSettingsPage">
            <span className={css.menuLinkText}>{intl.formatMessage({ id: 'top_bar.account_settings_label' })}</span>
          </NamedLink>
        </MenuItem>
        <MenuItem className={css.menuLinkWrapper} key="AboutPage">
          <NamedLink name="AboutPage" >
            <span className={css.menuLinkText}>{intl.formatMessage({ id: 'top_bar.mobile_menu.about_page' })}</span>
          </NamedLink>
        </MenuItem>
        <MenuItem className={css.menuDividerWrapper} key="divider"><div className={css.divider}/></MenuItem>
        <MenuItem className={css.menuLinkWrapper} key="logout" onClick={onLogout}>
          <div className={css.logoutButton}onClick={onLogout}>
            <span className={css.menuLinkText}>{intl.formatMessage({ id: 'top_bar.log_out' })}</span>
          </div>
        </MenuItem>
        <MenuItem key="spacer">
          <Spacer size={8}/>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const BuyBikeLink = !authenticatedOnClientSide ? (
    <>
      <NamedLink
        className={css.createListingLink}
        name="SearchPage"
        state={{ prevPath: history.location.pathname }}
      >
        {intl.formatMessage({ id: 'top_bar.buy' })}
      </NamedLink>
    </>
  ) : null

  const sellBikeLink = !authenticatedOnClientSide ? (
    <>
      <Spacer axis={HORIZONTALL_AXIS} size={16}/>
      <NamedLink className={css.sellBikeLink} name="NewListingPage">
        {intl.formatMessage({ id: 'top_bar.sell' })}
      </NamedLink>
    </>
  ) : null

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      {intl.formatMessage({ id: 'top_bar.log_in' })}
    </NamedLink>
  );

  return (
    <nav className={classes}>
      <NamedLink className={css.logoLink} name="LandingPage">
        <Logo
          format="desktop"
          className={css.logo}
          alt={intl.formatMessage({ id: 'top_bar.desktop.logo_label' })}
        />
      </NamedLink>
      {search}
      {BuyBikeLink}
      {inboxLink}
      <Spacer axis={HORIZONTALL_AXIS} size={SMALL_SPACER}/>
      {profileMenu}
      <div className={css.loginSellButtonWrapper}>
        {loginLink}
        {sellBikeLink}
      </div>
      <Spacer axis={HORIZONTALL_AXIS} size={16}/>
      <Dropdown selectedOption={currentLocale} options={options} onOptionClick={onLocaleChange}/>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default compose(withRouter)(TopbarDesktop);
