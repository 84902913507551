import config from '../../config'
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck'
import { fetchCurrentUser } from '../../ducks/user.duck'
import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader'
import { storableError } from '../../util/errors'

const { UUID } = sdkTypes

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/ProfilePage/SET_INITIAL_STATE'

export const SHOW_USER_REQUEST = 'app/ProfilePage/SHOW_USER_REQUEST'
export const SHOW_USER_SUCCESS = 'app/ProfilePage/SHOW_USER_SUCCESS'
export const SHOW_USER_ERROR = 'app/ProfilePage/SHOW_USER_ERROR'

export const QUERY_LISTINGS_REQUEST = 'app/ProfilePage/QUERY_LISTINGS_REQUEST'
export const QUERY_LISTINGS_SUCCESS = 'app/ProfilePage/QUERY_LISTINGS_SUCCESS'
export const QUERY_LISTINGS_ERROR = 'app/ProfilePage/QUERY_LISTINGS_ERROR'

export const CLOSE_LISTING_REQUEST = 'app/ProfilePage/CLOSE_LISTING_REQUEST'
export const CLOSE_LISTING_SUCCESS = 'app/ProfilePage/CLOSE_LISTING_SUCCESS'
export const CLOSE_LISTING_ERROR = 'app/ProfilePage/CLOSE_LISTING_ERROR'

// ================ Reducer ================ //

const initialState = {
  userId: null,
  userListingRefs: [],
  fetchUserInProgress: true,
  fetchListingsInProgress: true,
  userShowError: null,
  queryListingsError: null,
  closingListing: null,
  closingListingError: null,
}

export default function profilePageReducer(state = initialState, action = {}) {
  const { type, payload } = action
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState }
    case SHOW_USER_REQUEST:
      return { ...state, userShowError: null, userId: payload.userId, fetchUserInProgress: true }
    case SHOW_USER_SUCCESS:
      return { ...state, fetchUserInProgress: false }
    case SHOW_USER_ERROR:
      return { ...state, userShowError: payload, fetchUserInProgress: false }

    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,

        // Empty listings only when user id changes
        userListingRefs: payload.userId === state.userId ? state.userListingRefs : [],

        queryListingsError: null,
        fetchListingsInProgress: true,
      }
    case QUERY_LISTINGS_SUCCESS:
      return { ...state, userListingRefs: payload.listingRefs, fetchListingsInProgress: false }
    case QUERY_LISTINGS_ERROR:
      return { ...state, userListingRefs: [], queryListingsError: payload, fetchListingsInProgress: false }

    case CLOSE_LISTING_REQUEST:
      return {
        ...state,
        closingListing: payload.listingId,
        closingListingError: null,
      }
    case CLOSE_LISTING_SUCCESS:
      return {
        ...state,
        userListingRefs: state.userListingRefs.filter(listingRef => listingRef.id.uuid != payload.listingId.uuid),
        closingListing: null,
        closingListingError: null,
      }
    case CLOSE_LISTING_ERROR: {
      return {
        ...state,
        closingListing: null,
        closingListingError: {
          listingId: state.closingListing,
          error: payload,
        },
      }
    }

    default:
      return state
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
})

export const showUserRequest = userId => ({
  type: SHOW_USER_REQUEST,
  payload: { userId },
})

export const showUserSuccess = () => ({
  type: SHOW_USER_SUCCESS,
})

export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
})

export const queryListingsRequest = userId => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { userId },
})

export const queryListingsSuccess = listingRefs => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listingRefs },
})

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
})

export const closeListingRequest = listingId => ({
  type: CLOSE_LISTING_REQUEST,
  payload: { listingId },
})

export const closeListingSuccess = listingId => ({
  type: CLOSE_LISTING_SUCCESS,
  payload: { listingId },
})

export const closeListingError = e => ({
  type: CLOSE_LISTING_ERROR,
  error: true,
  payload: e,
})

// ================ Thunks ================ //

export const queryUserListings = userId => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(userId))

  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing
  const aspectRatio = aspectHeight / aspectWidth

  return sdk.listings
    .query({
      author_id: userId,
      include: ['author', 'images'],
      'fields.image': [
        `variants.${variantPrefix}`,
        `variants.${variantPrefix}-2x`,
        'variants.scaled-large'
      ],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    })
    .then(response => {
      // Pick only the id and type properties from the response listings
      const listingRefs = response.data.data.map(({ id, type }) => ({ id, type }))
      dispatch(addMarketplaceEntities(response))
      dispatch(queryListingsSuccess(listingRefs))
      return response
    })
    .catch(e => dispatch(queryListingsError(storableError(e))))
}

export const showUser = userId => (dispatch, getState, sdk) => {
  dispatch(showUserRequest(userId))
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response))
      dispatch(showUserSuccess())
      return response
    })
    .catch(e => dispatch(showUserError(storableError(e))))
}

export const closeListing = (listingId, reason) => (dispatch, getState, sdk) => {
  dispatch(closeListingRequest(listingId))

  const setReasonPromise = sdk.ownListings.update({id: listingId, privateData: { closingReason: reason }})
  const updateListingReason = sdk.ownListings.close({ id: listingId }, { expand: true })

  return Promise.all([setReasonPromise, updateListingReason])
    .then(response => {
      dispatch(closeListingSuccess(listingId))
      return response
    })
    .catch(e => {
      dispatch(closeListingError(storableError(e)))
    })
}

export const loadData = params => (dispatch, getState, sdk) => {
  const userId = new UUID(params.id)

  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState())

  return Promise.all([
    dispatch(fetchCurrentUser()),
    dispatch(showUser(userId)),
    dispatch(queryUserListings(userId)),
  ])
}
