import { enTranslations } from './countries/en'
import { ltTranslations } from './countries/lt'
import { plTranslations } from './countries/pl'
import { LOCALES } from './locales'

export const messages = {
  [LOCALES.ENGLISH]: enTranslations,
  [LOCALES.LITHUANIAN]: ltTranslations,
  [LOCALES.POLISH]: plTranslations,
}
