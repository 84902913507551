import React from 'react'
import { string } from 'prop-types'
import { injectIntl, intlShape } from '../../util/reactIntl'
import classNames from 'classnames'
import config from '../../config'
import { Logo, ExternalLink, NamedLink } from '../../components'

import css from './Footer.module.css'

import FacebookIcon from '../../assets/icons/socials/facebook.png'
import InstagramIcon from '../../assets/icons/socials/instagram.png'
import LinkedInIcon from '../../assets/icons/socials/linkedin.png'
import Spacer, { HORIZONTALL_AXIS, LARGE_SPACER, MEDIUM_SPACER } from '../Spacer/Spacer'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteLinkedInPage } = config

  const goToFb = intl.formatMessage({ id: 'footer.facebook_link' })
  const goToInsta = intl.formatMessage({ id: 'footer.instagram_link' })
  const goToLinkedIn = intl.formatMessage({ id: 'footer.linkedin_link' })

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.socialLinkWrapper} title={goToFb}>
      <img className={css.socialIcon} src={FacebookIcon}/>
    </ExternalLink>
  ) : null

  const linkeIdLink = siteLinkedInPage ? (
    <ExternalLink key="linkToLinkedIn" href={siteLinkedInPage} className={css.socialLinkWrapper} title={goToLinkedIn}>
      <img className={css.socialIcon} src={LinkedInIcon}/>
    </ExternalLink>
  ) : null

  const instragramLink = siteInstagramPage ? (
    <ExternalLink key="linkToInstagram" href={siteInstagramPage} className={css.socialLinkWrapper} title={goToInsta}>
      <img className={css.socialIcon} src={InstagramIcon}/>
    </ExternalLink>
  ) : null

  const spacer = key => <Spacer key={key} size={MEDIUM_SPACER} axis={HORIZONTALL_AXIS}/>

  return [fbLink, spacer(0), linkeIdLink, spacer(1), instragramLink].filter(v => v != null)
}

const renderLargeSpacer = isMobile => isMobile ? <Spacer size={LARGE_SPACER}/> : <></>;

const Footer = props => {
  const { rootClassName, className, intl } = props
  const socialMediaLinks = renderSocialMediaLinks(intl)
  const classes = classNames(rootClassName || css.root, className)
  const isMobile = useIsMobileScreen(768)
  const largeSpacer = renderLargeSpacer(isMobile)

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.organizationWrapper}>
            <NamedLink name="LandingPage">
              <Logo format="desktop" className={css.logo} />
            </NamedLink>
            <Spacer size={LARGE_SPACER}/>
            <div className={css.socialMediaLinks}>{socialMediaLinks}</div>
          </div>
          
          <Spacer size={LARGE_SPACER}/>
          
          <div className={css.actionsWrapper}>
            <span className={css.contactDetails}>info@nextvelo.com</span>
            {largeSpacer}
            <NamedLink name='SearchPage'>
              <h5 className={css.actionLinkTitle}>{intl.formatMessage({ id: 'footer.buy_bike' })}</h5>
            </NamedLink>
            {largeSpacer}
            <NamedLink name='NewListingPage'>
              <h5 className={css.actionLinkTitle}>{intl.formatMessage({ id: 'footer.sell_bike' })}</h5>
            </NamedLink>
            {largeSpacer}
            <NamedLink name='AboutPage'>
              <h5 className={css.actionLinkTitle}>{intl.formatMessage({ id: 'footer.about' })}</h5>
            </NamedLink>
            {largeSpacer}
            <NamedLink name='PrivacyPolicyPage'>
              <h5 className={css.actionLinkTitle}>{intl.formatMessage({ id: 'footer.privacy_policy' })}</h5>
            </NamedLink>
            {largeSpacer}
            <NamedLink name='TermsOfServicePage'>
              <h5 className={css.actionLinkTitle}>{intl.formatMessage({ id: 'footer.terms_conditions' })}</h5>
            </NamedLink>
          </div>

          <Spacer size={LARGE_SPACER}/>
          <div className={css.divider}/>
          <span className={css.contentRights}>{intl.formatMessage({ id: 'footer.content_rights' })}</span>
        </div>
      </div>
    </div>
  )
}

Footer.defaultProps = {
  rootClassName: null,
  className: null,
}

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
}

export default injectIntl(Footer)
