import config from '../../config';
import { addMarketplaceEntities } from "../../ducks/marketplaceData.duck";
import { storableError } from "../../util/errors";
import { createImageVariantConfig } from '../../util/sdkLoader';

const RESULTS_PER_PAGE = 10

export const FETCH_LISTINGS_REQUEST = 'app/LandingPage/FETCH_RECENT_LISTINGS_REQUEST'
export const FETCH_LISTINGS_SUCCESS = 'app/LandingPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/LandingPage/FETCH_LISTINGS_ERROR';

export const FETCH_PART_LISTINGS_REQUEST = 'app/LandingPage/FETCH_PART_RECENT_LISTINGS_REQUEST'
export const FETCH_PART_LISTINGS_SUCCESS = 'app/LandingPage/FETCH_PART_LISTINGS_SUCCESS';
export const FETCH_PART_LISTINGS_ERROR = 'app/LandingPage/FETCH_PART_LISTINGS_ERROR';

const initialState = {
  queryInProgress: false,
  recentListingIds: [],
  recentPartListingsIds: [],
  fetchListingError: null
}

const resultIds = data => data.data.map(listing => listing.id)

// ================ Landing page reducer ================ //

const landingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action
  
  switch(type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryInProgress: true,
        fetchListingError: null
      }
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        queryInProgress: false,
        recentListingIds: resultIds(payload.data)
      }
    case FETCH_LISTINGS_ERROR:
      return {
        ...state,
        queryInProgress: false,
        fetchListingError: payload
      }
    case FETCH_PART_LISTINGS_REQUEST:
      return {
        ...state,
        queryInProgress: true,
        fetchListingError: null
      }
    case FETCH_PART_LISTINGS_SUCCESS:
      return {
        ...state,
        queryInProgress: false,
        recentPartListingsIds: resultIds(payload.data)
      }
    case FETCH_PART_LISTINGS_ERROR:
      return {
        ...state,
        queryInProgress: false,
        fetchListingError: payload
      }
    default:
      return state;
  }
}

export default landingPageReducer

// ================ Landing page action creators ================ //

export const fetchListingRequest = () => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: null
})

export const fetchListingSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data }
})

export const fetchListingError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e
})

export const fetchPartListingRequest = () => ({
  type: FETCH_PART_LISTINGS_REQUEST,
  payload: null
})

export const fetchPartListingSuccess = response => ({
  type: FETCH_PART_LISTINGS_SUCCESS,
  payload: { data: response.data }
})

export const fetchPartListingError = e => ({
  type: FETCH_PART_LISTINGS_ERROR,
  error: true,
  payload: e
})

const availableListingCountryCodes = (currency) => {
  return currency === 'PLN' ? 'PL' : 'LT,LV,EE';
}

export const fetchRecentListings = _ => (dispatch, getState, sdk) => {
  dispatch(fetchListingRequest())

  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing
  const aspectRatio = aspectHeight / aspectWidth
  const currency = getState().marketplaceData.currency;
  const price = currency === 'PLN' ? '430000,' : '100000,'

  const params = {
    per_page: RESULTS_PER_PAGE,
    pub_listingType: 'bike',
    price: price,
    pub_country: availableListingCountryCodes(currency),
    include: ['author', 'images', 'currentStock'],
    'fields.image': [
      `variants.${variantPrefix}`, 
      `variants.${variantPrefix}-2x`,
      'variants.scaled-large'
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
  }

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response))
      dispatch(fetchListingSuccess(response))
      return response
    })
    .catch(e => {
      dispatch(fetchListingError(storableError(e)))
      throw e
    })
}

export const fetchRecentPartListings = _ => (dispatch, getState, sdk) => {
  dispatch(fetchPartListingRequest())

  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing
  const aspectRatio = aspectHeight / aspectWidth
  const currency = getState().marketplaceData.currency;
  const price = currency === 'PLN' ? '43000,' : '10000,'

  const params = {
    per_page: 5,
    pub_listingType: 'parts',
    price: price,
    pub_country: availableListingCountryCodes(currency),
    include: ['author', 'images', 'currentStock'],
    'fields.image': [
      `variants.${variantPrefix}`, 
      `variants.${variantPrefix}-2x`,
      'variants.scaled-large'
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
  }

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response))
      dispatch(fetchPartListingSuccess(response))
      return response
    })
    .catch(e => {
      dispatch(fetchPartListingError(storableError(e)))
      throw e
    })
}

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  return Promise.all([dispatch(fetchRecentListings()), dispatch(fetchRecentPartListings())])
}
