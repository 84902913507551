export const enTranslations = {
  'landing_page.sell_button': 'Sell now',
  'landing_page.view_bikes_and_parts': 'Find bike or parts',
  'landing_page.buy_bike_button': 'View all bikes',
  'landing_page.buy_parts_button': 'View all parts',
  'landing_page.section_hero.title': 'Turn Your',
  'landing_page.section_hero.sub_title': 'Bike Into Cash',
  'landing_page.section_hero.description_1': 'Create a listing for free in less than 3 minutes.',
  'landing_page.section_hero.description_2': 'Reach thousands of buyers.',
  'landing_page.schema_description':
    'Buy & sell pre-loved roadbikes, gravel bikes, and mountain bikes on NextVelo, the largest online platform for second-hand bicycles. With buyer protection, fast shipping, and secure payments.',
  'Landing_page.schema_title': 'Discover your pre-owned roadbike, gravel bike, or MTB | NextVelo',
  'landing_page.categories_label': 'Categories',
  'landing_page.most_recent_label': 'Recent bikes',
  'landing_page.most_popular_label': 'Popular brands',
  'landing_page.most_recent_part_label': 'Bike parts',

  'locales.english': 'EN',
  'locales.lithuanian': 'LT',
  'locales.polish': 'PL',

  'months.january': 'January',
  'months.february': 'February',
  'months.march': 'March',
  'months.april': 'April',
  'months.may': 'May',
  'months.june': 'June',
  'months.july': 'July',
  'months.august': 'August',
  'months.september': 'September',
  'months.october': 'October',
  'months.november': 'November',
  'months.december': 'December',

  'landing_page.section_info.label': 'How NextVelo works?',

  'landing_page.section_info.sell': 'Sell',
  'landing_page.section_info.sell.question_0': 'Tell us about the bike you are selling',
  'landing_page.section_info.sell.answer_0':
    'Provide bike specifications, including size, materials, color, and other details.',
  'landing_page.section_info.sell.question_1': 'Handle inquiries',
  'landing_page.section_info.sell.answer_1':
    'Connect with potential buyers through the messaging system, answer their questions, or arrange a test ride.',
  'landing_page.section_info.sell.question_2': 'Confirm your payment',
  'landing_page.section_info.sell.answer_2':
    'Meet with the buyer to receive payment or accept online payment.',

  'landing_page.section_info.buy': 'Buy',
  'landing_page.section_info.buy.question_0': 'Choose the bike you like',
  'landing_page.section_info.buy.answer_0':
    'Search for bikes by size, bike type, or other bike attributes.',
  'landing_page.section_info.buy.question_1': 'Find out more about the bike',
  'landing_page.section_info.buy.answer_1':
    'Meet up for a testdrive. Or if you want, get it delivered straight away.',
  'landing_page.section_info.buy.question_2': 'Confirm your payment',
  'landing_page.section_info.buy.answer_2':
    'Meet with the seller to pay in cash, or arrange on online payment.',

  'config.labels.condition': 'Condition',
  'config.labels.shipping': 'Shipping',
  'config.labels.receiptAvailable': 'Receipt',
  'config.labels.frameColor': 'Color',
  'config.labels.frameMaterial': 'Material',
  'config.labels.wheelSize': 'Wheel size',
  'config.labels.frameSize': 'Frame size',
  'config.labels.year': 'Year',
  'config.labels.brand': 'Brand',
  'config.labels.category': 'Type',
  'config.labels.price': 'Price',
  'config.labels.keyword': 'Keyword',
  'config.labels.model': 'Model',
  'config.labels.country': 'Country',
  'config.labels.subCategory': 'Category',
  'config.labels.discipline': 'Discipline',
  'config.labels.frontTravel': 'Front travel',
  'config.labels.rearTravel': 'Rear travel',
  'config.labels.dropperTravel': 'Travel',
  'config.labels.dropperDiameter': 'Diameter',
  'config.labels.dropperActivation': 'Activation',
  'config.labels.catalogTree': 'Category',
  'config.labels.hubStandard': 'Hub standard',
  'config.labels.frontHubStandard': 'Front hub standard',
  'config.labels.rearHubStandard': 'Rear hub standard',
  'config.labels.suspensionType': 'Suspension type',
  'config.labels.suspensionMounting': 'Suspension mounting',
  'config.labels.suspensionLength': 'Suspension length',
  'config.labels.suspensionStroke': 'Suspension stroke',
  'config.labels.axleDimension': 'Axle dimension',
  'config.labels.gears': 'Gears',
  'config.labels.frontGears': 'Front gears',
  'config.labels.rearGears': 'Rear gears',
  'config.labels.placement': 'Type',
  'config.labels.activation': 'Activation',
  'config.labels.chainringMounting': 'Chainring mounting',
  'config.labels.bottomBracketMounting': 'Mounting',
  'config.labels.pedals': 'Type',
  'config.labels.frontWheelSize': 'Front wheel size',
  'config.labels.rearWheelSize': 'Rear wheel size',
  'config.labels.wheelsPlacement': 'Type',
  'config.labels.brakeType': 'Type',
  'config.labels.brakesActivation': 'Activation',
  'config.labels.hubsPlacement': 'Type',
  'config.labels.handlebarType': 'Type',
  'config.labels.gripsType': 'Type',
  'config.labels.steererType': 'Type',
  'config.labels.crankArmLength': 'Crank arm length',
  'config.labels.chainringTeeth': 'Teeth',
  'config.labels.handlebarWidth': 'Handlebar width',

  'categories.all': 'Bikes & parts',
  'categories.bike': 'Bikes',
  'categories.parts': 'Parts',

  'categories.city': 'City',
  'categories.road': 'Road',
  'categories.mountain': 'Mountain',
  'categories.gravel': 'Gravel',
  'categories.bmx_dirt': 'Bmx & Dirt',
  'categories.folding': 'Folding',
  'categories.electric': 'Electric',
  'categories.other': 'Other',

  'categories.frame': 'Frame',
  'categories.suspension': 'Suspension',
  'categories.drivetrain': 'Drivetrain',
  'categories.brakes': 'Brakes',
  'categories.wheels': 'Wheels',
  'categories.tyres_tubes': 'Tyres',
  'sub_categories.tyres_tubes': 'Tyres',
  'categories.cockpit': 'Cockpit',
  'categories.seat': 'Seat',

  'sub_categories.frame_full_suspension': 'Full suspension',
  'sub_categories.frame_hard_tail_mtb': 'Hardtail MTB',
  'sub_categories.frame_road': 'Road',
  'sub_categories.frame_gravel': 'Gravel',
  'sub_categories.frame_full_suspension_e_bike': 'Full suspension E-Bike',
  'sub_categories.frame_hard_tail_e_bike': 'Hardtail E-Bike',
  'sub_categories.frame_city': 'City',
  'sub_categories.frame_dirt_n_street': 'Dirt & Street',

  'sub_categories.suspension_rigid_fork': 'Rigid fork',
  'sub_categories.suspension_suspension_fork': 'Suspension fork',
  'sub_categories.suspension_rear': 'Rear suspension',

  'sub_categories.drivetrain_crank': 'Crank',
  'sub_categories.drivetrain_chain': 'Chain',
  'sub_categories.drivetrain_cassette': 'Cassette',
  'sub_categories.drivetrain_shifter': 'Shifter',
  'sub_categories.drivetrain_derailleur': 'Derailleur',
  'sub_categories.drivetrain_chainring': 'Chainring',
  'sub_categories.drivetrain_bottom_bracket': 'Bottom bracket',
  'sub_categories.drivetrain_chain_guides': 'Chain guide',
  'sub_categories.drivetrain_pedals': 'Pedals',

  'sub_categories.brakes_disk': 'Disk brakes',
  'sub_categories.brakes_rim': 'Rim brakes',
  'sub_categories.brakes_rotor': 'Nrake rotor',
  'sub_categories.brakes_other': 'Other',

  'sub_categories.wheels_complete': 'Complete wheels',
  'sub_categories.wheels_hubs': 'Hubs',
  'sub_categories.wheels_rims': 'Rims',

  'sub_categories.cockpit_handlebar': 'Handlebar',
  'sub_categories.cockpit_stem': 'Stem',
  'sub_categories.cockpit_grips': 'Grips',
  'sub_categories.cockpit_headset': 'Headset',

  'sub_categories.seat_dropper_post': 'Dropper post',
  'sub_categories.seat_post': 'Seat post',
  'sub_categories.seat_saddle': 'Seat saddle',
  'sub_categories.seat_other': 'Other',

  'year.placeholder': 'Select year',
  'frame_size.placeholder': 'Select frame size',
  'brand.placeholder': 'Select brand',
  'country.placeholder': 'Select country',
  'front_travel.placeholder': 'Select front travel',
  'rear_travel.placeholder': 'Select rear travel',

  'country.LT': 'Lithuania',
  'country.LV': 'Latvia',
  'country.EE': 'Estonia',
  'country.PL': 'Poland',
  'country.all': 'All',

  'sub_categories.road': 'Road',
  'sub_categories.track': 'Track',
  'sub_categories.aearo_triathlon_tt': 'Aearo/Triathlon/TT',
  'sub_categories.single_gear': 'Single gear',
  'sub_categories.xc_cross_country': 'XC / Cross Country',
  'sub_categories.trail': 'Trail',
  'sub_categories.enduro': 'Enduro',
  'sub_categories.downhill': 'Downhill',
  'sub_categories.bmx': 'Bmx',
  'sub_categories.dirt_street': 'Dirt/Street Bikes',
  'sub_categories.electric_city': 'City',
  'sub_categories.electric_mountain': 'Mountain',
  'sub_categories.electric_road': 'Road',
  'sub_categories.electric_other': 'Other',
  'sub_categories.cargo': 'Cargo',
  'sub_categories.tricycle': 'Tricycle',
  'sub_categories.tandem': 'Tandem',
  'sub_categories.balance': 'Balance',
  'sub_categories.unicycle': 'Unicycle',

  'shipping.no_shipping': 'Local pick up only',
  'shipping.domestic_shipping': 'Will ship within country',
  'shipping.international_shipping': 'Will ship outside country',

  'frame_material.aluminum': 'Aluminum',
  'frame_material.carbon': 'Carbon',
  'frame_material.steel': 'Steel',
  'frame_material.other': 'Other',

  'frame_color.pink': 'Pink',
  'frame_color.green': 'Green',
  'frame_color.orange': 'Orange',
  'frame_color.blue': 'Blue',
  'frame_color.red': 'Red',
  'frame_color.white': 'White',
  'frame_color.yellow': 'Yellow',
  'frame_color.black': 'Black',
  'frame_color.grey': 'Grey',
  'frame_color.other': 'Other',

  'wheel_size.16': '16″',
  'wheel_size.20': '20″',
  'wheel_size.28': '28″ (700c)',
  'wheel_size.26': '26”',
  'wheel_size.27.5': '27.5” (650b)',
  'wheel_size.29': '29”',
  'wheel_size.650B': '650B',
  'wheel_size.700C': '28” (700c)',
  'wheel_size.other': 'Other',

  'suspension_type.air': 'Air suspension',
  'suspension_type.coil': 'Coil suspension',

  'suspension_mounting.trunnion': 'Trunnion',
  'suspension_mounting.standard': 'Standard',

  'discipline.xc_marathon': 'XC / Marathon',
  'discipline.trail_all_mountain': 'Trail / All-Mountain',
  'discipline.enduro': 'Enduro',
  'discipline.downhill': 'Downhill',

  'hub_standard.15_110': '15 x 110mm (Boost)',
  'hub_standard.20_110': '20 x 110mm (Boost)',
  'hub_standard.9_100': '9 x 100mm',
  'hub_standard.15_100': '15 x 100mm',
  'hub_standard.20_100': '20 x 100mm',
  'hub_standard.9_135': '9 x 135mm',
  'hub_standard.10_135': '10 x 135mm',
  'hub_standard.15_142': '15 x 142mm',
  'hub_standard.15_150': '15 x 150mm',
  'hub_standard.10_170': '10 x 170mm',
  'hub_standard.12_190': '12 x 190mm',
  'hub_standard.12_197': '12 x 197mm',

  'suspension_type.air': 'Air',
  'suspension_type.coil': 'Coil',

  'axle_dimension.30': '30mm',
  'axle_dimension.29_9': '29.9mm',
  'axle_dimension.22_24': '22/24mm (DUB)',
  'axle_dimension.25': '25mm',
  'axle_dimension.24': '24mm',
  'axle_dimension.19': '19mm',
  'axle_dimension.square_taper': 'Square taper',
  'axle_dimension.octalink': 'Octalink',
  'axle_dimension.m30': 'M30',
  'axle_dimension.isis': 'ISIS',
  'axle_dimension.american': 'American',
  'axle_dimension.mid': 'Mid',

  'gears.1': '1',
  'gears.2': '2',
  'gears.3': '3',
  'gears.4': '4',
  'gears.5': '5',
  'gears.6': '6',
  'gears.7': '7',
  'gears.8': '8',
  'gears.9': '9',
  'gears.10': '10',
  'gears.11': '11',
  'gears.12': '12',
  'gears.13': '13',

  'placement.front': 'Front',
  'placement.rear': 'Rear',
  'placement.set': 'Set',

  'activation.mechanical': 'Mechanical',
  'activation.electric': 'Electric',

  'chainring_mounting.3_arm': '3-arm',
  'chainring_mounting.4_arm': '4-arm',
  'chainring_mounting.5_arm': '5-arm',

  'bottom_bracket_mounting.bsa': 'BSA',
  'bottom_bracket_mounting.ita': 'ITA',
  'bottom_bracket_mounting.pressfit': 'Pressfit',

  'pedals.flat': 'Flat',
  'pedals.clipless': 'Clipless',
  'pedals.hybrid': 'Hybrid',

  'brakes_activation.hydraulic': 'Hydraulic',
  'brakes_activation.mechanical': 'Mechanical',

  'handlebar_type.mtb': 'MTB',
  'handlebar_type.dropbar': 'Dropbar',
  'handlebar_type.bmx': 'BMX',
  'handlebar_type.city_trekking': 'City/Trekking',

  'grips_type.lock_on': 'Lock on',
  'grips_type.push_on': 'Push on',
  'grips_type.bar_tape': 'Bar tape',

  'steerer_type.1_5': '1.5”',
  'steerer_type.1_8': '1-1/8”',
  'steerer_type.threaded': 'Threaded',
  'steerer_type.tapered': 'Tapered',

  'dropper_diameter.27_2': '27.2mm',
  'dropper_diameter.28_6': '28.6mm',
  'dropper_diameter.30_9': '30.9mm',
  'dropper_diameter.31_6': '31.6mm',
  'dropper_diameter.31_8': '31.8mm',
  'dropper_diameter.34_9': '34.9mm',

  'dropper_activation.mechanical': 'Mechanical',
  'dropper_activation.electric': 'Electric',
  'dropper_activation.hydraulic': 'Hydraulic',

  'rotor_mounting.6_hole': '6-Hole',
  'rotor_mounting.centerlock': 'Centerlock',

  'rotor_placement.front': 'Front',
  'rotor_placement.rear': 'Rear',
  'rotor_placement.set': 'Set',

  'wheels_placement.front': 'Front',
  'wheels_placement.rear': 'Rear',
  'wheels_placement.set': 'Set',

  'hubs_placement.front': 'Front',
  'hubs_placement.rear': 'Rear',
  'hubs_placement.set': 'Set',

  'receipt.yes': 'Yes',
  'receipt.no': 'No',

  'condition.bad': 'Parts bike',
  'condition.partly_bad': 'Requires maintenance',
  'condition.good': 'Good',
  'condition.perfect': 'Perfect',
  'condition.brand_new': 'Brand new',

  'condition.bad.label': `✔ Unrideable bike\n✔ Inspection and service needed\n✔ Parts replacement necessary`,
  'condition.partly_bad.label': `✔ Some scratches on the surface\n✔ Requires service adjustments\n✔ Some parts may need replacement`,
  'condition.good.label': `✔ Some scratches on the surface\n✔ May require service adjustments\n✔ Parts replacement not necessary`,
  'condition.perfect.label': `✔ Some light scratches\n✔ Mechanically perfect\n✔ No significant damage to any components`,
  'condition.brand_new.label': `✔ The bike was never used`,

  'condition.used_part': 'Used',
  'condition.good_part': 'Good',
  'condition.new_part': 'New',

  'condition.used_part.label': '✔ Previously owned, with noticeable flaws.',
  'condition.good_part.label': '✔ Lightly used, in great shape.',
  'condition.new_part.label': '✔ Mint condition, never used.',

  'frame_size.xxl': 'XXL',
  'frame_size.xl': 'XL',
  'frame_size.l': 'L',
  'frame_size.m': 'M',
  'frame_size.s': 'S',
  'frame_size.xs': 'XS',
  'frame_size.xxs': 'XSS',

  'front_travel.60': '60',
  'front_travel.70': '70',
  'front_travel.80': '80',
  'front_travel.90': '90',
  'front_travel.100': '100',
  'front_travel.110': '110',
  'front_travel.120': '120',
  'front_travel.130': '130',
  'front_travel.140': '140',
  'front_travel.150': '150',
  'front_travel.160': '160',
  'front_travel.170': '170',
  'front_travel.180': '180',
  'front_travel.190': '190',
  'front_travel.200': '200',
  'front_travel.210': '210',
  'front_travel.220': '220',
  'front_travel.230': '230',

  'rear_travel.60': '60',
  'rear_travel.70': '70',
  'rear_travel.80': '80',
  'rear_travel.90': '90',
  'rear_travel.100': '100',
  'rear_travel.110': '110',
  'rear_travel.120': '120',
  'rear_travel.130': '130',
  'rear_travel.140': '140',
  'rear_travel.150': '150',
  'rear_travel.160': '160',
  'rear_travel.170': '170',
  'rear_travel.180': '180',
  'rear_travel.190': '190',
  'rear_travel.200': '200',
  'rear_travel.210': '210',
  'rear_travel.220': '220',
  'rear_travel.230': '230',

  'top_bar.search_form.placeholder': 'Search',
  'top_bar.generic_error_message': 'Something went wrong. Please try again.',
  'top_bar.logo_icon_label': 'Go to homepage',
  'top_bar.menu_icon_label': 'Open menu',

  'top_bar.log_in': 'Sign in',
  'top_bar.log_out': 'Log out',
  'top_bar.sell': 'Sell',
  'top_bar.buy': 'Buy',
  'top_bar.inbox_label': 'Inbox',
  'top_bar.listings_label': 'Your listings',
  'top_bar.profile_settings_label': 'Profile settings',
  'top_bar.account_settings_label': 'Settings',
  'top_bar.profile': 'Profile',

  'top_bar.mobile_menu.buy': 'Buy',
  'top_bar.mobile_menu.about_page': 'About',
  'top_bar.mobile_menu.how_it_works_page': 'How it works',
  'top_bar.mobile_menu.show_profile': 'Show profile',
  'top_bar.mobile_menu.account_label': 'Account',
  'top_bar.mobile_menu.services_label': 'Services',
  'top_bar.mobile_menu.favorite_listings': 'Favorite listings',

  'top_bar.desktop.logo_label': 'NextVelo',

  'authentication_page.or': 'or',
  'authentication_page.facebook_login': 'Continue with Facebook',
  'authentication_page.google_login': 'Continue with Google',
  'authentication_page.email_login': 'Continue with email',
  'authentication_page.terms_and_conditions_accept_label':
    "By selecting Agree and continue, I agree to NextVelo's {termsLink}, and the {privacyPolicyLink}",
  'authentication_page.terms_and_conditions_label': 'Terms of Service',
  'authentication_page.privacy_policy_label': 'Privacy Policy',

  'log_in.email_label': 'Email',
  'log_in.email_placeholder': 'Email',
  'log_in.email_required': 'Please enter email',
  'log_in.email_invalid': 'Provided email is invalid',
  'log_in.password_label': 'Password',
  'log_in.password_placeholder': 'Password',
  'log_in.password_required': 'Please enter password',
  'log_in.forgot_password': 'Forgot password?',
  'log_in.log_in_label': 'Log in',
  'log_in.sign_up_prompt': "Don't have an account? ",
  'log_in.sign_up_label': 'Sign up',
  'log_in.failed':
    'The email and password you entered did not match our records. Please double-check and try again.',

  'sign_up.email_label': 'Email',
  'sign_up.email_placeholder': 'Email',
  'sign_up.email_required': 'Please enter email',
  'sign_up.email_invalid': 'Provided email is invalid',
  'sign_up.password_label': 'Password',
  'sign_up.password_placeholder': 'Password',
  'sign_up.password_required': 'Please enter password',
  'sign_up.password_too_short': 'The password should be at least {minLength} characters',
  'sign_up.password_too_long': 'The password should be at most {maxLength} characters',
  'sign_up.first_name_label': 'First name',
  'sign_up.first_name_placeholder': 'First name',
  'sign_up.first_name_required': 'Please enter first name',
  'sign_up.last_name_label': 'Last name',
  'sign_up.last_name_placeholder': 'Last name',
  'sign_up.last_name_required': 'Please enter last name',
  'sign_up.sign_up_label': 'Sign up',
  'sign_up.log_in_prompt': 'Already have an account? ',
  'sign_up.log_in_label': 'Log in',
  'sign_up.failed':
    'Signup failed. Please make sure all the information you entered is correct and try again.',
  'sign_up.email_already_taken': 'Email already taken',

  'confirm_sign_up.email_label': 'Email',
  'confirm_sign_up.email_placeholder': 'Email',
  'confirm_sign_up.email_required': 'Please enter email',
  'confirm_sign_up.email_invalid': 'Provided email is invalid',
  'confirm_sign_up.first_name_label': 'First name',
  'confirm_sign_up.first_name_placeholder': 'First name',
  'confirm_sign_up.first_name_required': 'Please enter first name',
  'confirm_sign_up.last_name_label': 'Last name',
  'confirm_sign_up.last_name_placeholder': 'Last name',
  'confirm_sign_up.last_name_required': 'Please enter last name',
  'confirm_sign_up.confirm_and_continue_label': 'Agree and continue',

  'avatar.banned_user_label': 'Banned user',
  'avatar.deleted_user_label': 'Deleted user',

  'footer.copyright': '© NextVelo',
  'footer.buy_bike': 'Find bike or parts',
  'footer.sell_bike': 'Sell bike or parts',
  'footer.about': 'About NextVelo',
  'footer.terms_conditions': 'Terms & conditions',
  'footer.privacy_policy': 'Privacy Policy',
  'footer.instagram_link': 'Go to Instagram page',
  'footer.facebook_link': 'Go to Facebook page',
  'footer.linkedin_link': 'Go to LinkedIn page',
  'footer.organization_description': 'The largest online community for bikes.',
  'footer.content_rights': '© NextVelo. All rights reserved.',

  'modal.close': 'Close modal',

  'filter_form.cancel': 'Cancel',
  'filter_form.clear': 'Clear',
  'filter_form.submit': 'Apply',

  'actions.cancel': 'Cancel',
  'actions.clear': 'Clear',
  'actions.submit': 'Apply',
  'actions.publish': 'Publish',
  'actions.continue': 'Continue',
  'actions.back': 'Back',

  'search_page.price_filter.cancel': 'Cancel',
  'search_page.price_filter.clear': 'Clear',
  'search_page.price_filter.plain_label': 'Price',
  'search_page.price_filter.label': 'Price range:',
  'search_page.price_filter.submit': 'Apply',
  'search_page.price_filter.to': 'to',
  'search_page.header.foundResults':
    '{count, number} {count, plural, one {result} other {results}}',
  'search_page.header.loadingResults': 'Loading search results',
  'search_page.sortBy': 'Sort by',
  'search_page.filter_plain.selected': '• {count}',
  'search_page.multiple_filter.selected': '{labelText} • {count}',
  'search_page.reset_filters': 'Reset filters',
  'search_page.no_results': 'Could not find any listings that match your search criteria.',
  'search_page.empty_state.message':
    'Unfortunately, we were unable to find any listings that match your search criteria',
  'search_page.price_filter.selected': '{minPrice} - {maxPrice}',
  'search_page.mobile_filters.label': 'Filter',
  'search_page.mobile_filters.reset': 'Reset',
  'search_page.range_filter.label.selected': '{minValue} - {maxValue}',

  'search_page.mobile.show_listings': 'Show {count}',

  'profile_page.label': 'Profile',
  'profile_page.listing.view': 'View',
  'profile_page.edit_profile': 'Edit profile',
  'profile_page.joined': 'Joined {createdAt}',
  'profile_page.bikes.seller': 'Your listings',
  'profile_page.bikes.buyer': 'Listings for sale',
  'profile_page.data_load_failed': 'Whoops, something went wrong. Please try again.',
  'profile_page.bio_label': 'About',
  'profile_page.edit_listing': 'Edit',
  'profile_page.delete_listing': 'Delete',
  'profile_page.delete_modal.title': 'Delete listing',
  'profile_page.delete_modal.subtitle': 'How did you sold your item?',
  'profile_page.delete_modal.reason.nextvelo': 'Found a buyer at NextVelo',
  'profile_page.delete_modal.reason.outside': 'Found a buyer outside the platform',
  'profile_page.delete_modal.reason.other': 'Other',
  'profile_page.delete_modal.delete_action': 'Delete',
  'profile_page.delete_modal.cancel_action': 'Cancel',
  'profile_page.no_listings.owner_label': 'Turn Your Old Bike into Cash',

  'listing_page.sold_by': '{name}',
  'listing_page.loading': 'Loading listing',
  'listing_page.order_panel': 'Sold by {name}',
  'listing_page.contact_seller': 'Contact seller',
  'listing_page.add_to_favorites': 'Add to favorites',
  'listing_page.remove_from_favorites': 'Remove from favorites',
  'listing_page.description.about_bike_label': 'About bike',
  'listing_page.details.label': 'Details',
  'listing_page.image_gallery.view_photos': 'View photos ({count})',
  'listing_page.image_gallery.image_alt_text': 'Listing image {index}/{count}',
  'listing_page.image_gallery.image_thumb_alt_text': 'Listing image {index}/{count}',
  'listing_page.basic_information.label': 'Basic information',
  'listing_page.location.label': 'Location',
  'listing_page.seller_details.label': 'Seller information',
  'listing_page.offered_by.label': 'Offered by',
  'listing_page.offered_by.phone_number': 'Phone Number',

  'new_listing_page.page_title': 'What will you sell?',
  'new_listing_page.type.bike': 'Bicycle',
  'new_listing_page.description.bike': 'Complete bicycle, any condition.',
  'new_listing_page.type.part': 'Bike components',
  'new_listing_page.description.part': 'Frame, fork, wheels or other parts.',

  'edit_listing_page.location.label': 'Location',
  'edit_listing_page.details.label': 'Details',
  'edit_listing_page.categories.label': 'Type',
  'edit_listing_page.general_details.label': 'Photos & details',
  'edit_listing_page.show_listing_failed': 'Fetching listing data failed',
  'edit_listing_page.update_failed': 'Failed to update listing. Please try again.',
  'edit_listing_page.upload_failed': 'Publishing of listing failed',
  'edit_listing_page.max_length': 'Must be {maxLength} characters or less',

  'edit_listing_page.location.tab_title': 'Sell your new or second hand bike',
  'edit_listing_page.location.address': 'Where is your bike located?',
  'edit_listing_page.location.address_hint': 'This will not bee seen to other members.',
  'edit_listing_page.location.address_not_recognized':
    "We didn't recognize this location. Please try another location.",
  'edit_listing_page.location.address_placeholder': 'Enter address',
  'edit_listing_page.location.address_required': 'You need to provide a location',
  'edit_listing_page.location.shipping_label': 'Will you ship a bike?',
  'edit_listing_page.location.shipping_required': 'Please choose shipping method',

  'edit_listing_page.categories.tab_title': 'Which of the best describes your bike?',
  'edit_listing_page.categories.type_select': 'Select the type of your bike',
  'edit_listing_page.categories.sub_category_required': "Please choose bike's sub-category",
  'edit_listing_page.categories.category_required': "Please choose bike's category",

  'edit_listing_page.details.tab_title': 'General information about your bike',
  'edit_listing_page.details.frame_details': 'Frame details',
  'edit_listing_page.details.brand': 'Brand',
  'edit_listing_page.details.brand_placeholder': "Choose bike's brand",
  'edit_listing_page.details.brand_required': 'Please select brand',
  'edit_listing_page.details.model': 'Model',
  'edit_listing_page.details.model_placeholder': "Enter model of bike's frame",
  'edit_listing_page.details.model_required': "Please enter model of bike's frame",
  'edit_listing_page.details.year': 'Year',
  'edit_listing_page.details.year_placeholder': 'Select a year',
  'edit_listing_page.details.year_required': 'Please select a year',
  'edit_listing_page.details.frame_size': 'Frame size',
  'edit_listing_page.details.frame_size_placeholder': 'Select frame size',
  'edit_listing_page.details.frame_size_required': 'Please select frame size',
  'edit_listing_page.details.frame_material': 'Material',
  'edit_listing_page.details.frame_material_required': 'Please select material',
  'edit_listing_page.details.frame_color': 'Colour',
  'edit_listing_page.details.frame_color_required': 'Please select colour',
  'edit_listing_page.details.wheel_size': 'Wheel size',
  'edit_listing_page.details.wheel_size_info':
    "Wheel size is the diameter of the bike's wheel, usually noted on the tire.",
  'edit_listing_page.details.wheel_size_placeholder': 'Select wheel size',
  'edit_listing_page.details.wheel_size_required': 'Please select wheel size',
  'edit_listing_page.details.component_details': 'Component details',
  'edit_listing_page.details.component_details_placeholder':
    'What components - groupset, wheels, fork, shocks, seatpost, etc. - are on the bike? E.g/ Shimano Dura-Ace groupset, Bontrager Aeolus Pro 3v TLR wheels...',
  'edit_listing_page.details.component_details_required': 'Please enter component details',
  'edit_listing_page.details.frame_size_table_title': 'The right frame size',
  'edit_listing_page.details.frame_size_table_info':
    "Your bike's frame size is typically located on the seat tube of the frame. If you're unable to locate it, consult the table below.",
  'edit_listing_page.details.frame_size_table_header_1': 'Frame size',
  'edit_listing_page.details.frame_size_table_header_2': 'Frame in cm',
  'edit_listing_page.details.wheel_size.hint':
    'Wheel size is the diameter of the bike’s wheel, usually noted on the tire.',

  'edit_listing_page.general_details.tab_title': 'Add some photos of your bike',
  'edit_listing_page.general_details.image_upload_failed': 'Failed to upload image',
  'edit_listing_page.general_details.image_upload_over_limit':
    'The maximum allowed file size is 20 MB',
  'edit_listing_page.general_details.image_required': 'Please upload at least 4 photos',
  'edit_listing_page.general_details.image_upload.info': 'Choose at least 3 photos',
  'edit_listing_page.general_details.image_upload.from_device': 'Upload from your device',
  'edit_listing_page.general_details.image_upload.add_more': 'Add more',
  'edit_listing_page.general_details.image_upload.main_image': 'Main image',
  'edit_listing_page.general_details.title': 'Title',
  'edit_listing_page.general_details.title_placeholder': 'e.g. Trek slash 9.7',
  'edit_listing_page.general_details.title_required': 'Please enter title',
  'edit_listing_page.general_details.condition': 'Condition',
  'edit_listing_page.general_details.condition_required': "Please pick bike's condition",
  'edit_listing_page.general_details.receipt': 'Does the bike have original receipt?',
  'edit_listing_page.general_details.receipt_required': 'Please provide receipt availability',
  'edit_listing_page.general_details.price': 'Price',
  'edit_listing_page.general_details.placeholder': '€0.00',
  'edit_listing_page.general_details.price_required': 'Please specify price',
  'edit_listing_page.general_details.price_too_low': 'Price should be at least {minPrice}.',
  'edit_listing_page.general_details.price_too_high': "Price shouldn't exceed {maxPrice}.",
  'edit_listing_page.general_details.saved_image_alt': 'Saved listing image',

  'parts_listing_page.details.label': 'Details',
  'parts_listing_page.categories.label': 'Type',
  'parts_listing_page.general_details.label': 'Photos & details',
  'parts_listing_page.specifications.label': 'Specifications',

  'parts_listing_page.categories.tab_title': 'Which component do you want to sell?',
  'parts_listing_page.details.tab_title': 'Provide basic information about your part.',
  'parts_listing_page.specification.tab_title': 'Share part specifications.',

  'parts_listing_page.specification.panel_description':
    'Increase your chances of a sale by providing extra details.',

  'parts_listing_page.details.sub_category': 'Subcategory',
  'parts_listing_page.details.sub_category_required': 'Please select sub-category',
  'parts_listing_page.details.sub_category_placeholder': 'Choose sub-category',

  'parts_listing_page.details.brand': 'Brand',
  'parts_listing_page.details.brand_placeholder': 'Choose component brand',
  'parts_listing_page.details.brand_required': 'Please select brand',
  'parts_listing_page.details.model': 'Model',
  'parts_listing_page.details.model_placeholder': 'Enter component model',
  'parts_listing_page.details.model_required': 'Please enter component model',
  'parts_listing_page.details.year_placeholder': 'Select year',

  'parts_listing_page.specification.more_details': 'More details',
  'parts_listing_page.specifications.frame_size': 'Size',
  'parts_listing_page.specifications.frame_size_placeholder': 'Select size',
  'parts_listing_page.specifications.frame_size_required': 'Please choose size',
  'parts_listing_page.specifications.frame_material': 'Material',
  'parts_listing_page.specifications.frame_material_placeholder': 'Select material',
  'parts_listing_page.specifications.frame_material_required': 'Please select material',
  'parts_listing_page.specifications.wheel_size': 'Wheel size',
  'parts_listing_page.specifications.wheel_size_placeholder': 'Select wheel size',
  'parts_listing_page.specifications.wheel_size_required': 'Please select wheel size',
  'parts_listing_page.specifications.front_travel': 'Front travel',
  'parts_listing_page.specifications.front_travel_placeholder': 'Enter front travel',
  'parts_listing_page.specifications.front_travel_required': 'Please enter front travel',
  'parts_listing_page.specifications.rear_travel': 'Rear travel',
  'parts_listing_page.specifications.rear_travel_placeholder': 'Enter reat travel',
  'parts_listing_page.specifications.rear_travel_required': 'Please enter rear travel',
  'parts_listing_page.specifications.discipline': 'Disclipline',
  'parts_listing_page.specifications.discipline_placeholder': 'Select discipline',
  'parts_listing_page.specifications.hub_standard': 'Hub standard',
  'parts_listing_page.specifications.hub_standard_placeholder': 'Select hub standard',
  'parts_listing_page.specifications.suspension_type': 'Type',
  'parts_listing_page.specifications.suspension_mounting': 'Mounting',
  'parts_listing_page.specifications.suspension_length': 'Length',
  'parts_listing_page.specifications.suspension_length_placeholder': 'Enter length',
  'parts_listing_page.specifications.suspension_stroke': 'Stroke',
  'parts_listing_page.specifications.suspension_stroke_placeholder': 'Enter stroke length',
  'parts_listing_page.specifications.axle_dimension': 'Axle dimension',
  'parts_listing_page.specifications.axle_dimension_placeholder': 'Select axle dimension',
  'parts_listing_page.specifications.crank_arm_length': 'Crank arm length',
  'parts_listing_page.specifications.crank_arm_length_placeholder': 'Enter crank arm length',
  'parts_listing_page.specifications.gears': 'Gears',
  'parts_listing_page.specifications.gears_placeholder': 'Select gears',
  'parts_listing_page.specifications.placement': 'Type',
  'parts_listing_page.specifications.activation': 'Activation',
  'parts_listing_page.specifications.front_gears': 'Front gears',
  'parts_listing_page.specifications.front_gears_placeholder': 'Select front gears',
  'parts_listing_page.specifications.rear_gears': 'Rear gears',
  'parts_listing_page.specifications.rear_gears_placeholder': 'Select rear gears',
  'parts_listing_page.specifications.chainring_mounting': 'Mounting',
  'parts_listing_page.specifications.chainring_mounting_placeholder': 'Select mounting',
  'parts_listing_page.specifications.chainring_teeth': 'Teeth',
  'parts_listing_page.specifications.chainring_teeth_placeholder': 'Enter teeth number',
  'parts_listing_page.specifications.bottom_bracket_mounting': 'Mounting',
  'parts_listing_page.specifications.bottom_bracket_mounting_placeholder': 'Select mounting',
  'parts_listing_page.specifications.bottom_bracket_width': 'Width',
  'parts_listing_page.specifications.bottom_bracket_width_placeholder': 'Enter width',
  'parts_listing_page.specifications.pedals': 'Type',
  'parts_listing_page.specifications.pedals_placeholder': 'Select type',
  'parts_listing_page.specifications.brake_type': 'Type',
  'parts_listing_page.specifications.brakes_activation': 'Activation',
  'parts_listing_page.specifications.rotor_mounting': 'Mounting',
  'parts_listing_page.specifications.rotor_placement': 'Type',
  'parts_listing_page.specifications.front_disk_diameter': 'Front disk diameter',
  'parts_listing_page.specifications.front_disk_diameter_placeholder': 'Enter front disk diameter',
  'parts_listing_page.specifications.rear_disk_diameter': 'Rear disk diameter',
  'parts_listing_page.specifications.rear_disk_diameter_placeholder': 'Enter rear disk diameter',
  'parts_listing_page.specifications.wheels_placement': 'Type',
  'parts_listing_page.specifications.front_wheel_size': 'Front wheel size',
  'parts_listing_page.specifications.front_wheel_size_placeholder': 'Select front wheel size',
  'parts_listing_page.specifications.rear_wheel_size': 'Rear wheel size',
  'parts_listing_page.specifications.rear_wheel_size_placeholder': 'Select rear wheel size',
  'parts_listing_page.specifications.hubs_placement': 'Type',
  'parts_listing_page.specifications.front_hub_standard': 'Front dimension',
  'parts_listing_page.specifications.front_hub_standard_placeholder': 'Select front hub dimension',
  'parts_listing_page.specifications.rear_hub_standard': 'Rear dimension',
  'parts_listing_page.specifications.rear_hub_standard_placeholder': 'Select rear hub dimension',
  'parts_listing_page.specifications.inner_rim_width': 'Inner rim width',
  'parts_listing_page.specifications.inner_rim_width_placeholder': 'Enter inner rim width',
  'parts_listing_page.specifications.handlebar_type': 'Type',
  'parts_listing_page.specifications.handlebar_type_placeholder': 'Select handlebar type',
  'parts_listing_page.specifications.handlebar_width': 'Width',
  'parts_listing_page.specifications.handlebar_width_placeholder': 'Enter handlebar width',
  'parts_listing_page.specifications.stem_length': 'Length',
  'parts_listing_page.specifications.stem_length_placeholder': 'Enter stem length',
  'parts_listing_page.specifications.grips_type': 'Type',
  'parts_listing_page.specifications.grips_type_placeholder': 'Select type',
  'parts_listing_page.specifications.steerer_type': 'Steerer type',
  'parts_listing_page.specifications.steerer_type_placeholder': 'Select steerer type',
  'parts_listing_page.specifications.dropper_travel': 'Travel',
  'parts_listing_page.specifications.dropper_travel_placeholder': 'Enter travel',
  'parts_listing_page.specifications.dropper_diameter': 'Diameter',
  'parts_listing_page.specifications.dropper_diameter_placeholder': 'Select diameter',
  'parts_listing_page.specifications.dropper_activation': 'Activation',
  'parts_listing_page.specifications.dropper_activation_placeholder': 'Select activation',
  'parts_listing_page.specifications.seat_length': 'Length',
  'parts_listing_page.specifications.seat_length_placeholder': 'Enter seat length',
  'parts_listing_page.specifications.brake_type_placeholder': 'Select type',

  'parts_listing_page.general_details.tab_title': 'Add some photos of your part',
  'parts_listing_page.general_details.description.label': 'Description',
  'parts_listing_page.general_details.description.placeholder':
    'Other information about selling item, include description of flaws, packaging, receipt and other important specifications.',
  'parts_listing_page.general_details.description.required': 'Please provide part description',
  'parts_listing_page.location.label': 'Location',
  'parts_listing_page.general_details.shipping.label': 'Shipping',
  'parts_listing_page.general_details.shipping.required': 'Please select shipping option',
  'parts_listing_page.general_details.image_upload.info': 'Choose at least 1 photo',

  'your_listings_page.edit_listing': 'Edit listing',
  'your_listings_page.delete_listing': 'Delete listing',
  'your_listings_page.delete_draft': 'Delete draft',
  'your_listings_page.title': 'Bikes for sale',
  'your_listings_page.schema_title': 'Your listings',
  'your_listings_page.delete_modal.title': 'Are you sure you want to delete bike?',
  'your_listings_page.delete_modal.delete_action': 'Delete',
  'your_listings_page.delete_modal.cancel_action': 'Cancel',

  'inbox_page.label': 'Inbox',
  'inbox_page.empty_state.message': "You don't have any messages",
  'inbox_page.message_input.placeholder': 'Send a message',

  'about_page.title': 'About us',
  'about_page.mission_statement':
    "We're on a mission to make cycling accessible and affordable for everyone, regardless of their age, gender, physical ability, or socioeconomic status. We're passionate about promoting sustainable transportation and helping people adopt healthy lifestyles. That's why our platform connects buyers with sellers from all over the world, providing a wide range of high-quality used bikes at affordable prices.",
  'about_page.sustainability_title': 'Sustainability',
  'about_page.sustainability_statement':
    'Cycling and buying a used bike are sustainable choices that benefit both individuals and the environment. Cycling reduces carbon emissions and air pollution, while buying a used bike extends its lifespan and reduces waste. Additionally, cycling promotes a healthier lifestyle, reducing the burden on the healthcare system. These sustainable choices contribute to a healthier and more sustainable future for all.',
  'about_page.our_work_title': 'Our work',
  'about_page.our_work_statement':
    'We are here since the very first downhill race in Lithuania in 2008. Our deep passion for cycling and the sense of community it brings has driven us ever since. We have poured our hearts into fostering a welcoming environment where everyone can access the joys of biking. We have created films, organized events, taught others what we have learned the hard way, and we strive to spread our passion for cycling far and wide. We firmly believe that the love of riding should be shared and are dedicated to making that a reality.',
  'about_page.our_team_title': 'Our team',
  'about_page.our_team_statement':
    "We're a passionate team based in Vilnius, Lithuania, dedicated to solving biking problems. We strive to improve the cycling experience and promote sustainable transportation solutions. Let's work together to create a brighter future for all.",

  'profile_settings.title': 'Profile settings',
  'profile_settings.first_name.label': 'First name',
  'profile_settings.first_name.placeholder': 'First name',
  'profile_settings.first_name.required': 'Please fill in first name',
  'profile_settings.last_name.label': 'Last name',
  'profile_settings.last_name.placeholder': 'Last name',
  'profile_settings.last_name.required': 'Please fill in last name',
  'profile_settings.bio.label': 'Bio',
  'profile_settings.bio.placeholder': 'Tell us a little bit about yourself...',
  'profile_settings.save': 'Save changes',
  'profile_settings.profile_picture.file_too_large': 'Please upload smaller picture',
  'profile_settings.profile_picture.upload_failed': 'Profile picture upload failed',
  'profile_settings.profile_picture.edit': 'Edit picture',

  'account_settings.label': 'Settings',
  'account_settings.page_title': 'Account settings',
  'account_settings.email.heading': 'Contact details',
  'account_settings.email.verified': 'Your email address is verified.',
  'account_settings.errors.generic': 'Something went wrong, please try again.',
  'account_settings.email.label': 'Email address',
  'account_settings.email.required': 'Please enter email',
  'account_settings.email.invalid': 'Entered email address is invalid',
  'account_settings.email.taken': 'Email address already in use',
  'account_settings.confirm_password.label': 'Password',
  'account_settings.confirm_password.placeholder': 'Enter new password',
  'account_settings.confirm_password.required': 'Please enter password',
  'account_settings.confirm_password.too_short':
    'Password should be at least {minLength} characters',
  'account_settings.confirm_email_change_label':
    'To verify email address change, please enter your current password',
  'account_settings.confirm_password.failed': 'Incorrect password',

  'account_settings.phone.label': 'Phone number',
  'account_settings.phone.placeholder': 'Please enter phone number',
  'account_settings.phone.error': 'Please enter valid phone number',
  'account_settings.phone.contact_allowed.title': 'Show phone number on ads',
  'account_settings.phone.contact_allowed.description':
    'Allow other users to see your phone number on your ads',

  'account_settings.password.heading': 'Security',

  'account_settings.save_changes': 'Save changes',

  'password_recovery_page.forgot_password.title': 'Forgot your password?',
  'password_recovery_page.forgot_password.message':
    "Enter your email address that you used to register. We'll send you an email with a link to reset your password.",
  'password_recovery_page.forgot_password.email.label': 'Email',
  'password_recovery_page.forgot_password.email.placeholder': 'Email',
  'password_recovery_page.forgot_password.email.required': 'Please enter email',
  'password_recovery_page.forgot_password.email.not_found': 'Email not found',
  'password_recovery_page.forgot_password.email.invalid': 'Email invalid',
  'password_recovery_page.forgot_password.submit': 'Send',

  'PasswordRecoveryForm.emailInvalid': 'A valid email address is required',
  'PasswordRecoveryForm.emailLabel': 'Email',
  'PasswordRecoveryForm.emailNotFound':
    "Hmm. We didn't find an account with that email address. Please double-check the address and try again.",
  'PasswordRecoveryForm.emailPlaceholder': 'john.doe@example.com',
  'PasswordRecoveryForm.emailRequired': 'This field is required',
  'PasswordRecoveryForm.loginLinkInfo': 'Suddenly remembered your password? {loginLink}',
  'PasswordRecoveryForm.loginLinkText': 'Log in.',
  'PasswordRecoveryForm.sendInstructions': 'Send instructions',
  'PasswordRecoveryPage.actionFailedMessage':
    'Something went wrong. Please refresh the page and try again.',
  'PasswordRecoveryPage.actionFailedTitle': 'Whoops!',
  'PasswordRecoveryPage.emailSubmittedMessage':
    'The instructions for resetting your password have been sent to {submittedEmailText}.',
  'PasswordRecoveryPage.emailSubmittedTitle': 'Check your inbox',
  'PasswordRecoveryPage.fixEmailInfo': 'Whoops, typo in your email? {fixEmailLink}',
  'PasswordRecoveryPage.fixEmailLinkText': 'Fix it.',
  'PasswordRecoveryPage.forgotPasswordMessage':
    "No worries! Please enter the email address you used when signing up and we'll send you instructions on how to set a new password.",
  'PasswordRecoveryPage.forgotPasswordTitle': 'Forgot your password?',
  'PasswordRecoveryPage.resendEmailInfo': "Didn't get the email? {resendEmailLink}",
  'PasswordRecoveryPage.resendEmailLinkText': 'Send another email.',
  'PasswordRecoveryPage.resendingEmailInfo': 'Resending instructions…',
  'PasswordRecoveryPage.title': 'Request a new password',

  'favorite_listings_page.label': 'Favorite listings',
  'favorite_listings_page.search_button.bike': 'Search bikes',
  'favorite_listings_page.search_button.parts': 'Search parts',
  'favorite_listings_page.empty_state.bike.title': 'Your saved bikes',
  'favorite_listings_page.empty_state.bike.description':
    'Once you save the bike, it will appear here',
  'favorite_listings_page.empty_state.parts.title': 'Your saved bike parts',
  'favorite_listings_page.empty_state.parts.description':
    'Once you save the bike part, it will appear here',
  'favorite_listings_page.schema_title': 'Favorite listings',
  'favorite_listings_page.tabs.bikes': 'Bikes',
  'favorite_listings_page.tabs.parts': 'Parts',

  'sign_up.confirm_signup_with_idp_title': 'Sign up with {idp}',
  'sign_up.confirm_signup_info_text': 'Please check that your information is correct.',

  'cookies.title': 'We use cookies',
  'cookies.consent_message':
    'NextVelo uses cookies to improve your browsing experience, personalize content and ads, and analyze site traffic. By clicking "Accept", you consent to the use of cookies. If you prefer not to allow cookies, you can disable them in your browser settings.',
  'cookies.accept': 'Accept',

  'pagination.previous': 'Previous page',
  'pagination.next': 'Next page',
  'pagination.to_page': 'Go to {page} page',

  'validators.min_max_value': 'Value must be between {minValue} and {maxValue}',
  'validators.min_max_value.invalid_type.numeric': 'Value should be numeric',

  'about_page.schema_title': 'About us | {siteTitle}',
  'about_page.schema_description': 'About NextVelo',
  'listing_page.schema_title': '{title} | {siteTitle}',
  'profile_page.schema_title': '{name} | {siteTitle}',
  'search_page.schema_for_search': 'a query',
  'page.schema_title': 'Sell and buy bikes | {siteTitle}',
  'page.schema_description': 'The largest online community for bikes.',
  'edit_listing_page.schema_title': 'Create a listing',
  'log_in.page_schema': 'Log in | Nextvelo',
  'sign_up.page_schema': 'Sign up | Nextvelo',
  'password_recovery_page.title': 'Request a new password',
  'inbox_page.schema_title': 'Inbox',
  'new_listing_page.schema_title': 'New listing',

  'search_page.schema_title': 'Buy used bikes | NextVelo',
  'search_page.schema_description': 'Buy & sell pre-owned bicycles on NextVelo.',
  'search_page.schema_title.bike': 'Discover Your Next Ride: Quality Used Bikes | NextVelo',
  'search_page.schema_description.bike':
    'Explore a wide range of quality used bicycles at NextVelo. Find your ideal road, mountain, or gravel bike with secure transactions and buyer protection. Start your cycling adventure today.',
  'search_page.schema_title.bike.road': 'High-Performance Road Bikes | NextVelo',
  'search_page.schema_description.bike.road':
    'Discover top-tier road bikes for cyclists who demand speed and efficiency. Browse our collection for lightweight, aerodynamic designs at NextVelo.',
  'search_page.schema_title.bike.mountain': 'Mountain Bikes | MTB | NextVelo',
  'search_page.schema_description.bike.mountain':
    'Tackle the toughest trails with our robust mountain bikes. Engineered for durability and control, our selection at NextVelo meets all your off-road cycling needs.',
  'search_page.schema_title.bike.gravel': 'Gravel Bikes | NextVelo',
  'search_page.schema_description.bike.gravel':
    'Venture beyond the paved roads with our versatile gravel bikes, designed for comfort and resilience on mixed terrain. Explore more with NextVelo.',
  'search_page.schema_title.bike.city': 'City Bikes for Sale | Urban Cycling | NextVelo',
  'search_page.schema_description.bike.city':
    'Explore our premium selection of city bikes perfect for urban cycling enthusiasts. Find the ideal blend of comfort and style for your daily commute at NextVelo.',
  'search_page.schema_title.bike.electric': 'Electric Bikes | E-Bikes | NextVelo',
  'search_page.schema_description.bike.electric':
    'Boost your ride with our electric bikes, offering power-assisted cycling for ease and speed. Discover the perfect e-bike to suit your lifestyle at NextVelo.',
  'search_page.schema_title.bike.bmx_dirt': 'BMX & Dirt Bikes | NextVelo',
  'search_page.schema_description.bike.bmx_dirt':
    'Jump into action with our BMX and dirt bikes, designed for freestyle and off-road challenges at NextVelo.',
  'search_page.schema_title.bike.folding': 'Compact Folding Bikes | Portable Bikes | NextVelo',
  'search_page.schema_description.bike.folding':
    'Maximize convenience with our folding bikes, combining portability and performance. Ideal for commuting and storage in tight spaces, find yours at NextVelo.',
  'search_page.schema_title.bike.other':
    'Unique & Specialty Bikes | Find Your Perfect Ride | NextVelo',
  'search_page.schema_description.bike.other':
    'Looking for something different? Find unique and specialty bikes for all preferences. Dive into our eclectic collection at NextVelo.',

  'search_page.schema_title.parts': 'Bike Parts & Components | NextVelo',
  'search_page.schema_description.parts':
    'Shop high-quality bike parts and components. Find frames, suspension, drivetrains, brakes, and more to upgrade your ride at NextVelo',
  'search_page.schema_title.parts.frame': 'Bike Frames for Sale | NextVelo',
  'search_page.schema_description.parts.frame':
    "Discover durable and lightweight bike frames. Choose the perfect foundation for your custom build with NextVelo's selection.",
  'search_page.schema_title.parts.suspension': 'Bike Suspension Systems | NextVelo',
  'search_page.schema_description.parts.suspension':
    'Enhance your ride with top-rated bike suspension systems. Find forks and shocks for smooth performance at NextVelo.',
  'search_page.schema_title.parts.drivetrain': 'Bike Drivetrains | NextVelo',
  'search_page.schema_description.parts.drivetrain':
    'Upgrade your bike with reliable drivetrains. Shop chains, derailleurs, and cranks for efficient power transfer at NextVelo.',
  'search_page.schema_title.parts.brakes': 'Bike Brakes | NextVelo',
  'search_page.schema_description.parts.brakes':
    'Ensure your safety with high-performance bike brakes. From disc to rim brakes, find all you need at NextVelo.',
  'search_page.schema_title.parts.wheels': 'Bike Wheels & Rims | NextVelo',
  'search_page.schema_description.parts.wheels':
    'Roll smoothly with our selection of bike wheels and rims. Discover durable options for all terrains at NextVelo.',
  'search_page.schema_title.parts.tyres_tubes': 'Bike Tyres for Every Terrain | NextVelo',
  'search_page.schema_description.parts.tyres_tubes':
    'Find the perfect bike tyres for any terrain at NextVelo. Choose from road, mountain, and gravel options.',
  'search_page.schema_title.parts.cockpit': 'Bike Handlebars & Cockpit Components | NextVelo',
  'search_page.schema_description.parts.cockpit':
    'Customize your control center with bike handlebars and cockpit components. Shop quality parts for comfort and performance at NextVelo.',
  'search_page.schema_title.parts.seat': 'Bike Seats & Saddles | NextVelo',
  'search_page.schema_description.parts.seat':
    'Experience comfort with our range of bike seats and saddles. Find the right fit for long rides at NextVelo.',

  'PrivacyPolicyPage.schemaTitle': 'Privacy Policy | {siteTitle}',
};
