export const ltTranslations = {
  'landing_page.sell_button': 'Parduok',
  'landing_page.buy_button': 'Rask naują dviratį',
  'landing_page.section_hero.sub_title': 'Įkelk nemokamai',
  'landing_page.section_hero.title': 'Turi nenaudojamą dviratį?',
  'landing_page.section_hero.description_1': 'Platforma sukurta sporto entuziastams.',
  'landing_page.section_hero.description_2': 'Pasiek tūkstančius pirkėjų nemokamai.',
  'landing_page.schema_description':
    'Pirkite ir parduokite naudotus plento, gravel ir kalnų dviračius didžiausioje naudotų dviračių internetinėje platformoje NextVelo. Su pirkėjų apsauga, greitu pristatymu ir saugiais mokėjimais.',
  'Landing_page.schema_title': 'Atraskite plento, Gravel ar MTB dviračius | NextVelo',
  'landing_page.categories_label': 'Kategorijos',
  'landing_page.most_recent_label': 'Naujausi dviračiai',
  'landing_page.most_popular_label': 'Populiariausi',

  'locales.english': 'EN',
  'locales.lithuanian': 'LT',
  'locales.polish': 'PL',

  'months.january': 'Sausio',
  'months.february': 'Vasario',
  'months.march': 'Kovo',
  'months.april': 'Balandžio',
  'months.may': 'Gegužės',
  'months.june': 'Birželio',
  'months.july': 'Liepos',
  'months.august': 'Rugpjūčio',
  'months.september': 'Rugsėjo',
  'months.october': 'Spalio',
  'months.november': 'Lapkričio',
  'months.december': 'Gruodžio',

  'landing_page.section_info.label': 'Kaip veikia NextVelo?',

  'landing_page.section_info.sell': 'Parduok',
  'landing_page.section_info.sell.question_0': 'Papasakok apie parduodamą dviratį',
  'landing_page.section_info.sell.answer_0':
    'Pateik dviračio specifikacijas kaip dydį, medžiagą, spalvą ir kitas detales.',
  'landing_page.section_info.sell.question_1': 'Atsakyk į užklausas',
  'landing_page.section_info.sell.answer_1':
    'Bendrauk su potencialiais pirkėjais per susirašinėjimo sistemą, atsakyk į klausimus ar suplanuok testinį važiavimą.',
  'landing_page.section_info.sell.question_2': 'Patvirtink apmokėjimą',
  'landing_page.section_info.sell.answer_2':
    'Susitik su pirkėju ir gauk pinigus grynaisiais, arba sutark dėl pavedimo internetu.',

  'landing_page.section_info.buy': 'Pirk',
  'landing_page.section_info.buy.question_0': 'Išsirink mėgstamą dviratį',
  'landing_page.section_info.buy.answer_0':
    'Ieškok dviračio pagal dydį, tipą, ar kitas dviračio savybes.',
  'landing_page.section_info.buy.question_1': 'Sužinok daugiau apie dviratį',
  'landing_page.section_info.buy.answer_1':
    'Susitik su pardavėju bandomajam važiavimui, arba suplanuok siuntimą.',
  'landing_page.section_info.buy.question_2': 'Patvirtink apmokėjimo būdą',
  'landing_page.section_info.buy.answer_2':
    'Susitik su pardavėju ir mokėk grynaisiais, arba sutarkite dėl pinigų pavedimo.',

  'config.labels.condition': 'Būklė',
  'config.labels.shipping': 'Siuntimas',
  'config.labels.receiptAvailable': 'Pirkimo dokumentai',
  'config.labels.frameColor': 'Spalva',
  'config.labels.frameMaterial': 'Rėmas',
  'config.labels.wheelSize': 'Ratų dydis',
  'config.labels.frameSize': 'Rėmo dydis',
  'config.labels.year': 'Metai',
  'config.labels.brand': 'Gamintojas',
  'config.labels.category': 'Dviračio kategorija',
  'config.labels.price': 'Kaina',
  'config.labels.keyword': 'Raktinis žodis',
  'config.labels.model': 'Modelis',
  'config.labels.country': 'Šalis',

  'categories.city': 'Miesto',
  'categories.road': 'Plento',
  'categories.mountain': 'Kalnų',
  'categories.gravel': 'Gravel',
  'categories.bmx_dirt': 'Bmx ir Dirt',
  'categories.folding': 'Sulankstomas',
  'categories.electric': 'Elektrinis',
  'categories.other': 'Kita',

  'year.placeholder': 'Pasirink metus',
  'frame_size.placeholder': 'Pasirink rėmo dydį',
  'brand.placeholder': 'Pasirink gamintoją',
  'country.placeholder': 'Pasirink šalį',

  'country.LT': 'Lietuva',
  'country.LV': 'Latvija',
  'country.EE': 'Estija',
  'country.PL': 'Lenkija',
  'country.all': 'Visos',

  'frame_size.xxl': 'XXL',
  'frame_size.xl': 'XL',
  'frame_size.l': 'L',
  'frame_size.m': 'M',
  'frame_size.s': 'S',
  'frame_size.xs': 'XS',
  'frame_size.xxs': 'XSS',

  'front_travel.60': '60',
  'front_travel.70': '70',
  'front_travel.80': '80',
  'front_travel.90': '90',
  'front_travel.100': '100',
  'front_travel.110': '110',
  'front_travel.120': '120',
  'front_travel.130': '130',
  'front_travel.140': '140',
  'front_travel.150': '150',
  'front_travel.160': '160',
  'front_travel.170': '170',
  'front_travel.180': '180',
  'front_travel.190': '190',
  'front_travel.200': '200',
  'front_travel.210': '210',
  'front_travel.220': '220',
  'front_travel.230': '230',

  'rear_travel.60': '60',
  'rear_travel.70': '70',
  'rear_travel.80': '80',
  'rear_travel.90': '90',
  'rear_travel.100': '100',
  'rear_travel.110': '110',
  'rear_travel.120': '120',
  'rear_travel.130': '130',
  'rear_travel.140': '140',
  'rear_travel.150': '150',
  'rear_travel.160': '160',
  'rear_travel.170': '170',
  'rear_travel.180': '180',
  'rear_travel.190': '190',
  'rear_travel.200': '200',
  'rear_travel.210': '210',
  'rear_travel.220': '220',
  'rear_travel.230': '230',

  'sub_categories.road': 'Plento',
  'sub_categories.track': 'Treko',
  'sub_categories.aearo_triathlon_tt': 'Aearo/Triathlon/TT',
  'sub_categories.single_gear': 'Single gear',
  'sub_categories.xc_cross_country': 'XC / Cross Country',
  'sub_categories.trail': 'Trail',
  'sub_categories.enduro': 'Enduro',
  'sub_categories.downhill': 'Downhill',
  'sub_categories.bmx': 'Bmx',
  'sub_categories.dirt_street': 'Dirt/Street Bikes',
  'sub_categories.electric_city': 'Miesto',
  'sub_categories.electric_mountain': 'Kalnų',
  'sub_categories.electric_road': 'Plento',
  'sub_categories.electric_other': 'Kita',
  'sub_categories.cargo': 'Cargo',
  'sub_categories.tricycle': 'Triratis',
  'sub_categories.tandem': 'Tandem',
  'sub_categories.balance': 'Balansinis',
  'sub_categories.unicycle': 'Vienaratis',

  'shipping.no_shipping': 'Atsiėmimas susitikus',
  'shipping.domestic_shipping': 'Siuntimas šalies viduje',
  'shipping.international_shipping': 'Tarptautinis',

  'frame_material.aluminum': 'Aliuminis',
  'frame_material.carbon': 'Karboninis',
  'frame_material.steel': 'Plieno',
  'frame_material.other': 'Kita',

  'frame_color.pink': 'Rožinis',
  'frame_color.green': 'Žalias',
  'frame_color.orange': 'Oranžinis',
  'frame_color.blue': 'Mėlynas',
  'frame_color.red': 'Raudonas',
  'frame_color.white': 'Baltas',
  'frame_color.yellow': 'Geltonas',
  'frame_color.black': 'Juodas',
  'frame_color.grey': 'Pilkas',
  'frame_color.other': 'Kita',

  'wheel_size.16': '16″',
  'wheel_size.20': '20″',
  'wheel_size.28': '28″ (700c)',
  'wheel_size.26': '26”',
  'wheel_size.27.5': '27.5” (650b)',
  'wheel_size.29': '29”',
  'wheel_size.650B': '650B',
  'wheel_size.700C': '28” (700c)',
  'wheel_size.other': 'Kita',

  'receipt.yes': 'Taip',
  'receipt.no': 'Ne',

  'condition.bad': 'Dviratis dalims',
  'condition.partly_bad': 'Reikia remonto',
  'condition.good': 'Geras',
  'condition.perfect': 'Puikios būklės',
  'condition.brand_new': 'Naujas',

  'condition.bad.label': `✔ Netinkamas važiuoti\n✔ Reikalinga apžiūra ir remontas\n✔ Reikalingas dalių keitimas`,
  'condition.partly_bad.label': `✔ Turi šiek tiek įbrėžimų\n✔ Reikalingas servisas\n✔ Reikia pakeisti kai kurias dalis`,
  'condition.good.label': `✔ Turi šiek tiek įbrėžimų\n✔ Gali reikėti profilaktinio dviračio aptarnavimo\n✔ Dalių keitimas nereikalingas`,
  'condition.perfect.label': `✔ Turi nežymių įbrėžimų\n✔ Techniškai tvarkingas\n✔ Nėra defektų`,
  'condition.brand_new.label': `✔ Nenaudotas dviratis`,

  'top_bar.search_form.placeholder': 'Paieška',
  'top_bar.generic_error_message': 'Kažkas nutiko. Bandyk dar kartą.',
  'top_bar.logo_icon_label': 'Eiti į pradinį puslapį',
  'top_bar.menu_icon_label': 'Atidaryti meniu',

  'top_bar.log_in': 'Prisijungti',
  'top_bar.log_out': 'Atsijungti',
  'top_bar.sell': 'Parduok',
  'top_bar.inbox_label': 'Žinutės',
  'top_bar.listings_label': 'Tavo skelbimai',
  'top_bar.profile_settings_label': 'Profilio nustatymai',
  'top_bar.account_settings_label': 'Nustatymai',
  'top_bar.profile': 'Profilis',

  'top_bar.mobile_menu.buy': 'Pirk',
  'top_bar.mobile_menu.about_page': 'Apie NextVelo',
  'top_bar.mobile_menu.how_it_works_page': 'How it works',
  'top_bar.mobile_menu.show_profile': 'Eiti į profilį',
  'top_bar.mobile_menu.account_label': 'Paskyra',
  'top_bar.mobile_menu.services_label': 'Paslaugos',
  'top_bar.mobile_menu.favorite_listings': 'Pamėgti skelbimai',
  'top_bar.buy': 'Pirk',

  'top_bar.desktop.logo_label': 'NextVelo',

  'authentication_page.or': 'arba',
  'authentication_page.facebook_login': 'Tęsti su Facebook',
  'authentication_page.google_login': 'Tęsti su Google',
  'authentication_page.email_login': 'Tęsti su el. paštu',
  'authentication_page.terms_and_conditions_accept_label':
    "Užsiregistruodamas patvirtinu, jog sutinku su NextVelo's {termsLink}, ir {privacyPolicyLink}",
  'authentication_page.terms_and_conditions_label': 'Bendrosiomis Taisyklėmis',
  'authentication_page.privacy_policy_label': 'Privatumo Politika',

  'log_in.email_label': 'El. paštas',
  'log_in.email_placeholder': 'El. paštas',
  'log_in.email_required': 'Įvesk el. paštą',
  'log_in.email_invalid': 'El. paštas neteisingas',
  'log_in.password_label': 'Slaptažodis',
  'log_in.password_placeholder': 'Slaptažodis',
  'log_in.password_required': 'Įvesk slaptažodį',
  'log_in.forgot_password': 'Pamiršai slaptažodį?',
  'log_in.log_in_label': 'Prisijunk',
  'log_in.sign_up_prompt': 'Neturi paskyros? ',
  'log_in.sign_up_label': 'Prisiregistruok',
  'log_in.failed': 'El. paštas ir slaptažodis neegzistuoja. Patikrink ir bandyk dar kartą',

  'sign_up.email_label': 'El. paštas',
  'sign_up.email_placeholder': 'El. paštas',
  'sign_up.email_required': 'Įvesk el. paštą',
  'sign_up.email_invalid': 'El. paštas neteisingas',
  'sign_up.password_label': 'Slaptažodis',
  'sign_up.password_placeholder': 'Slaptažodis',
  'sign_up.password_required': 'Įvesk slaptažodį',
  'sign_up.password_too_short': 'Slaptažodis turi būti bent {minLength} simbolių',
  'sign_up.password_too_long': 'Slaptažodis negali būti ilgesnis nei {maxLength} simbolių',
  'sign_up.first_name_label': 'Vardas',
  'sign_up.first_name_placeholder': 'Vardas',
  'sign_up.first_name_required': 'Įvesk vardą',
  'sign_up.last_name_label': 'Pavardė',
  'sign_up.last_name_placeholder': 'Pavardė',
  'sign_up.last_name_required': 'Įvesk pavardę',
  'sign_up.sign_up_label': 'Prisiregistruoti',
  'sign_up.log_in_prompt': 'Jau turi paskyrą? ',
  'sign_up.log_in_label': 'Prisijunk',

  'confirm_sign_up.email_label': 'El. paštas',
  'confirm_sign_up.email_placeholder': 'El. paštas',
  'confirm_sign_up.email_required': 'Įvesk el. paštą',
  'confirm_sign_up.email_invalid': 'El. paštas neteisingas',
  'confirm_sign_up.first_name_label': 'Vardas',
  'confirm_sign_up.first_name_placeholder': 'Pavardė',
  'confirm_sign_up.first_name_required': 'Įvesk vardą',
  'confirm_sign_up.last_name_label': 'Pavardė',
  'confirm_sign_up.last_name_placeholder': 'Pavardė',
  'confirm_sign_up.last_name_required': 'Įvesk pavardę',
  'confirm_sign_up.confirm_and_continue_label': 'Sutinku, tęsti',

  'avatar.banned_user_label': 'Užblokuotas narys',
  'avatar.deleted_user_label': 'Ištrintas narys',

  'footer.privacy': 'Privatumas',
  'footer.terms': 'Sąlygos',
  'footer.copyright': '© NextVelo',
  'footer.terms_of_service': 'Bendrosios Taisyklės',
  'footer.privacy_policy': 'Privatumo Politika',
  'footer.instagram_link': 'Eiti į Instagram puslapį',
  'footer.facebook_link': 'Eiti į Facebook puslapį',
  'footer.twitter_link': 'Eiti į Twitter puslapį',
  'footer.organization_description': 'Didžiausia internetinė dviratininkų bendruomenė.',
  'footer.copyright': '© NextVelo',
  'footer.buy_bike': 'Pirk',
  'footer.sell_bike': 'Parduok',
  'footer.about': 'Apie NextVelo',
  'footer.terms_conditions': 'Bendrosios Taisyklės',
  'footer.privacy_policy': 'Privatumo Politika',
  'footer.instagram_link': 'Eiti į Instagram puslapį',
  'footer.facebook_link': 'Go to Facebook page',
  'footer.linkedin_link': 'Go to LinkedIn page',
  'footer.organization_description': 'Didžiausia internetinė dviratininkų bendruomenė.',
  'footer.content_rights': '© NextVelo. All rights reserved.',

  'modal.close': 'Uždaryti langą',

  'filter_form.cancel': 'Atšaukti',
  'filter_form.clear': 'Išvalyti',
  'filter_form.submit': 'Pritaikyti',

  'actions.cancel': 'Atšaukti',
  'actions.clear': 'Išvalyti',
  'actions.submit': 'Pritaikyti',
  'actions.publish': 'Paskelbti',
  'actions.continue': 'Tęsti',
  'actions.back': 'Atgal',

  'search_page.price_filter.cancel': 'Atšaukti',
  'search_page.price_filter.clear': 'Išvalyti',
  'search_page.price_filter.plain_label': 'Kaina',
  'search_page.price_filter.label': 'Kaina:',
  'search_page.price_filter.submit': 'Pritaikyti',
  'search_page.price_filter.to': 'iki',
  'search_page.header.foundResults': 'Rasta - {count}',
  'search_page.header.loadingResults': 'Ieškoma rezultatų',
  'search_page.sortBy': 'Rikiuoti pagal',
  'search_page.filter_plain.selected': '• {count}',
  'search_page.multiple_filter.selected': '{labelText} • {count}',
  'search_page.reset_filters': 'Nuimti filtrus',
  'search_page.no_results': 'Nepavyko rasti rezulatų pagal pateiktą užklausą',
  'search_page.empty_state.message': 'Dėja, nepavyko rasti rezultatų pagal pateiktą užklausą',
  'search_page.price_filter.selected': '{minPrice} - {maxPrice}',
  'search_page.mobile_filters.label': 'Filtrai',
  'search_page.mobile_filters.reset': 'Atsatyti',
  'search_page.range_filter.label.selected': '{minValue} - {maxValue}',

  'search_page.mobile.show_listings': 'Rodyti {count}',

  'profile_page.label': 'Profilis',
  'profile_page.listing.view': 'Peržiūrėti',
  'profile_page.edit_profile': 'Keisti profilio detales',
  'profile_page.joined': 'Prisijungė {createdAt}',
  'profile_page.bikes.seller': 'Tavo dviračiai',
  'profile_page.bikes.buyer': 'Parduodamos prekės',
  'profile_page.data_load_failed': 'O ne, kažkas nutiko. Pabandyk dar kartą',
  'profile_page.bio_label': 'Apie',
  'profile_page.edit_listing': 'Keisti',
  'profile_page.delete_listing': 'Ištrinti',
  'profile_page.delete_modal.title': 'Ar tu tikras, kad nori ištrinti dviratį?',
  'profile_page.delete_modal.subtitle': 'Kaip pardavei prekę?',
  'profile_page.delete_modal.reason.nextvelo': 'Pirkėją radau NextVelo platformoje',
  'profile_page.delete_modal.reason.outside': 'Pirkėją radau kitur',
  'profile_page.delete_modal.reason.other': 'Kita',
  'profile_page.delete_modal.delete_action': 'Ištrinti',
  'profile_page.delete_modal.cancel_action': 'Atšaukti',
  'profile_page.no_listings.owner_label': 'Iškeisk seną dviratį į pinigus',

  'listing_page.sold_by': '{name}',
  'listing_page.loading': 'Kraunama',
  'listing_page.order_panel': 'Įkėlė {name}',
  'listing_page.contact_seller': 'Susisiek',
  'listing_page.description.about_bike_label': 'Apie dviratį',
  'listing_page.details.label': 'Išsami informacija',
  'listing_page.image_gallery.view_photos': 'Peržiūrėk nuotraukas ({count})',
  'listing_page.image_gallery.image_alt_text': 'Nuotrauka {index}/{count}',
  'listing_page.image_gallery.image_thumb_alt_text': 'Nuotrauka {index}/{count}',
  'listing_page.basic_information.label': 'Pagrindinė informacija',
  'listing_page.location.label': 'Lokacija',
  'listing_page.seller_details.label': 'Papildoma informacija iš pardavėjo',
  'listing_page.offered_by.label': 'Pardavėjas',
  'listing_page.add_to_favorites': 'Pridėti prie pamėgtų',
  'listing_page.remove_from_favorites': 'Pašalinti iš pamėgtų',
  'listing_page.offered_by.phone_number': 'Telefono numeris',

  'edit_listing_page.location.label': 'Vieta',
  'edit_listing_page.details.label': 'Daugiau',
  'edit_listing_page.categories.label': 'Kategorija',
  'edit_listing_page.general_details.label': 'Nuotraukos ir informacija',
  'edit_listing_page.show_listing_failed': 'Nepavyko pateikti',
  'edit_listing_page.update_failed': 'Nepavyko pateikti. Pabandyk dar kartą.',
  'edit_listing_page.upload_failed': 'Nepavyko įkelti.',
  'edit_listing_page.max_length': 'Turi būti {maxLength} simbolių ar mažiau',

  'edit_listing_page.location.tab_title': 'Parduok naują arba naudotą dviratį.',
  'edit_listing_page.location.address': 'Kur yra tavo dviratis?',
  'edit_listing_page.location.address_hint': 'Adresas bus matomas tik tau.',
  'edit_listing_page.location.address_not_recognized': 'Adresas nėra žinomas. Įvesk kitą adresą.',
  'edit_listing_page.location.address_placeholder': 'Įvesk adresą',
  'edit_listing_page.location.address_required': 'Turi įvesti adresą',
  'edit_listing_page.location.shipping_label': 'Siuntimas',
  'edit_listing_page.location.shipping_required': 'Pasirink siuntimo būdą',

  'edit_listing_page.categories.tab_title': 'Pasirink, kuris labiausiai atitinka tavo dviratį',
  'edit_listing_page.categories.type_select': 'Pasirink kategoriją',
  'edit_listing_page.categories.sub_category_required': 'Pasirink tipą',
  'edit_listing_page.categories.category_required': 'Turi pasirinkti dviračio kategoriją',

  'edit_listing_page.details.tab_title': 'Pagrindinė informacija apie dviratį',
  'edit_listing_page.details.frame_details': 'Rėmo informacija',
  'edit_listing_page.details.brand': 'Gamintojas',
  'edit_listing_page.details.brand_placeholder': 'Pasirink gamintoją',
  'edit_listing_page.details.brand_required': 'Turi pasirinkti gamintoją',
  'edit_listing_page.details.model': 'Modelis',
  'edit_listing_page.details.model_placeholder': 'Įvesk rėmo modelį',
  'edit_listing_page.details.model_required': 'Turi įvesti rėmo modelį',
  'edit_listing_page.details.year': 'Metai',
  'edit_listing_page.details.year_placeholder': 'Pasirink metus',
  'edit_listing_page.details.year_required': 'Turi pasirinkti metus',
  'edit_listing_page.details.frame_size': 'Rėmo dydis',
  'edit_listing_page.details.frame_size_placeholder': 'Pasirink rėmo dydį',
  'edit_listing_page.details.frame_size_required': 'Turi pasirinkti rėmo dydį',
  'edit_listing_page.details.frame_material': 'Rėmas',
  'edit_listing_page.details.frame_material_required': 'Pasirink rėmą',
  'edit_listing_page.details.frame_color': 'Spalva',
  'edit_listing_page.details.frame_color_required': 'Pasirink spalvą',
  'edit_listing_page.details.wheel_size': 'Ratų dydis',
  'edit_listing_page.details.wheel_size_info': 'Ratų diametras, dažnai nurodomas ant padangos',
  'edit_listing_page.details.wheel_size_placeholder': 'Pasirink ratų dydį',
  'edit_listing_page.details.wheel_size_required': 'Turi pasirinkti ratų dydį',
  'edit_listing_page.details.component_details': 'Detalių informacija',
  'edit_listing_page.details.component_details_placeholder':
    'Išvardink dviračio detales - šakę, bėgių sistemą, amortizatorius. Kaip pavyzdys - Shimano Dura-Ace bėgiai, Bontrager Aeolus Pro 3v TLR ratai',
  'edit_listing_page.details.component_details_required': 'Turi aprašyti dviračio detales',
  'edit_listing_page.details.frame_size_table_title': 'Dydžių lentelė',
  'edit_listing_page.details.frame_size_table_info':
    'Rėmo dydis dažniausiai yra nurodytas ant rėmo, esančio po sėdyne. Jei nepavyksta rasti, vadovaukitės lentelės duomenimis',
  'edit_listing_page.details.frame_size_table_header_1': 'Rėmo dydis',
  'edit_listing_page.details.frame_size_table_header_2': 'Rėmo dydis centimetrais',
  'edit_listing_page.details.wheel_size.hint': 'Ratų diametras, dažniausiai nurodomas ant padangos',

  'edit_listing_page.general_details.tab_title': 'Pridėk dviračio nuotraukas',
  'edit_listing_page.general_details.image_upload_failed': 'Nepavyko įkelti',
  'edit_listing_page.general_details.image_upload_over_limit':
    'Didžiausias leistinas nuotraukos dydis 20MB',
  'edit_listing_page.general_details.image_required': 'Turi įkelti bent 3 nuotraukas',
  'edit_listing_page.general_details.image_upload.info': 'Įkelk bent 3 nuotraukas',
  'edit_listing_page.general_details.image_upload.from_device': 'Įkelti iš įrenginio',
  'edit_listing_page.general_details.image_upload.add_more': 'Pridėti daugiau',
  'edit_listing_page.general_details.image_upload.main_image': 'Pagrindinė nuotrauka',
  'edit_listing_page.general_details.title': 'Pavadinimas',
  'edit_listing_page.general_details.title_placeholder': 'pvz. Trek slash 9.7',
  'edit_listing_page.general_details.title_required': 'Turi įvesti pavadinimą',
  'edit_listing_page.general_details.condition': 'Būklė',
  'edit_listing_page.general_details.condition_required': 'Turi pasirinkti dviračio būklę',
  'edit_listing_page.general_details.receipt': 'Ar dviratis turi pirkimo dokumentus?',
  'edit_listing_page.general_details.receipt_required':
    'Turi pasirinkti, ar dviratis turi pirkimo dokumentus',
  'edit_listing_page.general_details.price': 'Kaina',
  'edit_listing_page.general_details.placeholder': '€0.00',
  'edit_listing_page.general_details.price_required': 'Turi nurodyti kainą',
  'edit_listing_page.general_details.price_too_low': 'Kaina negali būti mažesnė nei {minPrice}.',
  'edit_listing_page.general_details.price_too_high': 'Kaina negali būti didesnė nei {maxPrice}.',
  'edit_listing_page.general_details.saved_image_alt': 'Saved listing image',

  'parts_listing_page.general_details.image_upload.info': 'Įkelk bent 1 nuotrauką',

  'your_listings_page.edit_listing': 'Koreguoti',
  'your_listings_page.delete_listing': 'Ištrinti',
  'your_listings_page.delete_draft': 'Ištrinti',
  'your_listings_page.title': 'Dviračiai pardavimui',
  'your_listings_page.schema_title': 'Tavo skelbimai',
  'your_listings_page.delete_modal.title': 'Ar tikrai nori ištrinti?',
  'your_listings_page.delete_modal.delete_action': 'Ištrinti',
  'your_listings_page.delete_modal.cancel_action': 'Atšaukti',

  'inbox_page.label': 'Žinutės',
  'inbox_page.empty_state.message': 'Dar neturi jokių žinučių',
  'inbox_page.message_input.placeholder': 'Išsiųsk žinutę',

  'about_page.title': 'Apie mus',
  'about_page.mission_statement':
    'Mūsų misija yra padaryti dviračius prieinamus ir įperkamus visiems, nepaisant amžiaus, lyties, fizinio pasiruošimo, socialinio ar ekonominio statuso. Mūsų aistra - skatinti tvarų transportą ir sveiką gyvenimo būdą. Mūsų platforma jungia pirkėjus ir pardavėjus, pateikiant platų, aukštos kokybės naudotų dviračių asortimentą prieinamomis kainomis.',
  'about_page.sustainability_title': 'Tvarumas',
  'about_page.sustainability_statement':
    'Važinėjimas dviračiu ir naudoto dviračio pirkimas yra tvarus pasirinkimas, naudingas ir žmonėms, ir aplinkai. Važiuojant dviračiu mažėja anglies dioksido išmetimas ir oro tarša, o perkant naudotą dviratį skatinamas sąmoningas gyvenimo būdas ir mažinamas atliekų kiekis. Be to, važinėjimas dviračiu skatina sveikesnį gyvenimo būdą ir mažina sveikatos priežiūros sistemai tenkančią naštą. Šie tvarūs pasirinkimai prisideda prie sveikesnės ir tvaresnės visų ateities.',
  'about_page.our_work_title': 'Mūsų darbai',
  'about_page.our_work_statement':
    'Esame čia nuo pat pirmųjų kalnų nusileidimo lenktynių Lietuvoje 2008 m.  Nuo tada atradome aistrą dviračių sportui ir jo bendruomenei. Visada stengėmės sukurti draugišką aplinką, kurioje kiekvienas galėtų pajausti važiavimo dviračiu džiaugsmą. Savo aistrą dviračių sportui stengiamės skleisti dalindamiesi savo sukaupta patirtimi, kurdami filmus, organizuodami renginius ir mokydami savo išmoktomis pamokomis. Mes įsitikinę, kad meile važinėti dviračiu reikia dalintis.',
  'about_page.our_team_title': 'Mūsų komanda',
  'about_page.our_team_statement':
    'Esame užsidegusi komanda, įsikūrusi Vilniuje, Lietuvoje, ir užsiimame viskuo, kas susiję su dviračių sportu. Siekiame gerinti važiavimo dviračiu patirtį ir skatinti tvarius transporto sprendimus. Dirbkime kartu, kad sukurtume šviesesnę ateitį sau ir kitiems',

  'profile_settings.title': 'Profilio nustatymai',
  'profile_settings.first_name.label': 'Vardas',
  'profile_settings.first_name.placeholder': 'Vardas',
  'profile_settings.first_name.required': 'Turi įvesti vardą',
  'profile_settings.last_name.label': 'Pavardė',
  'profile_settings.last_name.placeholder': 'Pavardė',
  'profile_settings.last_name.required': 'Turi įvesti pavardę',
  'profile_settings.bio.label': 'Aprašymas',
  'profile_settings.bio.placeholder': 'Papasakok apie save',
  'profile_settings.save': 'Išsaugoti',
  'profile_settings.profile_picture.file_too_large': 'Įkelk mažesnio dydžio nuotrauką',
  'profile_settings.profile_picture.upload_failed': 'Nepavyko įkelti',
  'profile_settings.profile_picture.edit': 'Keisti nuotrauką',

  'account_settings.label': 'Nustatymai',
  'account_settings.email.heading': 'Kontaktinės detalės',
  'account_settings.email.label': 'El. paštas',
  'account_settings.save_changes': 'Išsaugoti pakeitimus',
  'account_settings.email.invalid': 'Pašto adresas nevalidus',
  'account_settings.confirm_email_change_label': 'Įvesk savo dabartinį slaptažodį',
  'account_settings.confirm_password.label': 'Slaptažodis',
  'account_settings.confirm_password.placeholder': 'Slaptažodis',
  'account_settings.confirm_password.too_short':
    'Slaptažodis turi būti ne trumpesnis nei {minLength} simboliai',
  'account_settings.confirm_password.required': 'Įvesk slaptažodį',
  'account_settings.confirm_password.failed': 'Slaptažodis neteisingas',

  'account_settings.phone.label': 'Telefono numeris',
  'account_settings.phone.placeholder': 'Įvesk telefono numerį',
  'account_settings.phone.error': 'Telefono numeris neteisingas',
  'account_settings.phone.contact_allowed.title': 'Rodyti numerį skelbimo aprašyme',
  'account_settings.phone.contact_allowed.description':
    'Telefono numeris bus matomas aktyviuose skelbimuose',

  'password_recovery_page.forgot_password.title': 'Pamiršai slaptažodį?',
  'password_recovery_page.forgot_password.message':
    'Įvesk el. paštą, kuriuo registravaisi. Atsiųsime tau laišką su nuoroda pasikeisti slaptažodį.',
  'password_recovery_page.forgot_password.email.label': 'El. paštas',
  'password_recovery_page.forgot_password.email.placeholder': 'El. paštas',
  'password_recovery_page.forgot_password.email.required': 'Turi įvesti el. paštą',
  'password_recovery_page.forgot_password.email.not_found': 'El. paštas nerastas',
  'password_recovery_page.forgot_password.email.invalid': 'El. paštas neteisingas',
  'password_recovery_page.forgot_password.submit': 'Siųsti',

  'PasswordRecoveryForm.emailInvalid': 'Turi įvesti teisingą el. paštą',
  'PasswordRecoveryForm.emailLabel': 'El. paštas',
  'PasswordRecoveryForm.emailNotFound':
    'Hmm... Nepavyko rasti paskyros su nurodytu el. paštu. Patikrink, ar įvedei teisingai ir bandyk dar kartą.',
  'PasswordRecoveryForm.emailPlaceholder': 'john.doe@example.com',
  'PasswordRecoveryForm.emailRequired': 'Turi įvesti el. paštą',
  'PasswordRecoveryForm.loginLinkInfo': 'Netikėtai prisiminei savo slaptažodį? {loginLink}',
  'PasswordRecoveryForm.loginLinkText': 'Prisijungti',
  'PasswordRecoveryForm.sendInstructions': 'Siųsti instrukcijas',
  'PasswordRecoveryPage.actionFailedMessage': 'Kažkas nutiko. Bandyk dar kartą.',
  'PasswordRecoveryPage.actionFailedTitle': 'Oops!',
  'PasswordRecoveryPage.emailSubmittedMessage':
    'Instrukcijos, kaip atkurti slaptažodį nusiųstos į tavo el. paštą, {submittedEmailText}.',
  'PasswordRecoveryPage.emailSubmittedTitle': 'Patikrinti žinutes',
  'PasswordRecoveryPage.fixEmailInfo': 'Oops, klaidelė el.pašte? {fixEmailLink}',
  'PasswordRecoveryPage.fixEmailLinkText': 'Pataisyk.',
  'PasswordRecoveryPage.forgotPasswordMessage':
    'Nesijaudink! Įvesk el. paštą, su kuriuo registravaisi ir atsiųsime instrukcijas, kaip atkurti savo slaptažodį.',
  'PasswordRecoveryPage.forgotPasswordTitle': 'Pamiršai slaptažodį?',
  'PasswordRecoveryPage.resendEmailInfo': 'Negavai laiško? {resendEmailLink}',
  'PasswordRecoveryPage.resendEmailLinkText': 'Siųsti dar kartą.',
  'PasswordRecoveryPage.resendingEmailInfo': 'Siunčiam instrukcijas..',
  'PasswordRecoveryPage.title': 'Naujas slaptažodis',

  'favorite_listings_page.label': 'Pamėgti skelbimai',
  'favorite_listings_page.search_button': 'Dviračių paieška',
  'favorite_listings_page.empty_state.title': 'Tavo pamėgti dviračiai',
  'favorite_listings_page.empty_state.description': 'Kai pamėgsi dviratį, jis pasirodys čia',
  'favorite_listings_page.schema_title': 'Pamėgti skelbimai',

  'pagination.previous': 'Praeitas puslapir',
  'pagination.next': 'Sekantis puslapis',
  'pagination.to_page': 'Eiti į {page} puslapį',

  'cookies.title': 'Svetainėje naudojame slapukus',
  'cookies.consent_message':
    'NextVelo naudoja slapukus siekdami gerinti jūsų naršymo patirtį, suasmeninti turinį ir reklamas. Tęsdami naršymą sutinkate su privatumo politika.',
  'cookies.accept': 'Sutinku',

  'categories.all': 'Dviračiai ir dalys',
  'categories.bike': 'Dviračiai',
  'categories.parts': 'Dalys',

  'categories.frame': 'Rėmas',
  'categories.suspension': 'Amortizatoriai',
  'categories.drivetrain': 'Grupė',
  'categories.brakes': 'Stabdžiai',
  'categories.wheels': 'Ratai',
  'categories.tyres_tubes': 'Padangos',
  'sub_categories.tyres_tubes': 'Padangos',
  'categories.cockpit': 'Vairo dalys',
  'categories.seat': 'Sedynės',

  'config.labels.subCategory': 'Subkategorija',
  'config.labels.discipline': 'Tipas',
  'config.labels.frontTravel': 'Priekinė eiga',
  'config.labels.rearTravel': 'Galinė eiga',
  'config.labels.dropperTravel': 'Eiga',
  'config.labels.dropperDiameter': 'Diametras',
  'config.labels.dropperActivation': 'Aktyvacija',
  'config.labels.catalogTree': 'Kategorija',
  'config.labels.hubStandard': 'Stebulės standartas',
  'config.labels.frontHubStandard': 'Priekinė stebulė',
  'config.labels.rearHubStandard': 'Galinė stebulė',
  'config.labels.suspensionType': 'Amortizacijos tipas',
  'config.labels.suspensionMounting': 'Amortizacijos tvirtinimas',
  'config.labels.suspensionLength': 'Ilgis',
  'config.labels.suspensionStroke': 'Eiga',
  'config.labels.axleDimension': 'Ašies matmenys',
  'config.labels.gears': 'Pavaros',
  'config.labels.frontGears': 'Priekinės pavaros',
  'config.labels.rearGears': 'Galinės pavaros',
  'config.labels.placement': 'Tipas',
  'config.labels.activation': 'Aktyvacija',
  'config.labels.chainringMounting': 'Dantračių tvirtinimas',
  'config.labels.bottomBracketMounting': 'Tvirtinimas',
  'config.labels.pedals': 'Tipas',
  'config.labels.frontWheelSize': 'Priekinio rato dydis',
  'config.labels.rearWheelSize': 'Galinio rato dydis',
  'config.labels.wheelsPlacement': 'Tipas',
  'config.labels.brakeType': 'Tipas',
  'config.labels.brakesActivation': 'Aktyvacija',
  'config.labels.hubsPlacement': 'Tipas',
  'config.labels.handlebarType': 'Tipas',
  'config.labels.gripsType': 'Tipas',
  'config.labels.steererType': 'Tipas',
  'config.labels.crankArmLength': 'Švaistiklio ilgis',
  'config.labels.chainringTeeth': 'Dantračiai',
  'config.labels.handlebarWidth': 'Vairo plotis',

  'suspension_type.air': 'Orinė amortizacija',
  'suspension_type.coil': 'Spyruoklinė amortizacija',

  'suspension_mounting.trunnion': 'Trunnion',
  'suspension_mounting.standard': 'Standard',

  'discipline.xc_marathon': 'XC / Marathon',
  'discipline.trail_all_mountain': 'Trail / All-Mountain',
  'discipline.enduro': 'Enduro',
  'discipline.downhill': 'Downhill',

  'hub_standard.15_110': '15 x 110mm (Boost)',
  'hub_standard.20_110': '20 x 110mm (Boost)',
  'hub_standard.9_100': '9 x 100mm',
  'hub_standard.15_100': '15 x 100mm',
  'hub_standard.20_100': '20 x 100mm',
  'hub_standard.9_135': '9 x 135mm',
  'hub_standard.10_135': '10 x 135mm',
  'hub_standard.15_142': '15 x 142mm',
  'hub_standard.15_150': '15 x 150mm',
  'hub_standard.10_170': '10 x 170mm',
  'hub_standard.12_190': '12 x 190mm',
  'hub_standard.12_197': '12 x 197mm',

  'suspension_type.air': 'Orinė',
  'suspension_type.coil': 'Spyruoklinė',

  'axle_dimension.30': '30mm',
  'axle_dimension.29_9': '29.9mm',
  'axle_dimension.22_24': '22/24mm (DUB)',
  'axle_dimension.25': '25mm',
  'axle_dimension.24': '24mm',
  'axle_dimension.19': '19mm',
  'axle_dimension.square_taper': 'Square taper',
  'axle_dimension.octalink': 'Octalink',
  'axle_dimension.m30': 'M30',
  'axle_dimension.isis': 'ISIS',
  'axle_dimension.american': 'American',
  'axle_dimension.mid': 'Mid',

  'gears.1': '1',
  'gears.2': '2',
  'gears.3': '3',
  'gears.4': '4',
  'gears.5': '5',
  'gears.6': '6',
  'gears.7': '7',
  'gears.8': '8',
  'gears.9': '9',
  'gears.10': '10',
  'gears.11': '11',
  'gears.12': '12',
  'gears.13': '13',

  'placement.front': 'Priekis',
  'placement.rear': 'Galas',
  'placement.set': 'Rinkinys',

  'front_travel.placeholder': 'Įvesk priekinę eigą',
  'rear_travel.placeholder': 'Įvesk galinę eigą',

  'activation.mechanical': 'Mechaninė',
  'activation.electric': 'Elektrinė',

  'chainring_mounting.3_arm': '3-arm',
  'chainring_mounting.4_arm': '4-arm',
  'chainring_mounting.5_arm': '5-arm',

  'bottom_bracket_mounting.bsa': 'BSA',
  'bottom_bracket_mounting.ita': 'ITA',
  'bottom_bracket_mounting.pressfit': 'Pressfit',

  'pedals.flat': 'Platforminiai',
  'pedals.clipless': 'Įsegami',
  'pedals.hybrid': 'Hibridiniai',

  'brakes_activation.hydraulic': 'Hidraulinis',
  'brakes_activation.mechanical': 'Mechaninis',

  'handlebar_type.mtb': 'MTB',
  'handlebar_type.dropbar': 'Dropbar',
  'handlebar_type.bmx': 'BMX',
  'handlebar_type.city_trekking': 'City/Trekking',

  'grips_type.lock_on': 'Užspaudžiami',
  'grips_type.push_on': 'Užstumiami',
  'grips_type.bar_tape': 'Vairo juosta',

  'steerer_type.1_5': '1.5”',
  'steerer_type.1_8': '1-1/8”',
  'steerer_type.threaded': 'Threaded',
  'steerer_type.tapered': 'Tapered',

  'dropper_diameter.27_2': '27.2mm',
  'dropper_diameter.28_6': '28.6mm',
  'dropper_diameter.30_9': '30.9mm',
  'dropper_diameter.31_6': '31.6mm',
  'dropper_diameter.31_8': '31.8mm',
  'dropper_diameter.34_9': '34.9mm',

  'dropper_activation.mechanical': 'Mechaninis',
  'dropper_activation.electric': 'Elektrinis',
  'dropper_activation.hydraulic': 'Hidraulinis',

  'rotor_mounting.6_hole': '6-Hole',
  'rotor_mounting.centerlock': 'Centerlock',

  'rotor_placement.front': 'Priekinis',
  'rotor_placement.rear': 'Galinis',
  'rotor_placement.set': 'Komplektas',

  'wheels_placement.front': 'Priekinis',
  'wheels_placement.rear': 'Galinis',
  'wheels_placement.set': 'Komplektas',

  'hubs_placement.front': 'Priekinis',
  'hubs_placement.rear': 'Galinis',
  'hubs_placement.set': 'Komplektas',

  'condition.used_part': 'Naudotas',
  'condition.good_part': 'Geras',
  'condition.new_part': 'Naujas',

  'condition.used_part.label': '✔ Naudotas, su matomu nusidevėjimu ',
  'condition.good_part.label': '✔ Lengvai naudotas, tačiau geros būklės',
  'condition.new_part.label': '✔ Idealios būklės, ankščiau nenaudotas',

  'new_listing_page.page_title': 'Ką parduosi?',
  'new_listing_page.type.bike': 'Dviratį',
  'new_listing_page.description.bike': 'Pilną dviratį, bet kokios būklės.',
  'new_listing_page.type.part': 'Dviračio dalis',
  'new_listing_page.description.part': 'Rėmą, šakę, ratus arba kitas dalis.',

  'parts_listing_page.details.label': 'Informacija',
  'parts_listing_page.categories.label': 'Tipas',
  'parts_listing_page.general_details.label': 'Nuotraukos ir informacija',
  'parts_listing_page.specifications.label': 'Specifikacija',

  'parts_listing_page.categories.tab_title': 'Pasirink ką parduodi.',
  'parts_listing_page.details.tab_title': 'Pateik informaciją apie dalį.',
  'parts_listing_page.specification.tab_title': 'Pateik dalies specifikacijas.',

  'parts_listing_page.specification.panel_description':
    'Padidink pardavimo tikimybę pateikdamas papildomas detales.',

  'parts_listing_page.details.sub_category': 'Subkategorija',
  'parts_listing_page.details.sub_category_required': 'Pasirink subkategoriją',
  'parts_listing_page.details.sub_category_placeholder': 'Pasirink subkategoriją',
  'parts_listing_page.details.brand': 'Gamintojas',
  'parts_listing_page.details.brand_placeholder': 'Pasirink gamintoją',
  'parts_listing_page.details.brand_required': 'Turi pasirinkti gamintoją',
  'parts_listing_page.details.model': 'Modelis',
  'parts_listing_page.details.model_placeholder': 'Įvesk dalies modelį',
  'parts_listing_page.details.model_required': 'Turi įvesti dalies modelį',
  'parts_listing_page.details.year_placeholder': 'Pasirink metus',

  'parts_listing_page.specification.more_details': 'Daugiau detalių',
  'parts_listing_page.specifications.frame_size': 'Dydis',
  'parts_listing_page.specifications.frame_size_placeholder': 'Pasirink dydį',
  'parts_listing_page.specifications.frame_size_required': 'Pasirink rėmo dydį',
  'parts_listing_page.specifications.frame_material': 'Medžiaga',
  'parts_listing_page.specifications.frame_material_placeholder': 'Pasirink medžiagą',
  'parts_listing_page.specifications.frame_material_required': 'Pasirink medžiagą',
  'parts_listing_page.specifications.wheel_size': 'Ratų dydis',
  'parts_listing_page.specifications.wheel_size_placeholder': 'Pasirink ratų dydį',
  'parts_listing_page.specifications.wheel_size_required': 'Pasirink ratų dydį',
  'parts_listing_page.specifications.front_travel': 'Priekinė eiga',
  'parts_listing_page.specifications.front_travel_placeholder': 'Įrašyk priekinę eigą',
  'parts_listing_page.specifications.front_travel_required': 'Įrašyk galinę eigą',
  'parts_listing_page.specifications.rear_travel': 'Galinė eiga',
  'parts_listing_page.specifications.rear_travel_placeholder': 'Įrašyk galinę eigą',
  'parts_listing_page.specifications.rear_travel_required': 'Įrašyk galinę eigą',
  'parts_listing_page.specifications.discipline': 'Disciplina',
  'parts_listing_page.specifications.discipline_placeholder': 'Pasirink disciplina',
  'parts_listing_page.specifications.hub_standard': 'Stebulės standartas',
  'parts_listing_page.specifications.hub_standard_placeholder': 'Pasirink stebulės standartas',
  'parts_listing_page.specifications.suspension_type': 'Tipas',
  'parts_listing_page.specifications.suspension_mounting': 'Tvirtinimas',
  'parts_listing_page.specifications.suspension_length': 'Ilgis',
  'parts_listing_page.specifications.suspension_length_placeholder': 'Įvesk ilgį',
  'parts_listing_page.specifications.suspension_stroke': 'Amortizacijos eiga',
  'parts_listing_page.specifications.suspension_stroke_placeholder': 'Įvesk eigą',
  'parts_listing_page.specifications.axle_dimension': 'Ašies diametras',
  'parts_listing_page.specifications.axle_dimension_placeholder': 'Pasirink ašies diametrą',
  'parts_listing_page.specifications.crank_arm_length': 'Švaistiklio ilgis',
  'parts_listing_page.specifications.crank_arm_length_placeholder': 'Įvesk švaistiklio ilgį',
  'parts_listing_page.specifications.gears': 'Pavaros',
  'parts_listing_page.specifications.gears_placeholder': 'Pasirink pavaras',
  'parts_listing_page.specifications.placement': 'Tipas',
  'parts_listing_page.specifications.activation': 'Atkyvacija',
  'parts_listing_page.specifications.front_gears': 'Priekinės pavaros',
  'parts_listing_page.specifications.front_gears_placeholder': 'Pasirink priekines pavaras',
  'parts_listing_page.specifications.rear_gears': 'Galinės pavaros',
  'parts_listing_page.specifications.rear_gears_placeholder': 'Pasirink galines pavaras',
  'parts_listing_page.specifications.chainring_mounting': 'Tvirtinimas',
  'parts_listing_page.specifications.chainring_mounting_placeholder': 'Pasirink tvirtinimą',
  'parts_listing_page.specifications.chainring_teeth': 'Dantračiai',
  'parts_listing_page.specifications.chainring_teeth_placeholder': 'Įvesk dantračių kiekį',
  'parts_listing_page.specifications.bottom_bracket_mounting': 'Tvirtinimas',
  'parts_listing_page.specifications.bottom_bracket_mounting_placeholder': 'Pasirink tvirtinimą',
  'parts_listing_page.specifications.bottom_bracket_width': 'Plotis',
  'parts_listing_page.specifications.bottom_bracket_width_placeholder': 'Įvesk plotį',
  'parts_listing_page.specifications.pedals': 'Tipas',
  'parts_listing_page.specifications.pedals_placeholder': 'Pasirink tipą',
  'parts_listing_page.specifications.brake_type': 'Tipas',
  'parts_listing_page.specifications.brakes_activation': 'Aktyvacija',
  'parts_listing_page.specifications.rotor_mounting': 'Tvirtinimas',
  'parts_listing_page.specifications.rotor_placement': 'Tipas',
  'parts_listing_page.specifications.front_disk_diameter': 'Priekinio disko diametras',
  'parts_listing_page.specifications.front_disk_diameter_placeholder': 'Įvesk disko diametrą',
  'parts_listing_page.specifications.rear_disk_diameter': 'Galinio disko diametras',
  'parts_listing_page.specifications.rear_disk_diameter_placeholder': 'Įvesk disko diametrą',
  'parts_listing_page.specifications.wheels_placement': 'Tipas',
  'parts_listing_page.specifications.front_wheel_size': 'Priekinio rato dydis',
  'parts_listing_page.specifications.front_wheel_size_placeholder': 'Pasirink rato dydį',
  'parts_listing_page.specifications.rear_wheel_size': 'Galinio rato dydis',
  'parts_listing_page.specifications.rear_wheel_size_placeholder': 'Pasirink rato dydį',
  'parts_listing_page.specifications.hubs_placement': 'Tipas',
  'parts_listing_page.specifications.front_hub_standard': 'Priekinė stebulė',
  'parts_listing_page.specifications.front_hub_standard_placeholder': 'Pasirink stebulės matmenis',
  'parts_listing_page.specifications.rear_hub_standard': 'Galinė stebulė',
  'parts_listing_page.specifications.rear_hub_standard_placeholder': 'Pasirink stebulės matmenis',
  'parts_listing_page.specifications.inner_rim_width': 'Vidinis ratlankio plotis',
  'parts_listing_page.specifications.inner_rim_width_placeholder': 'Įvesk ratlankio plotį',
  'parts_listing_page.specifications.handlebar_type': 'Tipas',
  'parts_listing_page.specifications.handlebar_type_placeholder': 'Pasirink vairo tipą',
  'parts_listing_page.specifications.handlebar_width': 'Plotis',
  'parts_listing_page.specifications.handlebar_width_placeholder': 'Įvesk vairo plotį',
  'parts_listing_page.specifications.stem_length': 'Ilgis',
  'parts_listing_page.specifications.stem_length_placeholder': 'Įvesk vairo iškyšos ilgį',
  'parts_listing_page.specifications.grips_type': 'Tipas',
  'parts_listing_page.specifications.grips_type_placeholder': 'Pasirink tipą',
  'parts_listing_page.specifications.steerer_type': "'Steerer' tipas",
  'parts_listing_page.specifications.steerer_type_placeholder': 'Pasirink tipą',
  'parts_listing_page.specifications.dropper_travel': 'Eiga',
  'parts_listing_page.specifications.dropper_travel_placeholder': 'Įvesk eigą',
  'parts_listing_page.specifications.dropper_diameter': 'Diametras',
  'parts_listing_page.specifications.dropper_diameter_placeholder': 'Pasirink diametrą',
  'parts_listing_page.specifications.dropper_activation': 'Aktyvacija',
  'parts_listing_page.specifications.dropper_activation_placeholder': 'Pasirink aktyvaciją',
  'parts_listing_page.specifications.seat_length': 'Ilgis',
  'parts_listing_page.specifications.seat_length_placeholder': 'Įvesk iškyšos ilgį',
  'parts_listing_page.specifications.brake_type_placeholder': 'Pasirink tipą',

  'parts_listing_page.general_details.tab_title': 'Pridėk dalies nuotraukas',
  'parts_listing_page.general_details.description.label': 'Aprašas',
  'parts_listing_page.general_details.description.placeholder':
    'Pateik informaciją apie dalies trūkumus, įpakavimą, sąskaitas ar kitas svarbias detales.',
  'parts_listing_page.general_details.description.required': 'Pateik informaciją apie dalį',
  'parts_listing_page.location.label': 'Lokacija',
  'parts_listing_page.general_details.shipping.label': 'Siuntimas',
  'parts_listing_page.general_details.shipping.required': 'Pasirink siuntimą',

  'sub_categories.frame_full_suspension': 'Full suspension',
  'sub_categories.frame_hard_tail_mtb': 'Hardtail MTB',
  'sub_categories.frame_road': 'Plento',
  'sub_categories.frame_gravel': 'Gravel',
  'sub_categories.frame_full_suspension_e_bike': 'Full suspension E-Bike',
  'sub_categories.frame_hard_tail_e_bike': 'Hardtail E-Bike',
  'sub_categories.frame_city': 'Miesto',
  'sub_categories.frame_dirt_n_street': 'Dirt & Street',

  'sub_categories.suspension_rigid_fork': 'Rigid-šakė',
  'sub_categories.suspension_suspension_fork': 'Priekinio amortizatoriaus šakė',
  'sub_categories.suspension_rear': 'Galinis amortizatorius',

  'sub_categories.drivetrain_crank': 'Švaistiklis',
  'sub_categories.drivetrain_chain': 'Grandinė',
  'sub_categories.drivetrain_cassette': 'Kasetė',
  'sub_categories.drivetrain_shifter': 'Pavarų rankenėlė',
  'sub_categories.drivetrain_derailleur': 'Pavarų perjungėjas',
  'sub_categories.drivetrain_chainring': 'Dantračiai',
  'sub_categories.drivetrain_bottom_bracket': 'Centrinė ašis',
  'sub_categories.drivetrain_chain_guides': 'Chain guide',
  'sub_categories.drivetrain_pedals': 'Pedalai',

  'sub_categories.brakes_disk': 'Diskiniai stabdžiai',
  'sub_categories.brakes_rim': 'V-Brake tipo stabdžiai',
  'sub_categories.brakes_rotor': 'Stabdžių diskai',
  'sub_categories.brakes_other': 'Kita',

  'sub_categories.wheels_complete': 'Ratai',
  'sub_categories.wheels_hubs': 'Stebulės',
  'sub_categories.wheels_rims': 'Ratlankiai',

  'sub_categories.cockpit_handlebar': 'Vairas',
  'sub_categories.cockpit_stem': 'Vairo iškyša',
  'sub_categories.cockpit_grips': 'Vairo rankenėlės',
  'sub_categories.cockpit_headset': 'Vairo kolonėlė',

  'sub_categories.seat_dropper_post': 'Reguliuojama balnelio iškyša',
  'sub_categories.seat_post': 'Balnelio iškyša',
  'sub_categories.seat_saddle': 'Balnelis',
  'sub_categories.seat_other': 'Kita',

  'landing_page.view_bikes_and_parts': 'Rask dviratį ar dalis',
  'landing_page.buy_bike_button': 'Rask dviratį',
  'landing_page.buy_parts_button': 'Rask dalis',
  'landing_page.most_recent_part_label': 'Dviračių dalys',

  'validators.min_max_value': 'Įveskite reikšmę tarp {minValue} ir {maxValue}',
  'validators.min_max_value.invalid_type.numeric': 'Įveskite skaitinę reikšmę',

  'favorite_listings_page.search_button.bike': 'Ieškok dvirašių',
  'favorite_listings_page.search_button.parts': 'Ieškok dalių',
  'favorite_listings_page.empty_state.bike.title': 'Tavo pamėgti dviračiai',
  'favorite_listings_page.empty_state.bike.description': 'Kai pamėgsi dviratį, jis pasirodys čia',
  'favorite_listings_page.empty_state.parts.title': 'Tavo pamėgtos dalys',
  'favorite_listings_page.empty_state.parts.description': 'Kai pamėgsi dalį, ji pasirodys čia',

  'favorite_listings_page.tabs.bikes': 'Dviračiai',
  'favorite_listings_page.tabs.parts': 'Dalys',

  'new_listing_page.schema_title': 'Naujas skelbimas',

  'about_page.schema_title': 'Apie mus | {siteTitle}',
  'about_page.schema_description': 'Apie NextVelo',
  'listing_page.schema_title': '{title} | {siteTitle}',
  'profile_page.schema_title': '{name} | {siteTitle}',
  'search_page.schema_for_search': 'užklausa',
  'page.schema_title': 'Pirk ir parduok | {siteTitle}',
  'page.schema_description': 'Didžiausia internetinė dviratininkų bendruomenė.',
  'edit_listing_page.schema_title': 'Įkelk dviratį',
  'password_recovery_page.title': 'Naujas slaptažodis',
  'inbox_page.schema_title': 'Žinutės',

  'search_page.schema_title': 'Atraskite naudotus dviračius | NextVelo',
  'search_page.schema_description': 'Pirkite ir parduokite naudotus dviračius NextVelo.',
  'search_page.schema_title.bike': 'Atraskite savo kitą dviratį: Naudoti dviračiai | NextVelo',
  'search_page.schema_description.bike':
    'Atraskite platų kokybiškų naudotų dviračių asortimentą NextVelo. Raskite idealų plento, kalnų ar gravel dviratį su saugiais apmokėjimais ir pirkėjų apsauga. Pradėkite savo dviračių nuotykius jau šiandien.',
  'search_page.schema_title.bike.road': 'Aukštos kokybės plento dviračiai | NextVelo',
  'search_page.schema_description.bike.road':
    'Aukščiausios klasės plento dviračiai. Peržiūrėkite mūsų asortimentą ir ieškokite lengvų, aerodinamiškų dizaino dviračių NextVelo.',
  'search_page.schema_title.bike.mountain': 'Kalnų dviračiai | MTB | NextVelo',
  'search_page.schema_description.bike.mountain':
    'Įveikite sunkiausias trasas su kalnų dviračiais. Mūsų NextVelo asortimentas atitinka visus jūsų bekelės dviračių poreikius.',
  'search_page.schema_title.bike.gravel': 'Gravel dviračiai | NextVelo',
  'search_page.schema_description.bike.gravel':
    'Keliaukite ne tik asfaltuotais keliais su universaliais gravel dviračiais, sukurtais patogumui ir atsparumui įvairiose vietovėse. Su NextVelo atraskite daugiau.',
  'search_page.schema_title.bike.city': 'Parduodami miesto dviračiai | Miesto dviračiai | NextVelo',
  'search_page.schema_description.bike.city': 'Aukščiausios kokybės miesto dviračių NextVelo.',
  'search_page.schema_title.bike.electric': 'Elektriniai dviračiai | E-Bikes | NextVelo',
  'search_page.schema_description.bike.electric':
    'Paspartinkite savo kelionę su elektriniais dviračiais, siūlančiais lengvą ir greitą važiavimą. NextVelo atraskite tobulą el. dviratį, tinkantį jūsų gyvenimo būdui.',
  'search_page.schema_title.bike.bmx_dirt': 'BMX ir dirt dviračiai | NextVelo',
  'search_page.schema_description.bike.bmx_dirt': 'NextVelo rask BMX ir Dirt dviračius.',
  'search_page.schema_title.bike.folding': 'Sulankstomi dviračiai | Portable Bikes | NextVelo',
  'search_page.schema_description.bike.folding':
    'Maksimaliai patogūs mūsų sulankstomieji dviračiai. Idealiai tinka važiuoti į darbą ir laikyti ankštose vietose, raskite savo dviratį NextVelo.',
  'search_page.schema_title.bike.other':
    'Unikalūs ir specialūs dviračiai | Raskite savo tobulą dviratį | NextVelo',
  'search_page.schema_description.bike.other':
    'Ieškote kažko kitokio? Raskite unikalių ir specializuotų dviračių visiems pageidavimams NextVelo.',

  'search_page.schema_title.parts': 'Dviračių dalys ir komponentai | NextVelo',
  'search_page.schema_description.parts':
    'Apsipirkite aukštos kokybės dviračių dalių ir komponentų. NextVelo rasite rėmų, pakabos, pavaros, perjungėjų, stabdžių ir dar daugiau.',
  'search_page.schema_title.parts.frame': 'Parduodami dviračių rėmai | NextVelo',
  'search_page.schema_description.parts.frame':
    'Atraskite patvarius ir lengvus dviračių rėmus. Išsirinkite rėmą savo poreikiams iš NextVelo.',
  'search_page.schema_title.parts.suspension': 'Dviračių amortizatoriai | NextVelo',
  'search_page.schema_description.parts.suspension':
    'Pagerinkite važiavimą naudodami geriausius amortozatorius. NextVelo raskite šakių ir amortizatorių.',
  'search_page.schema_title.parts.drivetrain': 'Dviračių pavaros | NextVelo',
  'search_page.schema_description.parts.drivetrain':
    'Atnaujinkite savo dviratį patikimomis pavaromis. NextVelo įsigykite grandinių, bėgių perjungiklių.',
  'search_page.schema_title.parts.brakes': 'Dviračių stabdžiai | NextVelo',
  'search_page.schema_description.parts.brakes':
    'Užtikrinkite savo saugumą naudodami aukštos kokybės dviračio stabdžius. Nuo diskinių iki ratlankių stabdžių - viską, ko jums reikia, rasite NextVelo.',
  'search_page.schema_title.parts.wheels': 'Dviračių ratai ir ratlankiai | NextVelo',
  'search_page.schema_description.parts.wheels':
    'Sklandžiai riedėkite su mūsų siūlomais dviračių ratais ir ratlankiais. Atraskite patvarius variantus visoms kategorijoms NextVelo.',
  'search_page.schema_title.parts.tyres_tubes': 'Dviračių padangos bet kokiam reljefui | NextVelo',
  'search_page.schema_description.parts.tyres_tubes':
    'NextVelo raskite dviračių padangas bet kokiam reljefui. Rinkitės iš plento, kalnų ir gravel padangų.',
  'search_page.schema_title.parts.cockpit': 'Dviračio vairas ir vairo komponentai | NextVelo',
  'search_page.schema_description.parts.cockpit':
    'Įsigykite kokybiškų vairų, vairo iškišų, vairo juostų ir kitu dalių NextVelo.',
  'search_page.schema_title.parts.seat': 'Dviračių sėdynės, balneliai, ir kitos dalys | NextVelo',
  'search_page.schema_description.parts.seat':
    'Patirkite komfortą su mūsų dviračių sėdynių ir balnelių asortimentu NextVelo.',

  'PrivacyPolicyPage.schemaTitle': 'Privatumo politika | {siteTitle}',
};
