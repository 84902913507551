export const plTranslations = {
  'landing_page.sell_button': 'Sprzedaj',
  'landing_page.view_bikes_and_parts': 'Znajdź rower lub części',
  'landing_page.buy_bike_button': 'Zobacz wszystkie rowery',
  'landing_page.buy_parts_button': 'Zobacz wszystkie części',
  'landing_page.schema_description':
    'Kupuj i sprzedawaj używane rowery szosowe, gravelowe i górskie na NextVelo, największej platformie internetowej dla używanych rowerów. Z ochroną kupującego, szybką wysyłką i bezpiecznymi płatnościami.',
  'Landing_page.schema_title': ' Odkryj swój używany rower szosowy, gravelowy lub MTB | NextVelo',
  'landing_page.categories_label': 'Kategorie',
  'landing_page.most_recent_label': 'Najnowsze rowery',
  'landing_page.most_popular_label': 'Popularne marki',
  'landing_page.most_recent_part_label': 'Części rowerowe',
  'landing_page.section_hero.title': 'Zamień swój',
  'landing_page.section_hero.sub_title': 'Rower na gotówkę',
  'landing_page.section_hero.description_1': 'Stwórz ogłoszenie za darmo w mniej niż 3 minuty.',
  'landing_page.section_hero.description_2': 'Dotrzyj do tysięcy kupujących.',

  'locales.english': 'EN',
  'locales.lithuanian': 'LT',
  'locales.polish': 'PL',

  'months.january': 'Styczeń',
  'months.february': 'Luty',
  'months.march': 'Marzec',
  'months.april': 'Kwiecień',
  'months.may': 'Maj',
  'months.june': 'Czerwiec',
  'months.july': 'Lipiec',
  'months.august': 'Sierpień',
  'months.september': 'Wrzesień',
  'months.october': 'Październik',
  'months.november': 'Listopad',
  'months.december': 'Grudzień',

  'landing_page.section_info.label': 'Jak działa NextVelo?',

  'landing_page.section_info.sell': 'Sprzedaj',
  'landing_page.section_info.sell.question_0': 'Opowiedz o sprzedawanym rowerze',
  'landing_page.section_info.sell.answer_0':
    'Podaj specyfikacje roweru, takie jak rozmiar, materiał, kolor i inne szczegóły.',
  'landing_page.section_info.sell.question_1': 'Odpowiadaj na zapytania',
  'landing_page.section_info.sell.answer_1':
    'Skontaktuj się z potencjalnymi klientami za pomocą systemu wiadomości, odpowiadaj na ich pytania lub umów się na jazdę próbną.',
  'landing_page.section_info.sell.question_2': 'Potwierdź płatność',
  'landing_page.section_info.sell.answer_2':
    'Spotkaj się z kupującym, aby odebrać płatność lub zaakceptować płatność online.',

  'landing_page.section_info.buy': 'Kup',
  'landing_page.section_info.buy.question_0': 'Wybierz rower, który Ci się podoba',
  'landing_page.section_info.buy.answer_0':
    'Szukaj rowerów według rozmiaru, typu roweru lub innych cech roweru.',
  'landing_page.section_info.buy.question_1': 'Dowiedz się więcej o rowerze',
  'landing_page.section_info.buy.answer_1':
    'Umów się na jazdę próbną lub, jeśli chcesz, odbierz go od razu.',
  'landing_page.section_info.buy.question_2': 'Potwierdź płatność',
  'landing_page.section_info.buy.answer_2':
    'Spotkaj się z sprzedawcą, aby zapłacić gotówką lub zorganizować płatność online.',

  'config.labels.condition': 'Stan',
  'config.labels.shipping': 'Wysyłka',
  'config.labels.receiptAvailable': 'Paragon',
  'config.labels.frameColor': 'Kolor ramy',
  'config.labels.frameMaterial': 'Materiał ramy',
  'config.labels.wheelSize': 'Rozmiar kół',
  'config.labels.frameSize': 'Rozmiar ramy',
  'config.labels.year': 'Rok',
  'config.labels.brand': 'Marka',
  'config.labels.category': 'Typ',
  'config.labels.price': 'Cena',
  'config.labels.keyword': 'Słowo kluczowe',
  'config.labels.model': 'Model',
  'config.labels.country': 'Kraj',
  'config.labels.subCategory': 'Kategoria',
  'config.labels.discipline': 'Dyscyplina',
  'config.labels.frontTravel': 'Skok przedniego zawieszenia',
  'config.labels.rearTravel': 'Skok tylnego zawieszenia',
  'config.labels.dropperTravel': 'Skok droppera',
  'config.labels.dropperDiameter': 'Średnica droppera',
  'config.labels.dropperActivation': 'Aktywacja droppera',
  'config.labels.catalogTree': 'Kategoria',
  'config.labels.hubStandard': 'Standard piast',
  'config.labels.frontHubStandard': 'Standard przedniej piasty',
  'config.labels.rearHubStandard': 'Standard tylnej piasty',
  'config.labels.suspensionType': 'Typ zawieszenia',
  'config.labels.suspensionMounting': 'Montaż zawieszenia',
  'config.labels.suspensionLength': 'Długość zawieszenia',
  'config.labels.suspensionStroke': 'Skok zawieszenia',
  'config.labels.axleDimension': 'Wymiar osi',
  'config.labels.gears': 'Przerzutki',
  'config.labels.frontGears': 'Przerzutki przednie',
  'config.labels.rearGears': 'Przerzutki tylne',
  'config.labels.placement': 'Typ',
  'config.labels.activation': 'Aktywacja',
  'config.labels.chainringMounting': 'Montaż łańcucha',
  'config.labels.bottomBracketMounting': 'Montaż suportu',
  'config.labels.pedals': 'Typ',
  'config.labels.frontWheelSize': 'Rozmiar przedniego koła',
  'config.labels.rearWheelSize': 'Rozmiar tylnego koła',
  'config.labels.wheelsPlacement': 'Typ',
  'config.labels.brakeType': 'Typ hamulców',
  'config.labels.brakesActivation': 'Aktywacja hamulców',
  'config.labels.hubsPlacement': 'Typ',
  'config.labels.handlebarType': 'Typ kierownicy',
  'config.labels.gripsType': 'Typ chwytaków',
  'config.labels.steererType': 'Typ rurki sterowej',
  'config.labels.crankArmLength': 'Długość ramion korby',
  'config.labels.chainringTeeth': 'Zęby',
  'config.labels.handlebarWidth': 'Szerokość kierownicy',

  'categories.all': 'Rowery i części',
  'categories.bike': 'Rowery',
  'categories.parts': 'Części',

  'categories.city': 'Miejskie',
  'categories.road': 'Drogowe',
  'categories.mountain': 'Górskie',
  'categories.gravel': 'Gravel',
  'categories.bmx_dirt': 'Bmx & Dirt',
  'categories.folding': 'Składane',
  'categories.electric': 'Elektryczne',
  'categories.other': 'Inne',

  'categories.frame': 'Rama',
  'categories.suspension': 'Zawieszenie',
  'categories.drivetrain': 'Napęd',
  'categories.brakes': 'Hamulce',
  'categories.wheels': 'Koła',
  'categories.tyres_tubes': 'Opony',
  'sub_categories.tyres_tubes': 'Opony',
  'categories.cockpit': 'Kokpit',
  'categories.seat': 'Siodełko',

  'sub_categories.frame_full_suspension': 'Pełne zawieszenie',
  'sub_categories.frame_hard_tail_mtb': 'Hardtail MTB',
  'sub_categories.frame_road': 'Drogowe',
  'sub_categories.frame_gravel': 'Gravel',
  'sub_categories.frame_full_suspension_e_bike': 'Pełne zawieszenie E-Bike',
  'sub_categories.frame_hard_tail_e_bike': 'Hardtail E-Bike',
  'sub_categories.frame_city': 'Miejskie',
  'sub_categories.frame_dirt_n_street': 'Dirt & Street',

  'sub_categories.suspension_rigid_fork': 'Sztywna widelca',
  'sub_categories.suspension_suspension_fork': 'Widelca z zawieszeniem',
  'sub_categories.suspension_rear': 'Zawieszenie tył',

  'sub_categories.drivetrain_crank': 'Korba',
  'sub_categories.drivetrain_chain': 'Łańcuch',
  'sub_categories.drivetrain_cassette': 'Kaseta',
  'sub_categories.drivetrain_shifter': 'Manetka',
  'sub_categories.drivetrain_derailleur': 'Przerzutka',
  'sub_categories.drivetrain_chainring': 'Koło łańcuchowe',
  'sub_categories.drivetrain_bottom_bracket': 'Łożysko suportu',
  'sub_categories.drivetrain_chain_guides': 'Prowadnica łańcucha',
  'sub_categories.drivetrain_pedals': 'Pedały',

  'sub_categories.brakes_disk': 'Hamulce tarczowe',
  'sub_categories.brakes_rim': 'Hamulce felgowe',
  'sub_categories.brakes_rotor': 'Tarcza hamulcowa',
  'sub_categories.brakes_other': 'Inne',

  'sub_categories.wheels_complete': 'Kompletne koła',
  'sub_categories.wheels_hubs': 'Piasty',
  'sub_categories.wheels_rims': 'Obręcze',

  'sub_categories.cockpit_handlebar': 'Kierownica',
  'sub_categories.cockpit_stem': 'Mostek',
  'sub_categories.cockpit_grips': 'Chwytaki',
  'sub_categories.cockpit_headset': 'Łożysko steru',

  'sub_categories.seat_dropper_post': 'Dropper post',
  'sub_categories.seat_post': 'Sztyca',
  'sub_categories.seat_saddle': 'Siodełko',
  'sub_categories.seat_other': 'Inne',

  'year.placeholder': 'Wybierz rok',
  'frame_size.placeholder': 'Wybierz rozmiar ramy',
  'brand.placeholder': 'Wybierz markę',
  'country.placeholder': 'Wybierz kraj',
  'front_travel.placeholder': 'Wybierz skok przedniego zawieszenia',
  'rear_travel.placeholder': 'Wybierz skok tylnego zawieszenia',

  'country.LT': 'Litwa',
  'country.LV': 'Łotwa',
  'country.EE': 'Estonia',
  'country.PL': 'Polska',
  'country.all': 'Wszystkie',

  'sub_categories.road': 'Drogowe',
  'sub_categories.track': 'Torowe',
  'sub_categories.aearo_triathlon_tt': 'Aero/Triathlon/TT',
  'sub_categories.single_gear': 'Jednoślad',
  'sub_categories.xc_cross_country': 'XC / Cross Country',
  'sub_categories.trail': 'Trasy górskie',
  'sub_categories.enduro': 'Enduro',
  'sub_categories.downhill': 'Downhill',
  'sub_categories.bmx': 'Bmx',
  'sub_categories.dirt_street': 'Rower Dirt/Street',
  'sub_categories.electric_city': 'Elektryczne miejskie',
  'sub_categories.electric_mountain': 'Elektryczne górskie',
  'sub_categories.electric_road': 'Elektryczne drogowe',
  'sub_categories.electric_other': 'Inne',
  'sub_categories.cargo': 'Transportowe',
  'sub_categories.tricycle': 'Trójkołowe',
  'sub_categories.tandem': 'Tandem',
  'sub_categories.balance': 'Balans',
  'sub_categories.unicycle': 'Monocykl',

  'shipping.no_shipping': 'Odbiór osobisty',
  'shipping.domestic_shipping': 'Wysyłka krajowa',
  'shipping.international_shipping': 'Wysyłka międzynarodowa',

  'frame_material.aluminum': 'Aluminiowy',
  'frame_material.carbon': 'Węglowy',
  'frame_material.steel': 'Stalowy',
  'frame_material.other': 'Inny',

  'frame_color.pink': 'Różowy',
  'frame_color.green': 'Zielony',
  'frame_color.orange': 'Pomarańczowy',
  'frame_color.blue': 'Niebieski',
  'frame_color.red': 'Czerwony',
  'frame_color.white': 'Biały',
  'frame_color.yellow': 'Żółty',
  'frame_color.black': 'Czarny',
  'frame_color.grey': 'Szary',
  'frame_color.other': 'Inny',

  'wheel_size.16': '16″',
  'wheel_size.20': '20″',
  'wheel_size.28': '28″ (700c)',
  'wheel_size.26': '26”',
  'wheel_size.27.5': '27.5” (650b)',
  'wheel_size.29': '29”',
  'wheel_size.650B': '650B',
  'wheel_size.700C': '28” (700c)',
  'wheel_size.other': 'Inny',

  'suspension_type.air': 'Zawieszenie pneumatyczne',
  'suspension_type.coil': 'Zawieszenie sprężynowe',

  'suspension_mounting.trunnion': 'Trunion',
  'suspension_mounting.standard': 'Standardowe',

  'discipline.xc_marathon': 'XC / Maraton',
  'discipline.trail_all_mountain': 'Trasa / Góry',
  'discipline.enduro': 'Enduro',
  'discipline.downhill': 'Downhill',

  'hub_standard.15_110': '15 x 110mm (Boost)',
  'hub_standard.20_110': '20 x 110mm (Boost)',
  'hub_standard.9_100': '9 x 100mm',
  'hub_standard.15_100': '15 x 100mm',
  'hub_standard.20_100': '20 x 100mm',
  'hub_standard.9_135': '9 x 135mm',
  'hub_standard.10_135': '10 x 135mm',
  'hub_standard.15_142': '15 x 142mm',
  'hub_standard.15_150': '15 x 150mm',
  'hub_standard.10_170': '10 x 170mm',
  'hub_standard.12_190': '12 x 190mm',
  'hub_standard.12_197': '12 x 197mm',

  'axle_dimension.30': '30mm',
  'axle_dimension.29_9': '29.9mm',
  'axle_dimension.22_24': '22/24mm (DUB)',
  'axle_dimension.25': '25mm',
  'axle_dimension.24': '24mm',
  'axle_dimension.19': '19mm',
  'axle_dimension.square_taper': 'Square taper',
  'axle_dimension.octalink': 'Octalink',
  'axle_dimension.m30': 'M30',
  'axle_dimension.isis': 'ISIS',
  'axle_dimension.american': 'Amerykański',
  'axle_dimension.mid': 'Mid',

  'gears.1': '1',
  'gears.2': '2',
  'gears.3': '3',
  'gears.4': '4',
  'gears.5': '5',
  'gears.6': '6',
  'gears.7': '7',
  'gears.8': '8',
  'gears.9': '9',
  'gears.10': '10',
  'gears.11': '11',
  'gears.12': '12',
  'gears.13': '13',

  'placement.front': 'Przednie',
  'placement.rear': 'Tylne',
  'placement.set': 'Zestaw',

  'activation.mechanical': 'Mechaniczny',
  'activation.electric': 'Elektryczny',

  'chainring_mounting.3_arm': '3-ramienny',
  'chainring_mounting.4_arm': '4-ramienny',
  'chainring_mounting.5_arm': '5-ramienny',

  'bottom_bracket_mounting.bsa': 'BSA',
  'bottom_bracket_mounting.ita': 'ITA',
  'bottom_bracket_mounting.pressfit': 'Pressfit',

  'pedals.flat': 'Flat',
  'pedals.clipless': 'Bezstopniowe',
  'pedals.hybrid': 'Hybrydowe',

  'brakes_activation.hydraulic': 'Hydrauliczne',
  'brakes_activation.mechanical': 'Mechaniczne',

  'handlebar_type.mtb': 'MTB',
  'handlebar_type.dropbar': 'Kierownica sportowa',
  'handlebar_type.bmx': 'BMX',
  'handlebar_type.city_trekking': 'Miejska/Trekkingowa',

  'grips_type.lock_on': 'Z blokadą',
  'grips_type.push_on': 'Naciskane',
  'grips_type.bar_tape': 'Taśma kierownicy',

  'steerer_type.1_5': '1.5”',
  'steerer_type.1_8': '1-1/8”',
  'steerer_type.threaded': 'Nawrotny',
  'steerer_type.tapered': 'Rozsuwany',

  'dropper_diameter.27_2': '27.2mm',
  'dropper_diameter.28_6': '28.6mm',
  'dropper_diameter.30_9': '30.9mm',
  'dropper_diameter.31_6': '31.6mm',
  'dropper_diameter.31_8': '31.8mm',
  'dropper_diameter.34_9': '34.9mm',

  'dropper_activation.mechanical': 'Mechaniczny',
  'dropper_activation.electric': 'Elektryczny',
  'dropper_activation.hydraulic': 'Hydrauliczny',

  'rotor_mounting.6_hole': '6-otworowe',
  'rotor_mounting.centerlock': 'Centerlock',

  'rotor_placement.front': 'Przedni',
  'rotor_placement.rear': 'Tylny',
  'rotor_placement.set': 'Komplet',

  'wheels_placement.front': 'Przednie',
  'wheels_placement.rear': 'Tylne',
  'wheels_placement.set': 'Komplet',

  'hubs_placement.front': 'Przednie',
  'hubs_placement.rear': 'Tylny',
  'hubs_placement.set': 'Komplet',

  'receipt.yes': 'Tak',
  'receipt.no': 'Nie',

  'condition.bad': 'Częściowy rower',
  'condition.partly_bad': 'Wymaga konserwacji',
  'condition.good': 'Dobry',
  'condition.perfect': 'Doskonały',
  'condition.brand_new': 'Nowy',

  'condition.bad.label': `✔ Nie nadaje się do jazdy\\n✔ Wymaga przeglądu i serwisu\\n✔ Konieczna wymiana części`,
  'condition.partly_bad.label': `✔ Niektóre zadrapania na powierzchni\\n✔ Wymaga dostosowania serwisowego\\n✔ Niektóre części mogą wymagać wymiany`,
  'condition.good.label': `✔ Niektóre zadrapania na powierzchni\\n✔ Może wymagać dostosowania serwisowego\\n✔ Nie wymaga wymiany części`,
  'condition.perfect.label': `✔ Kilka lekkich zadrapań\\n✔ Mechanicznie doskonały\\n✔ Brak znaczących uszkodzeń komponentów`,
  'condition.brand_new.label': `✔ Rower nigdy nie był używany`,

  'condition.used_part': 'Używane',
  'condition.good_part': 'Dobre',
  'condition.new_part': 'Nowe',

  'condition.used_part.label': '✔ Wcześniej używane, z zauważalnymi wadami.',
  'condition.good_part.label': '✔ Mało używane, w świetnym stanie.',
  'condition.new_part.label': '✔ Stan idealny, nigdy nie używane.',

  'frame_size.xxl': 'XXL',
  'frame_size.xl': 'XL',
  'frame_size.l': 'L',
  'frame_size.m': 'M',
  'frame_size.s': 'S',
  'frame_size.xs': 'XS',
  'frame_size.xxs': 'XSS',

  'front_travel.60': '60',
  'front_travel.70': '70',
  'front_travel.80': '80',
  'front_travel.90': '90',
  'front_travel.100': '100',
  'front_travel.110': '110',
  'front_travel.120': '120',
  'front_travel.130': '130',
  'front_travel.140': '140',
  'front_travel.150': '150',
  'front_travel.160': '160',
  'front_travel.170': '170',
  'front_travel.180': '180',
  'front_travel.190': '190',
  'front_travel.200': '200',
  'front_travel.210': '210',
  'front_travel.220': '220',
  'front_travel.230': '230',
  'rear_travel.60': '60',
  'rear_travel.70': '70',
  'rear_travel.80': '80',
  'rear_travel.90': '90',
  'rear_travel.100': '100',
  'rear_travel.110': '110',
  'rear_travel.120': '120',
  'rear_travel.130': '130',
  'rear_travel.140': '140',
  'rear_travel.150': '150',
  'rear_travel.160': '160',
  'rear_travel.170': '170',
  'rear_travel.180': '180',
  'rear_travel.190': '190',
  'rear_travel.200': '200',
  'rear_travel.210': '210',
  'rear_travel.220': '220',
  'rear_travel.230': '230',

  'top_bar.search_form.placeholder': 'Szukaj',
  'top_bar.generic_error_message': 'Coś poszło nie tak. Proszę spróbuj ponownie.',
  'top_bar.logo_icon_label': 'Przejdź na stronę główną',
  'top_bar.menu_icon_label': 'Otwórz menu',

  'top_bar.log_in': 'Zaloguj się',
  'top_bar.log_out': 'Wyloguj się',
  'top_bar.sell': 'Sprzedaj',
  'top_bar.buy': 'Kup',
  'top_bar.inbox_label': 'Skrzynka odbiorcza',
  'top_bar.listings_label': 'Twoje oferty',
  'top_bar.profile_settings_label': 'Ustawienia profilu',
  'top_bar.account_settings_label': 'Ustawienia konta',
  'top_bar.profile': 'Profil',

  'top_bar.mobile_menu.buy': 'Kup',
  'top_bar.mobile_menu.about_page': 'O nas',
  'top_bar.mobile_menu.how_it_works_page': 'Jak to działa',
  'top_bar.mobile_menu.show_profile': 'Pokaż profil',
  'top_bar.mobile_menu.account_label': 'Konto',
  'top_bar.mobile_menu.services_label': 'Usługi',
  'top_bar.mobile_menu.favorite_listings': 'Ulubione oferty',

  'top_bar.desktop.logo_label': 'NextVelo',

  'authentication_page.or': 'lub',
  'authentication_page.facebook_login': 'Kontynuuj z Facebookiem',
  'authentication_page.google_login': 'Kontynuuj z Google',
  'authentication_page.email_login': 'Kontynuuj z adresem e-mail',
  'authentication_page.terms_and_conditions_accept_label':
    'Wybierając Zgadzam się i kontynuuj, akceptuję {termsLink} NextVelo oraz {privacyPolicyLink}',
  'authentication_page.terms_and_conditions_label': 'Warunki korzystania z usługi',
  'authentication_page.privacy_policy_label': 'Polityka prywatności',

  'log_in.email_label': 'Adres e-mail',
  'log_in.email_placeholder': 'Adres e-mail',
  'log_in.email_required': 'Proszę podać adres e-mail',
  'log_in.email_invalid': 'Podany adres e-mail jest nieprawidłowy',
  'log_in.password_label': 'Hasło',
  'log_in.password_placeholder': 'Hasło',
  'log_in.password_required': 'Proszę podać hasło',
  'log_in.forgot_password': 'Zapomniałeś hasła?',
  'log_in.log_in_label': 'Zaloguj się',
  'log_in.sign_up_prompt': 'Nie masz konta? ',
  'log_in.sign_up_label': 'Zarejestruj się',
  'log_in.failed':
    'Podany e-mail i hasło nie pasują do naszych danych. Proszę sprawdź i spróbuj ponownie.',

  'sign_up.email_label': 'Adres e-mail',
  'sign_up.email_placeholder': 'Adres e-mail',
  'sign_up.email_required': 'Proszę podać adres e-mail',
  'sign_up.email_invalid': 'Podany adres e-mail jest nieprawidłowy',
  'sign_up.password_label': 'Hasło',
  'sign_up.password_placeholder': 'Hasło',
  'sign_up.password_required': 'Proszę podać hasło',
  'sign_up.password_too_short': 'Hasło powinno mieć co najmniej {minLength} znaków',
  'sign_up.password_too_long': 'Hasło powinno mieć co najwyżej {maxLength} znaków',
  'sign_up.first_name_label': 'Imię',
  'sign_up.first_name_placeholder': 'Imię',
  'sign_up.first_name_required': 'Proszę podać imię',
  'sign_up.last_name_label': 'Nazwisko',
  'sign_up.last_name_placeholder': 'Nazwisko',
  'sign_up.last_name_required': 'Proszę podać nazwisko',
  'sign_up.sign_up_label': 'Zarejestruj się',
  'sign_up.log_in_prompt': 'Masz już konto? ',
  'sign_up.log_in_label': 'Zaloguj się',
  'sign_up.failed':
    'Rejestracja nie powiodła się. Upewnij się, że wszystkie wprowadzone informacje są poprawne i spróbuj ponownie.',
  'sign_up.email_already_taken': 'Adres e-mail jest już zajęty',

  'confirm_sign_up.email_label': 'Email',
  'confirm_sign_up.email_placeholder': 'Email',
  'confirm_sign_up.email_required': 'Proszę podać adres e-mail',
  'confirm_sign_up.email_invalid': 'Podany adres e-mail jest nieprawidłowy',
  'confirm_sign_up.first_name_label': 'Imię',
  'confirm_sign_up.first_name_placeholder': 'Imię',
  'confirm_sign_up.first_name_required': 'Proszę podać imię',
  'confirm_sign_up.last_name_label': 'Nazwisko',
  'confirm_sign_up.last_name_placeholder': 'Nazwisko',
  'confirm_sign_up.last_name_required': 'Proszę podać nazwisko',
  'confirm_sign_up.confirm_and_continue_label': 'Zgadzam się i kontynuuj',

  'avatar.banned_user_label': 'Zbanowany użytkownik',
  'avatar.deleted_user_label': 'Usunięty użytkownik',

  'footer.copyright': '© NextVelo',
  'footer.buy_bike': 'Znajdź rower lub części',
  'footer.sell_bike': 'Sprzedaj rower lub części',
  'footer.about': 'O NextVelo',
  'footer.terms_conditions': 'Warunki korzystania',
  'footer.privacy_policy': 'Polityka prywatności',
  'footer.instagram_link': 'Przejdź do strony Instagram',
  'footer.facebook_link': 'Przejdź do strony Facebook',
  'footer.linkedin_link': 'Przejdź do strony LinkedIn',
  'footer.organization_description': 'Największa społeczność online dla miłośników rowerów.',
  'footer.content_rights': '© NextVelo. Wszelkie prawa zastrzeżone.',

  'modal.close': 'Zamknij modal',

  'filter_form.cancel': 'Anuluj',
  'filter_form.clear': 'Wyczyść',
  'filter_form.submit': 'Zastosuj',

  'actions.cancel': 'Anuluj',
  'actions.clear': 'Wyczyść',
  'actions.submit': 'Zastosuj',
  'actions.publish': 'Opublikuj',
  'actions.continue': 'Kontynuuj',
  'actions.back': 'Wstecz',

  'search_page.price_filter.cancel': 'Anuluj',
  'search_page.price_filter.clear': 'Wyczyść',
  'search_page.price_filter.plain_label': 'Cena',
  'search_page.price_filter.label': 'Zakres cenowy:',
  'search_page.price_filter.submit': 'Zastosuj',
  'search_page.price_filter.to': 'do',
  'search_page.header.foundResults': '{count, number} {count, plural, one {wynik} other {wyników}}',
  'search_page.header.loadingResults': 'Ładowanie wyników wyszukiwania',
  'search_page.sortBy': 'Sortuj według',
  'search_page.filter_plain.selected': '• {count}',
  'search_page.multiple_filter.selected': '{labelText} • {count}',
  'search_page.reset_filters': 'Zresetuj filtry',
  'search_page.no_results': 'Nie znaleziono ofert odpowiadających Twoim kryteriom wyszukiwania.',
  'search_page.empty_state.message':
    'Niestety nie udało się znaleźć żadnych ofert pasujących do Twoich kryteriów wyszukiwania',
  'search_page.price_filter.selected': '{minPrice} - {maxPrice}',
  'search_page.mobile_filters.label': 'Filtry',
  'search_page.mobile_filters.reset': 'Resetuj',
  'search_page.range_filter.label.selected': '{minValue} - {maxValue}',

  'search_page.mobile.show_listings': 'Pokaż {count}',

  'profile_page.label': 'Profil',
  'profile_page.listing.view': 'Zobacz',
  'profile_page.edit_profile': 'Edytuj profil',
  'profile_page.joined': 'Dołączono {createdAt}',
  'profile_page.bikes.seller': 'Twoje oferty',
  'profile_page.bikes.buyer': 'Oferty sprzedaży',
  'profile_page.data_load_failed': 'Ups, coś poszło nie tak. Spróbuj ponownie.',
  'profile_page.bio_label': 'O mnie',
  'profile_page.edit_listing': 'Edytuj',
  'profile_page.delete_listing': 'Usuń',
  'profile_page.delete_modal.title': 'Usuń ofertę',
  'profile_page.delete_modal.subtitle': 'Jak sprzedałeś swoją rzecz?',
  'profile_page.delete_modal.reason.nextvelo': 'Znalazłem kupca na NextVelo',
  'profile_page.delete_modal.reason.outside': 'Znalazłem kupca poza platformą',
  'profile_page.delete_modal.reason.other': 'Inne',
  'profile_page.delete_modal.delete_action': 'Usuń',
  'profile_page.delete_modal.cancel_action': 'Anuluj',
  'profile_page.no_listings.owner_label': 'Zmień swój stary rower w gotówkę',

  'listing_page.sold_by': '{name}',
  'listing_page.loading': 'Ładowanie oferty',
  'listing_page.order_panel': 'Sprzedane przez {name}',
  'listing_page.contact_seller': 'Skontaktuj się z sprzedawcą',
  'listing_page.add_to_favorites': 'Dodaj do ulubionych',
  'listing_page.remove_from_favorites': 'Usuń z ulubionych',
  'listing_page.description.about_bike_label': 'O rowerze',
  'listing_page.details.label': 'Szczegóły',
  'listing_page.image_gallery.view_photos': 'Zobacz zdjęcia ({count})',
  'listing_page.image_gallery.image_alt_text': 'Zdjęcie oferty {index}/{count}',
  'listing_page.image_gallery.image_thumb_alt_text': 'Zdjęcie oferty {index}/{count}',
  'listing_page.basic_information.label': 'Podstawowe informacje',
  'listing_page.location.label': 'Lokalizacja',
  'listing_page.seller_details.label': 'Informacje o sprzedającym',
  'listing_page.offered_by.label': 'Oferowane przez',
  'listing_page.offered_by.phone_number': 'Numer telefonu',

  'new_listing_page.page_title': 'Co będziesz sprzedawać?',
  'new_listing_page.type.bike': 'Rower',
  'new_listing_page.description.bike': 'Pełny rower, w dowolnym stanie.',
  'new_listing_page.type.part': 'Komponenty rowerowe',
  'new_listing_page.description.part': 'Rama, widelec, koła lub inne części.',

  'edit_listing_page.location.label': 'Lokalizacja',
  'edit_listing_page.details.label': 'Szczegóły',
  'edit_listing_page.categories.label': 'Typ',
  'edit_listing_page.general_details.label': 'Zdjęcia i szczegóły',
  'edit_listing_page.show_listing_failed': 'Pobieranie danych oferty nie powiodło się',
  'edit_listing_page.update_failed': 'Aktualizacja oferty nie powiodła się. Spróbuj ponownie.',
  'edit_listing_page.upload_failed': 'Publikowanie oferty nie powiodło się',
  'edit_listing_page.max_length': 'Musi mieć maksymalnie {maxLength} znaków',

  'edit_listing_page.location.tab_title': 'Sprzedaj swój nowy lub używany rower',
  'edit_listing_page.location.address': 'Gdzie znajduje się Twój rower?',
  'edit_listing_page.location.address_hint': 'To nie będzie widoczne dla innych członków.',
  'edit_listing_page.location.address_not_recognized':
    'Nie rozpoznaliśmy tej lokalizacji. Proszę wybrać inną lokalizację.',
  'edit_listing_page.location.address_placeholder': 'Wprowadź adres',
  'edit_listing_page.location.address_required': 'Musisz podać lokalizację',
  'edit_listing_page.location.shipping_label': 'Czy wysyłasz rower?',
  'edit_listing_page.location.shipping_required': 'Proszę wybrać metodę wysyłki',

  'edit_listing_page.categories.tab_title': 'Który opisuje Twój rower najlepiej?',
  'edit_listing_page.categories.type_select': 'Wybierz typ swojego roweru',
  'edit_listing_page.categories.sub_category_required': 'Proszę wybrać podkategorie roweru',
  'edit_listing_page.categories.category_required': 'Proszę wybrać kategorię roweru',

  'edit_listing_page.details.tab_title': 'Ogólne informacje o Twoim rowerze',
  'edit_listing_page.details.frame_details': 'Szczegóły ramy',
  'edit_listing_page.details.brand': 'Marka',
  'edit_listing_page.details.brand_placeholder': 'Wybierz markę roweru',
  'edit_listing_page.details.brand_required': 'Proszę wybrać markę',
  'edit_listing_page.details.model': 'Model',
  'edit_listing_page.details.model_placeholder': 'Wprowadź model ramy roweru',
  'edit_listing_page.details.model_required': 'Proszę wprowadzić model ramy roweru',
  'edit_listing_page.details.year': 'Rok',
  'edit_listing_page.details.year_placeholder': 'Wybierz rok',
  'edit_listing_page.details.year_required': 'Proszę wybrać rok',
  'edit_listing_page.details.frame_size': 'Rozmiar ramy',
  'edit_listing_page.details.frame_size_placeholder': 'Wybierz rozmiar ramy',
  'edit_listing_page.details.frame_size_required': 'Proszę wybrać rozmiar ramy',
  'edit_listing_page.details.frame_material': 'Materiał',
  'edit_listing_page.details.frame_material_required': 'Proszę wybrać materiał',
  'edit_listing_page.details.frame_color': 'Kolor',
  'edit_listing_page.details.frame_color_required': 'Proszę wybrać kolor',
  'edit_listing_page.details.wheel_size': 'Rozmiar koła',
  'edit_listing_page.details.wheel_size_info':
    'Rozmiar koła to średnica koła rowerowego, zwykle podawana na oponie.',
  'edit_listing_page.details.wheel_size_placeholder': 'Wybierz rozmiar koła',
  'edit_listing_page.details.wheel_size_required': 'Proszę wybrać rozmiar koła',
  'edit_listing_page.details.component_details': 'Szczegóły komponentów',
  'edit_listing_page.details.component_details_placeholder':
    'Jakie komponenty - grupa osprzętu, koła, widelec, amortyzatory, sztyca itp. - znajdują się na rowerze? Na przykład: grupa osprzętu Shimano Dura-Ace, koła Bontrager Aeolus Pro 3v TLR...',
  'edit_listing_page.details.component_details_required': 'Proszę podać szczegóły komponentów',
  'edit_listing_page.details.frame_size_table_title': 'Odpowiedni rozmiar ramy',
  'edit_listing_page.details.frame_size_table_info':
    'Rozmiar ramy roweru zwykle znajduje się na rurze podsiodłowej. Jeśli nie możesz go znaleźć, skonsultuj się z poniższą tabelą.',
  'edit_listing_page.details.frame_size_table_header_1': 'Rozmiar ramy',
  'edit_listing_page.details.frame_size_table_header_2': 'Rama w cm',
  'edit_listing_page.details.wheel_size.hint':
    'Rozmiar koła to średnica koła rowerowego, zwykle podawana na oponie.',

  'edit_listing_page.general_details.tab_title': 'Dodaj kilka zdjęć swojego roweru',
  'edit_listing_page.general_details.image_upload_failed': 'Nie udało się przesłać zdjęcia',
  'edit_listing_page.general_details.image_upload_over_limit':
    'Maksymalny rozmiar pliku wynosi 20 MB',
  'edit_listing_page.general_details.image_required': 'Proszę przesłać co najmniej 4 zdjęcia',
  'edit_listing_page.general_details.image_upload.info': 'Wybierz co najmniej 3 zdjęcia',
  'edit_listing_page.general_details.image_upload.from_device': 'Prześlij z urządzenia',
  'edit_listing_page.general_details.image_upload.add_more': 'Dodaj więcej',
  'edit_listing_page.general_details.image_upload.main_image': 'Zdjęcie główne',
  'edit_listing_page.general_details.title': 'Tytuł',
  'edit_listing_page.general_details.title_placeholder': 'np. Trek Slash 9.7',
  'edit_listing_page.general_details.title_required': 'Proszę podać tytuł',
  'edit_listing_page.general_details.condition': 'Stan',
  'edit_listing_page.general_details.condition_required': 'Proszę wybrać stan roweru',
  'edit_listing_page.general_details.receipt': 'Czy rower ma oryginalny paragon?',
  'edit_listing_page.general_details.receipt_required': 'Proszę podać dostępność paragonu',
  'edit_listing_page.general_details.price': 'Cena',
  'edit_listing_page.general_details.placeholder': '0,00 €',
  'edit_listing_page.general_details.price_required': 'Proszę podać cenę',
  'edit_listing_page.general_details.price_too_low': 'Cena powinna wynosić co najmniej {minPrice}.',
  'edit_listing_page.general_details.price_too_high': 'Cena nie powinna przekraczać {maxPrice}.',
  'edit_listing_page.general_details.saved_image_alt': 'Zapisane zdjęcie oferty',

  'parts_listing_page.details.label': 'Szczegóły',
  'parts_listing_page.categories.label': 'Typ',
  'parts_listing_page.general_details.label': 'Zdjęcia i szczegóły',
  'parts_listing_page.specifications.label': 'Specyfikacje',

  'parts_listing_page.categories.tab_title': 'Który komponent chcesz sprzedać?',
  'parts_listing_page.details.tab_title': 'Podaj podstawowe informacje o swoim komponencie.',
  'parts_listing_page.specification.tab_title': 'Podziel się specyfikacjami komponentu.',

  'parts_listing_page.specification.panel_description':
    'Zwiększ swoje szanse na sprzedaż, dostarczając dodatkowych informacji.',

  'parts_listing_page.details.sub_category': 'Podkategoria',
  'parts_listing_page.details.sub_category_required': 'Proszę wybrać podkategorię',
  'parts_listing_page.details.sub_category_placeholder': 'Wybierz podkategorię',

  'parts_listing_page.details.brand': 'Marka',
  'parts_listing_page.details.brand_placeholder': 'Wybierz markę komponentu',
  'parts_listing_page.details.brand_required': 'Proszę wybrać markę',
  'parts_listing_page.details.model': 'Model',
  'parts_listing_page.details.model_placeholder': 'Wprowadź model komponentu',
  'parts_listing_page.details.model_required': 'Proszę podać model komponentu',
  'parts_listing_page.details.year_placeholder': 'Wybierz rok',

  'parts_listing_page.specification.more_details': 'Więcej szczegółów',
  'parts_listing_page.specifications.frame_size': 'Rozmiar',
  'parts_listing_page.specifications.frame_size_placeholder': 'Wybierz rozmiar',
  'parts_listing_page.specifications.frame_size_required': 'Proszę wybrać rozmiar',
  'parts_listing_page.specifications.frame_material': 'Materiał',
  'parts_listing_page.specifications.frame_material_placeholder': 'Wybierz materiał',
  'parts_listing_page.specifications.frame_material_required': 'Proszę wybrać materiał',
  'parts_listing_page.specifications.wheel_size': 'Rozmiar koła',
  'parts_listing_page.specifications.wheel_size_placeholder': 'Wybierz rozmiar koła',
  'parts_listing_page.specifications.wheel_size_required': 'Proszę wybrać rozmiar koła',
  'parts_listing_page.specifications.front_travel': 'Skok przedni',
  'parts_listing_page.specifications.front_travel_placeholder': 'Wprowadź skok przedni',
  'parts_listing_page.specifications.front_travel_required': 'Proszę wprowadzić skok przedni',
  'parts_listing_page.specifications.rear_travel': 'Skok tylny',
  'parts_listing_page.specifications.rear_travel_placeholder': 'Wprowadź skok tylny',
  'parts_listing_page.specifications.rear_travel_required': 'Proszę wprowadzić skok tylny',
  'parts_listing_page.specifications.discipline': 'Dyscyplina',
  'parts_listing_page.specifications.discipline_placeholder': 'Wybierz dyscyplinę',
  'parts_listing_page.specifications.hub_standard': 'Standard piasty',
  'parts_listing_page.specifications.hub_standard_placeholder': 'Wybierz standard piasty',
  'parts_listing_page.specifications.suspension_type': 'Typ',
  'parts_listing_page.specifications.suspension_mounting': 'Montaż',
  'parts_listing_page.specifications.suspension_length': 'Długość',
  'parts_listing_page.specifications.suspension_length_placeholder': 'Wprowadź długość',
  'parts_listing_page.specifications.suspension_stroke': 'Skok',
  'parts_listing_page.specifications.suspension_stroke_placeholder': 'Wprowadź długość skoku',
  'parts_listing_page.specifications.axle_dimension': 'Wymiar osi',
  'parts_listing_page.specifications.axle_dimension_placeholder': 'Wybierz wymiar osi',
  'parts_listing_page.specifications.crank_arm_length': 'Długość korby',
  'parts_listing_page.specifications.crank_arm_length_placeholder': 'Wprowadź długość korby',
  'parts_listing_page.specifications.gears': 'Przerzutki',
  'parts_listing_page.specifications.gears_placeholder': 'Wybierz przerzutki',
  'parts_listing_page.specifications.placement': 'Typ',
  'parts_listing_page.specifications.activation': 'Aktywacja',
  'parts_listing_page.specifications.front_gears': 'Przerzutki przednie',
  'parts_listing_page.specifications.front_gears_placeholder': 'Wybierz przerzutki przednie',
  'parts_listing_page.specifications.rear_gears': 'Przerzutki tylne',
  'parts_listing_page.specifications.rear_gears_placeholder': 'Wybierz przerzutki tylne',
  'parts_listing_page.specifications.chainring_mounting': 'Montaż tarczy',
  'parts_listing_page.specifications.chainring_mounting_placeholder': 'Wybierz montaż tarczy',
  'parts_listing_page.specifications.chainring_teeth': 'Ilość zębów tarczy',
  'parts_listing_page.specifications.chainring_teeth_placeholder': 'Wprowadź ilość zębów tarczy',
  'parts_listing_page.specifications.bottom_bracket_mounting': 'Montaż suportu',
  'parts_listing_page.specifications.bottom_bracket_mounting_placeholder': 'Wybierz montaż suportu',
  'parts_listing_page.specifications.bottom_bracket_width': 'Szerokość suportu',
  'parts_listing_page.specifications.bottom_bracket_width_placeholder':
    'Wprowadź szerokość suportu',
  'parts_listing_page.specifications.pedals': 'Typ pedałów',
  'parts_listing_page.specifications.pedals_placeholder': 'Wybierz typ pedałów',
  'parts_listing_page.specifications.brake_type': 'Typ hamulców',
  'parts_listing_page.specifications.brakes_activation': 'Aktywacja hamulców',
  'parts_listing_page.specifications.rotor_mounting': 'Montaż tarczy',
  'parts_listing_page.specifications.rotor_placement': 'Typ tarczy',
  'parts_listing_page.specifications.front_disk_diameter': 'Średnica tarczy przedniej',
  'parts_listing_page.specifications.front_disk_diameter_placeholder':
    'Wprowadź średnicę tarczy przedniej',
  'parts_listing_page.specifications.rear_disk_diameter': 'Średnica tarczy tylnej',
  'parts_listing_page.specifications.rear_disk_diameter_placeholder':
    'Wprowadź średnicę tarczy tylnej',
  'parts_listing_page.specifications.wheels_placement': 'Typ kół',
  'parts_listing_page.specifications.front_wheel_size': 'Rozmiar koła przedniego',
  'parts_listing_page.specifications.front_wheel_size_placeholder':
    'Wybierz rozmiar koła przedniego',
  'parts_listing_page.specifications.rear_wheel_size': 'Rozmiar koła tylnej',
  'parts_listing_page.specifications.rear_wheel_size_placeholder': 'Wybierz rozmiar koła tylnej',
  'parts_listing_page.specifications.hubs_placement': 'Typ piast',
  'parts_listing_page.specifications.front_hub_standard': 'Wymiar piasty przedniej',

  'parts_listing_page.general_details.tab_title': 'Dodaj kilka zdjęć części',
  'parts_listing_page.general_details.description.label': 'Opis',
  'parts_listing_page.general_details.description.placeholder':
    'Inne informacje o sprzedawanym przedmiocie, w tym opis wad, opakowania, paragonu i innych istotnych danych.',
  'parts_listing_page.general_details.description.required': 'Proszę podać opis części',
  'parts_listing_page.location.label': 'Lokalizacja',
  'parts_listing_page.general_details.shipping.label': 'Wysyłka',
  'parts_listing_page.general_details.shipping.required': 'Proszę wybrać opcję wysyłki',
  'parts_listing_page.general_details.image_upload.info': 'Wybierz przynajmniej 1 zdjęcie',

  'your_listings_page.edit_listing': 'Edytuj ofertę',
  'your_listings_page.delete_listing': 'Usuń ofertę',
  'your_listings_page.delete_draft': 'Usuń szkic',
  'your_listings_page.title': 'Rowery na sprzedaż',
  'your_listings_page.schema_title': 'Twoje oferty',
  'your_listings_page.delete_modal.title': 'Czy na pewno chcesz usunąć rower?',
  'your_listings_page.delete_modal.delete_action': 'Usuń',
  'your_listings_page.delete_modal.cancel_action': 'Anuluj',

  'inbox_page.label': 'Skrzynka odbiorcza',
  'inbox_page.empty_state.message': 'Nie masz żadnych wiadomości',
  'inbox_page.message_input.placeholder': 'Wyślij wiadomość',

  'about_page.title': 'O nas',
  'about_page.mission_statement':
    'Jesteśmy misją, aby rowerowanie było dostępne i przystępne dla każdego, niezależnie od wieku, płci, zdolności fizycznych czy statusu ekonomicznego. Pasjonuje nas promowanie zrównoważonego transportu i pomaganie ludziom w przyjmowaniu zdrowego stylu życia. Dlatego nasza platforma łączy kupujących z sprzedawcami z całego świata, oferując szeroki wybór wysokiej jakości używanych rowerów w przystępnych cenach.',
  'about_page.sustainability_title': 'Zrównoważoność',
  'about_page.sustainability_statement':
    'Rowerowanie i zakup używanego roweru to zrównoważone wybory korzystne zarówno dla jednostek, jak i dla środowiska. Rowerowanie redukuje emisję dwutlenku węgla i zanieczyszczenie powietrza, podczas gdy zakup używanego roweru przedłuża jego żywotność i redukuje odpady. Ponadto rowerowanie promuje zdrowszy styl życia, zmniejszając obciążenie systemu opieki zdrowotnej. Te zrównoważone wybory przyczyniają się do zdrowszej i bardziej zrównoważonej przyszłości dla wszystkich.',
  'about_page.our_work_title': 'Nasza praca',
  'about_page.our_work_statement':
    'Jesteśmy tutaj od pierwszego wyścigu zjazdowego na Litwie w 2008 roku. Nasza głęboka pasja do kolarstwa i poczucie wspólnoty, jakie przynosi, napędzały nas od tego czasu. Wylało się w nas serce, tworząc przyjazne środowisko, w którym każdy może korzystać z radości z jazdy na rowerze. Tworzyliśmy filmy, organizowaliśmy wydarzenia, uczylismy innych tego, czego nauczyliśmy się w trudny sposób, i dążymy do szerzenia naszej pasji do kolarstwa na szeroką skalę. Jesteśmy przekonani, że miłość do jazdy powinna być dzielona i jesteśmy dedykowani w tworzeniu tej rzeczywistości.',
  'about_page.our_team_title': 'Nasz zespół',
  'about_page.our_team_statement':
    'Jesteśmy zespołem pasjonatów z siedzibą w Wilnie na Litwie, dedykowanym rozwiązywaniu problemów związanych z rowerowaniem. Dążymy do poprawy doświadczenia związanego z jazdą na rowerze i promocji zrównoważonych rozwiązań w transporcie. Pracujmy razem, aby stworzyć lepszą przyszłość dla wszystkich.',

  'profile_settings.title': 'Ustawienia profilu',
  'profile_settings.first_name.label': 'Imię',
  'profile_settings.first_name.placeholder': 'Imię',
  'profile_settings.first_name.required': 'Proszę podać imię',
  'profile_settings.last_name.label': 'Nazwisko',
  'profile_settings.last_name.placeholder': 'Nazwisko',
  'profile_settings.last_name.required': 'Proszę podać nazwisko',
  'profile_settings.bio.label': 'O mnie',
  'profile_settings.bio.placeholder': 'Opowiedz nam trochę o sobie...',
  'profile_settings.save': 'Zapisz zmiany',
  'profile_settings.profile_picture.file_too_large': 'Proszę załadować mniejszy obraz',
  'profile_settings.profile_picture.upload_failed':
    'Wczytywanie zdjęcia profilowego nie powiodło się',
  'profile_settings.profile_picture.edit': 'Edytuj zdjęcie',

  'account_settings.label': 'Ustawienia',
  'account_settings.page_title': 'Ustawienia konta',
  'account_settings.email.heading': 'Dane kontaktowe',
  'account_settings.email.verified': 'Twój adres email jest zweryfikowany.',
  'account_settings.errors.generic': 'Coś poszło nie tak, spróbuj ponownie.',
  'account_settings.email.label': 'Adres email',
  'account_settings.email.required': 'Proszę podać adres email',
  'account_settings.email.invalid': 'Wprowadzony adres email jest nieprawidłowy',
  'account_settings.email.taken': 'Adres email jest już używany',
  'account_settings.confirm_password.label': 'Hasło',
  'account_settings.confirm_password.placeholder': 'Wprowadź nowe hasło',
  'account_settings.confirm_password.required': 'Proszę podać hasło',
  'account_settings.confirm_password.too_short':
    'Hasło powinno mieć co najmniej {minLength} znaków',
  'account_settings.confirm_email_change_label':
    'Aby potwierdzić zmianę adresu email, wprowadź obecne hasło',
  'account_settings.confirm_password.failed': 'Nieprawidłowe hasło',

  'account_settings.phone.label': 'Numer telefonu',
  'account_settings.phone.placeholder': 'Podaj numer telefonu',
  'account_settings.phone.error': 'Podaj poprawny numer telefonu',
  'account_settings.phone.contact_allowed.title': 'Pokaż numer telefonu w ogłoszeniach',
  'account_settings.phone.contact_allowed.description':
    'Zezwól innym użytkownikom na wyświetlanie Twojego numeru telefonu w Twoich ogłoszeniach',

  'account_settings.password.heading': 'Bezpieczeństwo',

  'account_settings.save_changes': 'Zapisz zmiany',

  'password_recovery_page.forgot_password.title': 'Zapomniałeś hasła?',
  'password_recovery_page.forgot_password.message':
    'Podaj adres e-mail, którego użyłeś/aś podczas rejestracji. Wyślemy Ci e-mail z linkiem do zresetowania hasła.',
  'password_recovery_page.forgot_password.email.label': 'E-mail',
  'password_recovery_page.forgot_password.email.placeholder': 'E-mail',
  'password_recovery_page.forgot_password.email.required': 'Proszę podać e-mail',
  'password_recovery_page.forgot_password.email.not_found': 'E-mail nie znaleziony',
  'password_recovery_page.forgot_password.email.invalid': 'Nieprawidłowy adres e-mail',
  'password_recovery_page.forgot_password.submit': 'Wyślij',

  'PasswordRecoveryForm.emailInvalid': 'Wymagany jest prawidłowy adres e-mail',
  'PasswordRecoveryForm.emailLabel': 'E-mail',
  'PasswordRecoveryForm.emailNotFound':
    'Hmm. Nie znaleźliśmy konta o podanym adresie e-mail. Sprawdź poprawność adresu i spróbuj ponownie.',
  'PasswordRecoveryForm.emailPlaceholder':
    '[jan.kowalski@example.com](mailto:jan.kowalski@example.com)',
  'PasswordRecoveryForm.emailRequired': 'To pole jest wymagane',
  'PasswordRecoveryForm.loginLinkInfo': 'Nagle przypomniałeś/aś sobie hasło? {loginLink}',
  'PasswordRecoveryForm.loginLinkText': 'Zaloguj się.',
  'PasswordRecoveryForm.sendInstructions': 'Wyślij instrukcje',
  'PasswordRecoveryPage.actionFailedMessage':
    'Coś poszło nie tak. Proszę odświeżyć stronę i spróbować ponownie.',
  'PasswordRecoveryPage.actionFailedTitle': 'Ups!',
  'PasswordRecoveryPage.emailSubmittedMessage':
    'Instrukcje dotyczące resetowania hasła zostały wysłane na adres {submittedEmailText}.',
  'PasswordRecoveryPage.emailSubmittedTitle': 'Sprawdź swoją skrzynkę odbiorczą',
  'PasswordRecoveryPage.fixEmailInfo': 'Ups, literówka w adresie e-mail? {fixEmailLink}',
  'PasswordRecoveryPage.fixEmailLinkText': 'Popraw ją.',
  'PasswordRecoveryPage.forgotPasswordMessage':
    'Spoko, podaj adres e-mail, który użyłeś/aś podczas rejestracji, a my wyślemy Ci instrukcje dotyczące ustawienia nowego hasła.',
  'PasswordRecoveryPage.forgotPasswordTitle': 'Zapomniałeś hasła?',
  'PasswordRecoveryPage.resendEmailInfo': 'Nie otrzymałeś/aś e-maila? {resendEmailLink}',
  'PasswordRecoveryPage.resendEmailLinkText': 'Wyślij kolejny e-mail.',
  'PasswordRecoveryPage.resendingEmailInfo': 'Ponowne wysyłanie instrukcji...',
  'PasswordRecoveryPage.title': 'Poproś o nowe hasło',

  'favorite_listings_page.label': 'Ulubione ogłoszenia',
  'favorite_listings_page.search_button.bike': 'Szukaj rowerów',
  'favorite_listings_page.search_button.parts': 'Szukaj części',
  'favorite_listings_page.empty_state.bike.title': 'Twoje zapisane rowery',
  'favorite_listings_page.empty_state.bike.description': 'Po zapisaniu roweru pojawi się tutaj',
  'favorite_listings_page.empty_state.parts.title': 'Twoje zapisane części rowerowe',
  'favorite_listings_page.empty_state.parts.description':
    'Po zapisaniu części rowerowej pojawi się tutaj',
  'favorite_listings_page.schema_title': 'Ulubione ogłoszenia',
  'favorite_listings_page.tabs.bikes': 'Rowerów',
  'favorite_listings_page.tabs.parts': 'Części',

  'sign_up.confirm_signup_with_idp_title': 'Zarejestruj się za pomocą {idp}',
  'sign_up.confirm_signup_info_text': 'Sprawdź, czy Twoje dane są poprawne.',

  'cookies.title': 'Korzystamy z plików cookie',
  'cookies.consent_message':
    'NextVelo używa plików cookie, aby poprawić jakość przeglądania, dostosować treść i reklamy oraz analizować ruch na stronie. Klikając „Akceptuj”, wyrażasz zgodę na korzystanie z plików cookie. Jeśli wolisz nie akceptować plików cookie, możesz je wyłączyć w ustawieniach przeglądarki.',
  'cookies.accept': 'Akceptować',

  'pagination.previous': 'Poprzednia strona',
  'pagination.next': 'Następna strona',
  'pagination.to_page': 'Przejdź do strony {page}',

  'validators.min_max_value': 'Wartość musi być między {minValue} a {maxValue}',
  'validators.min_max_value.invalid_type.numeric': 'Wartość powinna być liczbą',

  'about_page.schema_title': 'O nas | {siteTitle}',
  'about_page.schema_description': 'O NextVelo',
  'listing_page.schema_title': '{title} | {siteTitle}',
  'profile_page.schema_title': '{name} | {siteTitle}',
  'search_page.schema_for_search': 'zapytanie',
  'page.schema_title': 'Sprzedawaj i kupuj rowery | {siteTitle}',
  'page.schema_description': 'Największa społeczność rowerowa online.',
  'edit_listing_page.schema_title': 'Utwórz ogłoszenie',
  'log_in.page_schema': 'Zaloguj się | Nextvelo',
  'sign_up.page_schema': 'Zarejestruj się | Nextvelo',
  'password_recovery_page.title': 'Poproś o nowe hasło',
  'inbox_page.schema_title': 'Skrzynka odbiorcza',
  'new_listing_page.schema_title': 'Nowe ogłoszenie',

  'search_page.schema_title': 'Kup używane rowery | NextVelo',
  'search_page.schema_description': 'Kupuj i sprzedawaj używane rowery na NextVelo.',
  'search_page.schema_title.bike':
    'AOdkryj swój następny rower: Rowery używane wysokiej jakości | NextVelo',
  'search_page.schema_description.bike':
    'Odkryj szeroką gamę wysokiej jakości używanych rowerów w NextVelo. Znajdź swój idealny rower szosowy, górski lub gravelowy dzięki bezpiecznym transakcjom i ochronie kupującego. Rozpocznij swoją rowerową przygodę już dziś.',
  'search_page.schema_title.bike.road': 'Wysokiej klasy rowery szosowe | NextVelo',
  'search_page.schema_description.bike.road':
    'Obejmuje najwyższej klasy rowery szosowe dla rowerzystów, którzy wymagają prędkości i wydajności. Przejrzyj naszą kolekcję lekkich, aerodynamicznych konstrukcji w NextVelo.',
  'search_page.schema_title.bike.mountain': ' Rowery górskie | MTB | NextVelo',
  'search_page.schema_description.bike.mountain':
    'Pokonuj najtrudniejsze szlaki z naszymi wytrzymałymi rowerami górskimi. Zaprojektowany z myślą o trwałości i kontroli, nasz wybór w NextVelo spełnia wszystkie potrzeby związane z jazdą w terenie.',
  'search_page.schema_title.bike.gravel': 'Rowery gravelowe | NextVelo',
  'search_page.schema_description.bike.gravel':
    'Wyjedź poza utwardzone drogi z naszymi wszechstronnymi rowerami gravelowymi, zaprojektowanymi z myślą o komforcie i odporności w zróżnicowanym terenie. Odkryj więcej z NextVelo.',
  'search_page.schema_title.bike.city':
    'Rowery miejskie na sprzedaż | Kolarstwo miejskie | NextVelo',
  'search_page.schema_description.bike.city':
    'Poznaj naszą ofertę rowerów miejskich premium, idealnych dla entuzjastów kolarstwa miejskiego. Znajdź idealne połączenie komfortu i stylu na codzienne dojazdy do pracy w NextVelo.',
  'search_page.schema_title.bike.electric': 'Rowery elektryczne | Rowery elektryczne | NextVelo',
  'search_page.schema_description.bike.electric':
    'Wzmocnij swoją jazdę dzięki naszym rowerom elektrycznym, oferującym wspomaganą energią jazdę na rowerze dla łatwości i prędkości. Odkryj idealny e-rower pasujący do Twojego stylu życia w NextVelo.',
  'search_page.schema_title.bike.bmx_dirt': 'Rowery BMX i Dirt | NextVelo',
  'search_page.schema_description.bike.bmx_dirt':
    'Wskocz do akcji z naszymi rowerami BMX i dirtowymi, zaprojektowanymi do wyzwań freestyle i off-road w NextVelo.',
  'search_page.schema_title.bike.folding':
    'Kompaktowe rowery składane | Rowery przenośne | NextVelo',
  'search_page.schema_description.bike.folding':
    'Zmaksymalizuj wygodę dzięki naszym składanym rowerom, łączącym przenośność i wydajność. Idealny do dojazdów do pracy i przechowywania w ciasnych przestrzeniach, znajdź swój w NextVelo.',
  'search_page.schema_title.bike.other':
    'Unikalne i specjalistyczne rowery | Znajdź swój idealny rower | NextVelo',
  'search_page.schema_description.bike.other':
    'Szukasz czegoś innego? Znajdź unikalne i specjalistyczne rowery dla wszystkich preferencji. Zanurz się w naszej eklektycznej kolekcji w NextVelo.',

  'search_page.schema_title.parts': 'Części i komponenty rowerowe | NextVelo',
  'search_page.schema_description.parts':
    'Kupuj wysokiej jakości części i komponenty rowerowe. Znajdź ramy, zawieszenia, układy napędowe, hamulce i nie tylko, aby ulepszyć swoją jazdę w NextVelo.',
  'search_page.schema_title.parts.frame': 'Ramy rowerowe na sprzedaż | NextVelo',
  'search_page.schema_description.parts.frame':
    'Odkryj wytrzymałe i lekkie ramy rowerowe. Wybierz idealną podstawę dla swojej niestandardowej konstrukcji dzięki wyborowi NextVelo.',
  'search_page.schema_title.parts.suspension': 'Systemy zawieszenia rowerowego | NextVelo',
  'search_page.schema_description.parts.suspension':
    'Ulepsz swoją jazdę dzięki najlepiej ocenianym systemom zawieszenia rowerowego. Znajdź widelce i amortyzatory zapewniające płynną pracę w NextVelo.',
  'search_page.schema_title.parts.drivetrain': 'Układy napędowe do rowerów | NextVelo',
  'search_page.schema_description.parts.drivetrain':
    'Ulepsz swój rower dzięki niezawodnym układom napędowym. Łańcuchy, przerzutki i korby zapewniające wydajny transfer mocy w NextVelo.',
  'search_page.schema_title.parts.brakes': 'Hamulce rowerowe | NextVelo',
  'search_page.schema_description.parts.brakes':
    'Zapewnij sobie bezpieczeństwo dzięki wydajnym hamulcom rowerowym. Od hamulców tarczowych po obręczowe, znajdź wszystko, czego potrzebujesz w NextVelo.',
  'search_page.schema_title.parts.wheels': 'Koła i obręcze rowerowe | NextVelo',
  'search_page.schema_description.parts.wheels':
    'Płynnie tocz się dzięki naszej ofercie kół i obręczy rowerowych. Odkryj trwałe opcje dla wszystkich terenów w NextVelo.',
  'search_page.schema_title.parts.tyres_tubes': 'Opony rowerowe na każdy teren | NextVelo',
  'search_page.schema_description.parts.tyres_tubes':
    'Znajdź idealne opony rowerowe na każdy teren w NextVelo. Wybieraj spośród opon szosowych, górskich i szutrowych.',
  'search_page.schema_title.parts.cockpit': 'Kierownice rowerowe i elementy kokpitu | NextVelo',
  'search_page.schema_description.parts.cockpit':
    'Dostosuj swoje centrum sterowania dzięki kierownicom rowerowym i elementom kokpitu. Kupuj wysokiej jakości części zapewniające komfort i wydajność w NextVelo.',
  'search_page.schema_title.parts.seat': 'Foteliki i siodełka rowerowe | NextVelo',
  'search_page.schema_description.parts.seat':
    'Doświadcz komfortu dzięki naszej gamie fotelików i siodełek rowerowych. Znajdź odpowiednie dopasowanie na długie przejażdżki w NextVelo.',

  'PrivacyPolicyPage.schemaTitle': 'Polityka prywatności | {siteTitle}',
};
