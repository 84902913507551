import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';
import Spacer, { MEDIUM_SPACER } from '../Spacer/Spacer';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Paskutinis atnaujinimas: Gegužės 15, 2023</p>
      <h2>PRIVATUMO POLITIKA</h2>

      <p>
        MB “NEXTVELO, įmonės kodas [________], adresas [______], valdanti svetainėje 
        www.nextvelo.com registruojamus asmens duomenis. Svetainės elektroninio pašto 
        adresas [____________], tel. Nr. [_________] (toliau – Duomenų valdytojas).
      </p>

      <p>
        Jūsų pateikiami asmens duomenys mūsų Svetainėje yra tvarkomi vadovaujantis Bendruoju 
        duomenų apsaugos reglamentu (ES) 2016/679 (toliau – Reglamentas), Lietuvos Respublikos 
        asmens duomenų teisinės apsaugos įstatymo bei asmens duomenų apsaugą reglamentuojančiuose 
        ir nustatančiuose aktuose numatytais asmens duomenų tvarkymo reikalavimais ir taisyklėmis, 
        kitais Lietuvos Respublikos ar Europos Sąjungos teisės aktais.
      </p>

      <h2>BENDROSIOS NUOSTATOS</h2>

      <p>
        Šioje privatumo politikoje (toliau – Privatumo politika) aprašoma mūsų MB „NEXTVELO” 
        politika ir procedūros, susijusios su Jūsų informacijos rinkimu, naudojimu ir atskleidimu, 
        kai naudojatės elektronine parduotuve. Taip pat, aprašomos Jūsų privatumo teisės ir tai, 
        kaip įstatymai Jus saugo. 
      </p>

      <p>
        Mes naudojame Jūsų asmens duomenis paslaugų-prekių teikimui bei pardavimui (toliau – Paslaugos) 
        teikti ir tobulinti. Naudodamiesi Paslaugomis, Jūs sutinkate su šioje Privatumo politikoje 
        aprašomu informacijos rinkimu ir naudojimu.
      </p>

      <h2>SĄVOKOS IR INTERPRETACIJA</h2>

      <h3 className={css.subHeader}>Interpretacija</h3>

      <p>
        Žodžiai, kurių pirmoji raidė rašoma didžiosiomis raidėmis, turi reikšmes, apibrėžtas 
        toliau esančiose sąvokose. Šie apibrėžimai turi tą pačia reikšmę neatsižvelgiant į 
        tai, ar jie pateikiami vienaskaita ar daugiskaita. 
      </p>

      <h3 className={css.subHeader}>Sąvokos</h3>

      <p>Šios Privatumo politikos tikslais: </p>

      <ul className={css.listRoot}>
        <li className={css.listItem}>
          Paskyra – unikali paskyra, sukurta Jums norint pasiekti mūsų Paslaugas ar jų dalis;
          </li>
        <li className={css.listItem}>
          Bendrovė (šioje sutartyje vadinama Bendrove, Mes, Mus arba Mūsų) reiškia MB „NEXTVELO”;
        </li>
        <li className={css.listItem}>
          Slapukai – maži failai, kuriuos svetainė įdiegia Jūsų kompiuteryje, mobiliajame 
          ar bet kokiame kitame įrenginyje, kuriuose pateikia informacija apie Jūsų naršymo 
          istoriją toje svetainėje; 
        </li>
        <li className={css.listItem}>
          Valstybė – Lietuva;
        </li>
        <li className={css.listItem}>
          Įrenginys – bet koks įrenginys, kuriuo gali būti pasiekiamos Paslaugos 
          (tai yra kompiuteris, mobilusis telefonas, planšetė ar kita);
        </li>
        <li className={css.listItem}>
          Asmeniniai duomenys – bet kuri informacija, susijusi su fiziniu asmeniu – duomenų 
          subjektu, kurio tapatybė yra žinoma arba gali būti tiesiogiai ar netiesiogiai 
          nustatyta pasinaudojant tokiais duomenimis kaip asmens identifikavimo numeris, 
          vienas arba keli asmeniui būdingi fizinio, fiziologinio, psichologinio, ekonominio, 
          kultūrinio ar socialinio pobūdžio požymiai, asmens buvimo vietos duomenys ir interneto 
          identifikatorius.
        </li>
        <li className={css.listItem}>
          Parduotuvė – ši svetainė, valdoma įmonės MB „NEXTVELO”;
        </li>
        <li className={css.listItem}>
          Naudojimo duomenys – duomenys, surinkti automatiškai arba naudojantis Paslauga (pavyzdžiui, apsilankymo puslapyje trukmė);
        </li>
        <li className={css.listItem}>
          Svetainė – NEXTVELO, pasiekiama adresu www.nextvelo.com; 
        </li>
        <li>
          Jūs – asmuo pasiekiantis ar besinaudojantis Paslauga. Tai taip pat įmonės, 
          juridiniai asmenys, kurių vardu asmuo naudojasi arba naudojosi Paslauga.
        </li>
      </ul>

      <h2>ASMENINIŲ DUOMENŲ RINKIMAS IR NAUDOJIMAS</h2>

      <h3 className={css.subHeader}>Renkamų duomenų tipai</h3>

      <p>
        Kol naudojatės Mūsų paslaugomis, galime paprašyti Jūsų suteikti tam tikrus 
        Asmeninius duomenis, kurie padėtų Jus identifikuoti, su Jumis susisiekti bei 
        suteikti Jums paslaugas. Asmens identifikavimo informacija gali apimti, bet neapsiriboja: 
      </p>

      <ul className={css.listRoot}>
        <li className={css.listItem}>
          Vardas ir pavardė;
        </li>
        <li className={css.listItem}>
          Elektroninio pašto adresas;
        </li>
        <li className={css.listItem}>
          Telefono numeris; 
        </li>
        <li className={css.listItem}>
          Adresas;
        </li>
        <li className={css.listItem}>
          Naudojimo duomenys. 
        </li>
      </ul>

      <h3 className={css.subHeader}>Naudojimo duomenys</h3>

      <p>
        Naudojimo duomenys surenkami automatiškai naudojantis Svetaine. Naudojimo 
        duomenys gali apimti tokią informaciją kaip jūsų Įrenginio interneto protokolo 
        adresą (pvz., IP adresas), naršyklės tipą, naršyklės versiją, Jūsų aplankytus mūsų 
        Svetainės puslapius, Jūsų apsilankymo laiką ir datą, laiką, praleistą tuose puslapiuose, 
        unikalius Įrenginio identifikatorius ir kitus diagnostinius duomenis.
      </p>

      <p>
        Kai Jūs naudojatės Paslauga naudodamiesi mobiliuoju Įrenginiu arba per jį, 
        mes galime automatiškai rinkti tam tikrą informaciją, įskaitant, bet neapsiribojant: 
        Jūsų naudojamo mobiliojo prietaiso tipą, Jūsų mobiliojo Įrenginio unikalų ID, mobiliojo 
        Įrenginio IP adresą, Jūsų mobilaus telefono operacinę sistema, Jūsų naudojamos 
        mobiliosios interneto naršyklės tipą, unikalius Įrenginių identifikatorius ir kitus 
        diagnostikos duomenis.
      </p>

      <p>Taip pat galime rinkti informaciją, kurią Jūsų naršyklė siunčia Jums lankantis Svetainėje. </p>

      <h2>STEBĖJIMO TECHNOLOGIJOS IR SLAPUKAI</h2>

      <p>
        Mes ir mūsų partneriai naudoja įvairias technologijas informacijos apie Svetainės 
        naudojimo statistiką bei naršymo veiklą rinkti, tai gali būti tokio technologijos 
        kaip slapukai arba panašaus pobūdžio technologijos, tokios kaip pikseliai arba tinklapio 
        indikatoriai internetinio puslapio tendencijų analizei, puslapio administravimui, 
        nuasmenintos demografinės informacijos statistikai apie internetinio puslapio lankytojus, 
        todėl gaunama informacija mes negalime identifikuoti konkretaus asmens. Kiekvienas 
        lankytojas savo interneto naršyklėje gali valdyti ir atsisakyti kaip naršyklė priima 
        arba nepriima nei vieno slapuko. Mes savo Svetainėje naudojame savo slapukus kartu su 
        trečiųjų šalių slapukais, norėdami užtikrinti internetinės Svetainės pilną funkcionalumą 
        bei rinkodaros tikslais, norint parodyti specializuotus pasiūlymus Jums. 
      </p>

      <h2>JŪSŲ ASMENINIŲ DUOMENŲ NAUDOJIMAS</h2>

      <h3 className={css.subHeader}>Mes galime naudoti Jūsų Asmeninius duomenis šiais tikslais: </h3>

      <ul className={css.listRoot}>
        <li className={css.listItem}>
          Paslaugos teikimui ir palaikymui;
        </li>
        <li className={css.listItem}>
          Jūsų Paskyros tvarkymui – Jūsų pateikti Asmeniniai duomenys registracijos metu 
          Jums suteikia prieigą prie specialaus Svetainės funkcionalumo; 
        </li>
        <li className={css.listItem}>
          Sutarties vykdymui: Jūsų įsigytų prekių ir (ar) paslaugų įvykdymo ir tiekimo įsipareigojimams; 
        </li>
        <li className={css.listItem}>
          Susisiekimui su Jumis – dėl prekių pristatymo, paslaugų įgyvendinimo, užsakymo 
          ar kitos svarbios informacijos;
        </li>
        <li className={css.listItem}>
          Teikti Jums naujienas, specialius pasiūlymus ir bendrą informaciją apie kitas 
          mūsų siūlomas prekes, paslaugas ir renginius, panašius į tuos, kuriuos jau 
          įsigijote ar apie kurių teiravotės, nebent jūs nusprendėte tokios informacijos negauti.
        </li>
        <li className={css.listItem}>
          Jūsų užklausų įvykdymui;
        </li>
      </ul>

      <h3 className={css.subHeader}>Jūsų Asmens duomenys yra neteikiami tretiesiems asmenims, išskyrus šiuos atvejus:</h3>

      <ul className={css.listRoot}>
        <li className={css.listItem}>
          Kuomet yra gautas Kliento sutikimas;
        </li>
        <li className={css.listItem}>
          Asmens duomenys elektroninės prekybos tikslais teikiami partneriams, 
          teikiantiems Bendrovei paslaugas, susijusias su Bendrovės vykdoma veikla;
        </li>
        <li className={css.listItem}>
          Lietuvos Respublikos įstatymų ir teisės aktų numatytais atvejais kompetentingoms institucijoms;
        </li>
        <li className={css.listItem}>
          Svetainėje apmokėjimai apdorojami naudojantis:
          <ul className={css.listRoot}>
            <li className={css.listItem}>
              [___________], privatumo politika: [___________] 
            </li>
          </ul>
        </li>
      </ul>

      <h2>ASMENINIŲ DUOMENŲ SAUGOJIMAS</h2>

      <p>
        Jūsų asmens duomenys, kaip ir minėta prieš tai, bus tvarkomi laikantis Reglamento, 
        Lietuvos Respublikos asmens duomenų teisinės apsaugos įstatymo bei kitų teisės aktų 
        nustatytų reikalavimų. Tvarkydami Jūsų asmens duomenis, mes įgyvendiname organizacines ir 
        technines priemones, kurios užtikrina asmens duomenų apsaugą nuo atsitiktinio ar 
        neteisėto sunaikinimo, pakeitimo, atskleidimo, taip pat nuo bet kokio kito neteisėto 
        tvarkymo. Jūsų asmeninę informaciją archyvavimo tikslais saugome ne ilgiau nei 10 metų arba 
        tol, kol turime laikytis teisinių įsipareigojimų, sprendžiame ginčus ir vykdome įsipareigojimus. 
        Taip pat, norint užkirsti kelią piktnaudžiavimui ir kitai neteisėtai veikai.
      </p>

      <p>
        Visi Asmens duomenys ir kita Jūsų pateikta informacija laikoma konfidencialia. 
        Prieigą prie Asmens duomenų turi tik tie mūsų darbuotojai bei duomenų tvarkytojai, 
        kuriems ji būtina darbo funkcijoms vykdyti arba paslaugoms teikti.
      </p>

      <h2>NUORODOS Į KITAS SVETAINES</h2>

      <p>
        Mūsų Svetainėje galite rasti nuorodų į kitus puslapius, kurie nėra valdomi Mūsų. 
        Jei spustelėsite trečiosios šalies nuorodą, būsite nukreipti į tos trečiosios šalies svetainę. 
        Mes primygtinai rekomenduojame peržiūrėti kiekvienos lankomos svetainės privatumo politiką.
      </p>

      <p>
        Mes nekontroliuojame ir neprisiimame jokios atsakomybės už trečiųjų šalių 
        svetainių ar paslaugų turinį, privatumo politiką ar praktiką.
      </p>

      <h2>ŠIOS PRIVATUMO POLITIKOS PAKEITIMŲ TVARKA</h2>

      <p>
        Mes galime atnaujinti savo Privatumo politiką. Pranešime apie bet kokius pakeitimus 
        paskelbdami naują privatumo politiką šiame puslapyje.
      </p>

      <p>
        Prieš pakeitimo įsigaliojimą pranešime jums el. paštu ir (arba) gerai matomu 
        pranešimu apie mūsų paslaugą ir atnaujinsime šios Privatumo politikos viršuje 
        esančią datą „Paskutinis atnaujinimas“.
      </p>

      <p>
        Patariame periodiškai peržiūrėti šią Privatumo politiką, ar nėra pakeitimų.
        Šios Privatumo politikos pakeitimai įsigalioja, kai jie paskelbiami šiame puslapyje.
      </p>

      <h2>JŪSŲ TEISĖS</h2>

      <ul className={css.listRoot}>
        <li className={css.listItem}>
          Žinoti, kokie duomenys apie Jus sukaupti: gavus laisvos formos asmens prašymą 
          pateikti, kokius duomenis esame apie jį sukaupę, duomenis pateikiame per 30 kalendorinių 
          dienų nuo gauto prašymo dienos. Kiekvienas asmuo turi teisę sužinoti, kokią informaciją 
          esame sukaupę, prašyti ją koreguoti ar ją ištrinti. Jeigu, gavus prašymą, mums kilo įtarimų 
          dėl besikreipiančiojo asmens tapatybės ar jo amžiaus, mes turime teisę paprašyti 
          besikreipiančiojo asmens tapatybės dokumento ar jo kopijos. 
        </li>
        <li className={css.listItem}>
          Atsisakyti, kad Jūsų informacija būtų naudojama tiesioginės rinkodaros tikslais: 
          mes neįpareigojame savo klientų gauti naujienlaiškius ar bet kokia kitą siunčiamą 
          reklaminę informaciją, todėl laisva valia galite atsisakyti šios paslaugos prisijungę 
          prie savo profilio arba kiekvieno gauto reklaminio laiško apačioje paspaudus interaktyvią nuorodą.
        </li>
        <li className={css.listItem}>
          Būti pamirštam: jeigu Reglamentas ar Lietuvos Respublikos teisės aktai nenumato kitaip, 
          mes Jūsų teisėtu prašymu ištrinsime visus sukauptus duomenis apie Jus iš savo serverių 
          ir informacinės sistemos per 4 kalendorines dienas, nebent tai būtų neįmanoma ar 
          pareikalautų iš Duomenų valdytojų neproporcingų pastangų. Duomenys negali būti ištrinti 
          tada, kai jų saugojimą įpareigoja Europos Sąjungos ar Lietuvos Respublikos teisės aktai. 
        </li>
        <li className={css.listItem}>
          Nesutikti: jūs galite nesutikti su mūsų privatumo politika, tačiau dėl tam tikrų mums 
          reikalingų Jūsų būtinų duomenų sutarties vykdymui ir užtikrinant sklandų Svetainės veikimą, 
          mums Jūsų duomenys yra būtini, todėl jei nesutinkate su mūsų taisyklėmis, deja, negalime 
          sudaryti su Jumis pardavimo sutarties ir suteikti mūsų siūlomų paslaugų.
        </li>
      </ul>

      <p>Dėl Jūsų teisių įgyvendinimo kreipkitės raštu, valstybine kalba elektroniniu paštu: </p>

      <h2>ATSAKOMYBĖ</h2>

      <p>
        Jūs esate atsakingi už savo slaptažodžio ir vartotojo duomenų konfidencialumą ir 
        už bet kuriuos veiksmus (duomenų perdavimą, pateiktus užsakymus ar kt.), kurie mūsų 
        Svetainėje atliekami prisijungus naudojant Jūsų prisijungimo duomenis. Jūs negalite 
        atskleisti savo slaptažodžio tretiems asmenims. Jei mūsų Svetainėje teikiamomis 
        paslaugomis naudojasi trečiasis asmuo, prisijungęs prie Svetainės naudodamasis Jūsų 
        prisijungimo duomenimis, mes laikome, kad prisijungėte Jūs. Jei prarandate 
        prisijungimo duomenis, Jūs privalote nedelsiant informuoti apie tai mus paštu, 
        telefonu, faksu ar elektroniniu paštu.
      </p>

      <p>
        Jūs privalote saugoti savo prisijungimo prie Svetainės slaptažodį ir prisijungimo 
        vardą, jei esate susikūrę Paskyrą, bei kitus duomenis, jokiems kitiems tretiesiems 
        asmenims neatskleisti savo prisijungimo duomenų. Mes neatsakome, jeigu Jūsų Asmens 
        duomenys neteisėtai pakeičiami, atskleidžiami, sunaikinami, Jūsų tapatybė pavagiama 
        ar kitaip sukčiaujama pasinaudojant Jūsų Asmens duomenimis, dėl Jūsų paties kaltės 
        ar neatsargumo, kuris kilo kaip šios Privatumo politikos, Svetainės naudojimo 
        taisyklių ar teisės aktų nesilaikymo pasekmė. Jei mūsų Svetainėje teikiamomis paslaugomis 
        naudojasi trečiasis asmuo, prisijungęs prie Svetainės naudodamasis Jūsų prisijungimo 
        duomenimis, mes laikome, kad prisijungėte Jūs. Jei prarandate prisijungimo duomenis, 
        Jūs privalote nedelsiant informuoti apie tai mus paštu, telefonu, faksu ar elektroniniu 
        paštu. Taip pat mes jokiu atveju nebūsime atsakingi už žalą, atsiradusią Jums dėl to, 
        jog Jūs nurodėte neteisingus ar neišsamius asmens duomenis arba neinformavote mūsų 
        jiems pasikeitus. 
      </p>

      <h2>SUSISIEKITE</h2>

      <p>
        Jei turite klausimų apie šią Privatumo politiką, galite susisiekti : [___________].
      </p>

    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
