/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

import CityCategoryLogo from '../assets/icons/categories/city.png'
import RoadCategoryLogo from '../assets/icons/categories/road.png'
import MountainCategoryLogo from '../assets/icons/categories/mountain.png'
import GravelCategoryLogo from '../assets/icons/categories/gravel.png'
import BmxCategoryLogo from '../assets/icons/categories/bmx.png'
import FoldingCategoryLogo from '../assets/icons/categories/folding.png'
import ElectricCategoryLogo from '../assets/icons/categories/electric.png'
import OtherCategoryLogo from '../assets/icons/categories/other.png'

export const filters = [
  {
    id: 'catalogTree',
    label: 'Catalog tree',
    type: 'MultiLevelFilter',
    queryParamNames: ['pub_listingType', 'pub_category', 'pub_subCategory'],
    isBaseFilter: true,
    isFilterable: true,
    config: {
      options: [
        { 
          key: 'bike',
          label: 'Bikes',
          options: [
            { key: 'city', label: 'City' },
            { key: 'road', label: 'Road' },
            { key: 'mountain', label: 'Mountain' },
            { key: 'gravel', label: 'Gravel' },
            { key: 'bmx_dirt', label: 'Bmx & Dirt' },
            { key: 'folding', label: 'Folding' },
            { key: 'electric', label: 'Electric' },
            { key: 'other', label: 'Other' }
          ]
        },
        { 
          key: 'parts',
          label: 'Parts',
          options: [
            { 
              key: 'frame',
              label: 'Frame',
              options: [
                { key: 'frame_full_suspension', label: 'Full suspension' },
                { key: 'frame_hard_tail_mtb', label: 'Hardtail MTB' },
                { key: 'frame_road', label: 'Road' },
                { key: 'frame_gravel', label: 'Gravel' },
                { key: 'frame_full_suspension_e_bike', label: 'Full suspension E-Bike' },
                { key: 'frame_hard_tail_e_bike', label: 'Hardtail E-Bike' },
                { key: 'frame_city', label: 'City' },
                { key: 'frame_dirt_n_street', label: 'Dirt & street' }
              ] 
            },
            { 
              key: 'suspension',
              label: 'Suspension',
              options: [
                { key: 'suspension_rigid_fork', label: 'Rigid fork' },
                { key: 'suspension_suspension_fork', label: 'Suspension fork' },
                { key: 'suspension_rear', label: 'Rear suspension' },
              ] 
            },
            { 
              key: 'drivetrain',
              label: 'Drivetrain',
              options: [
                { key: 'drivetrain_crank', label: 'Crank' },
                { key: 'drivetrain_chain', label: 'Chain' },
                { key: 'drivetrain_cassette', label: 'Cassette' },
                { key: 'drivetrain_shifter', label: 'Shifter' },
                { key: 'drivetrain_derailleur', label: 'Derailleur' },
                { key: 'drivetrain_chainring', label: 'Chainring' },
                { key: 'drivetrain_bottom_bracket', label: 'Bottom bracket' },
                { key: 'drivetrain_chain_guides', label: 'Chain guides' },
                { key: 'drivetrain_pedals', label: 'Pedals' },
              ] 
            },
            { 
              key: 'brakes',
              label: 'Brakes',
              options: [
                { key: 'brakes_disk', label: 'Disk' },
                { key: 'brakes_rim', label: 'Rim' },
                { key: 'brakes_rotor', label: 'Rotor' },
                { key: 'brakes_other', label: 'Other' },
              ] 
            },
            { 
              key: 'wheels',
              label: 'Wheels',
              options: [
                { key: 'wheels_complete', label: 'Wheels complete' },
                { key: 'wheels_hubs', label: 'Hubs' },
                { key: 'wheels_rims', label: 'Rims' },
              ] 
            },
            { key: 'tyres_tubes', label: 'Tyres & Tubes' },
            { 
              key: 'cockpit',
              label: 'Cockpit',
              options: [
                { key: 'cockpit_handlebar', label: 'Handlebar' },
                { key: 'cockpit_stem', label: 'Stem' },
                { key: 'cockpit_grips', label: 'Grips' },
                { key: 'cockpit_headset', label: 'Headset' },
              ] 
            },
            { 
              key: 'seat',
              label: 'Seat',
              options: [
                { key: 'seat_dropper_post', label: 'Dropper post' },
                { key: 'seat_post', label: 'Seat post' },
                { key: 'seat_saddle', label: 'Seat saddle' },
                { key: 'seat_other', label: 'Other' },
              ] 
            }
          ]
        }
      ]
    }
  },
  {
    id: 'category',
    label: 'Bike type',
    translationGroup: 'categories',
    type: 'SelectMultipleFilter',
    isOpen: true,
    isFilterable: false,
    group: 'primary',
    sortingOrder: 4,
    queryParamNames: ['pub_category'],
    config: {
      schemaType: 'enum',
      options: [
        { key: 'city', label: 'City', logo: CityCategoryLogo },
        { 
          key: 'road', label: 'Road', logo: RoadCategoryLogo, options: [
            { key: 'road', label: 'Road' },
            { key: 'track', label: 'Track' },
            { key: 'aearo_triathlon_tt', label: 'Aearo/Triathlon/TT'},
            { key: 'single_gear', label: 'Single gear' }
          ] 
        },
        { 
          key: 'mountain', label: 'Mountain', logo: MountainCategoryLogo, options: [
            { key: 'xc_cross_country', label: 'XC / Cross Country' },
            { key: 'trail', label: 'Trail' },
            { key: 'enduro', label: 'Enduro'},
            { key: 'downhill', label: 'Downhill' }
          ]
        },
        { key: 'gravel', label: 'Gravel', logo: GravelCategoryLogo },
        { 
          key: 'bmx_dirt', label: 'Bmx & Dirt', logo: BmxCategoryLogo, options: [
            { key: 'bmx', label: 'Bmx' },
            { key: 'dirt_street', label: 'Dirt/Street Bikes'},
          ] 
        },
        { key: 'folding', label: 'Folding', logo: FoldingCategoryLogo },
        { 
          key: 'electric', label: 'Electric', logo: ElectricCategoryLogo, options: [
            { key: 'electric_city', label: 'City' },
            { key: 'electric_mountain', label: 'Mountain'},
            { key: 'electric_road', label: 'Road' },
            { key: 'electric_other', label: 'Other'},
          ]
        },
        { 
          key: 'other', label: 'Other', logo: OtherCategoryLogo, options: [
            { key: 'cargo', label: 'Cargo' },
            { key: 'tricycle', label: 'Tricycle'},
            { key: 'tandem', label: 'Tandem' },
            { key: 'balance', label: 'Balance'},
            { key: 'unicycle', label: 'Unicycle'},
          ]
        },
      ],
    },
  },
  {
    id: 'brand',
    label: 'Brand',
    isOpen: false,
    isFilterable: true,
    type: 'DropDownFilter',
    group: 'primary',
    sortingOrder: 6,
    queryParamNames: ['pub_brand'],
    translationGroup: 'brand',
    skipItemPageTranslations: true,
    config: {
      schemaType: 'enum',
      options: [
        { key: 'no_brand', value: 'no_brand', label: 'No brand' },
        { key: 'other', value: 'other', label: 'Other' },
        { key: 'scott', value: 'scott', label: 'Scott', featured: true },
        { key: 'giant', value: 'giant', label: 'Giant', featured: true },
        { key: 'wilier', value: 'wilier', label: 'Wilier', featured: true },
        { key: 'cannondale', value: 'cannondale', label: 'Cannondale' },
        { key: 'trek', value: 'trek', label: 'Trek', featured: true },
        { key: 'specialized', value: 'specialized', label: 'Specialized', featured: true },
        { key: 'cube', value: 'cube', label: 'Cube', featured: true },
        { key: 'canyon', value: 'canyon', label: 'Canyon', featured: true },
        { key: 'marin', value: 'marin', label: 'Marin' },
        { key: 'schwinn', value: 'schwinn', label: 'Schwinn' },
        { key: 'fuji', value: 'fuji', label: 'fuji' },
        { key: 'gt', value: 'gt', label: 'GT' },
        { key: 'niner', value: 'niner', label: 'Niner' },
        { key: 'whyte', value: 'whyte', label: 'Whyte' },
        { key: 'mongoose', value: 'mongoose', label: 'Mongoose' },
        { key: 'diamonback', value: 'diamonback', label: 'Diamonback' },
        { key: 'gary_fisher', value: 'gary_fisher', label: 'Gary Fisher' },
        { key: 'felt', value: 'felt', label: 'Felt' },
        { key: 'redline', value: 'redline', label: 'Redline' },
        { key: 'haro', value: 'haro', label: 'Haro' },
        { key: 'raleigh', value: 'raleigh', label: 'Raleigh' },
        { key: 'bianchi', value: 'bianchi', label: 'Bianchi' },
        { key: 'kink_industries', value: 'kink_industries', label: 'Kink Industries' },
        { key: 'santa_cruz', value: 'santa_cruz', label: 'Santa Cruz' },
        { key: 'cervelo', value: 'cervelo', label: 'Cervelo' },
        { key: 'colnago', value: 'colnago', label: 'Colnago' },
        { key: 'motobecane', value: 'motobecane', label: 'Motobecane' },
        { key: 'novara', value: 'novara', label: 'novara' },
        { key: 'bmc', value: 'bmc', label: 'BMC' },
        { key: 'devinci', value: 'devinci', label: 'Devinci' },
        { key: 'khs', value: 'khs', label: 'KHS' },
        { key: 'pivot', value: 'pivot', label: 'Pivot' },
        { key: 'roadmaster', value: 'roadmaster', label: 'Roadmaster' },
        { key: 'campagnolo', value: 'campagnolo', label: 'Campagnolo' },
        { key: 'jamis', value: 'jamis', label: 'Jamis' },
        { key: 'orbea', value: 'orbea', label: 'Orbea' },
        { key: 'ridley', value: 'ridley', label: 'Ridley' },
        { key: 'blackburn', value: 'blackburn', label: 'Blackburn' },
        { key: 'dk', value: 'dk', label: 'DK' },
        { key: 'eddy_merckx', value: 'eddy_merckx', label: 'Eddy Merckx' },
        { key: 'evo', value: 'evo', label: 'EVO' },
        { key: 'chrome', value: 'chrome', label: 'Chrome' },
        { key: 'ibis', value: 'ibis', label: 'Ibis' },
        { key: 'kona', value: 'kona', label: 'Kona' },
        { key: 'linskey_performace', value: 'linskey_performace', label: 'Linskey Performace' },
        { key: 'mtb', value: 'mtb', label: 'MTB' },
        { key: 'norco', value: 'norco', label: 'Norco' },
        { key: 'parlee', value: 'parlee', label: 'Parlee' },
        { key: 'pearl_izumi', value: 'pearl_izumi', label: 'Pearl Izumi' },
        { key: 'quintana_roo', value: 'quintana_roo', label: 'Quintana Roo' },
        { key: 'rocky_mountain', value: 'rocky_mountain', label: 'Rocky Mountain' },
        { key: 'salsa', value: 'salsa', label: 'Salsa' },
        { key: 'seven', value: 'seven', label: 'Seven' },
        { key: 'shimano', value: 'shimano', label: 'Shimano' },
        { key: 'triple_eight', value: 'triple_eight', label: 'Triple Eight' },
        { key: 'ktm', value: 'ktm', label: 'KTM' },
        { key: 'yeti', value: 'yeti', label: 'Yeti' },
        { key: 'focus', value: 'focus', label: 'Focus' },
        { key: 'commencal', value: 'commencal', label: 'Commencal' },
        { key: 'academy', value: 'academy', label: 'Academy' },
        { key: 'a2b', value: 'a2b', label: 'A2B' },
        { key: '8bar', value: '8bar', label: '8bar' },
        { key: 'airborne', value: 'airborne', label: 'Airborne' },
        { key: 'airdrop', value: 'airdrop', label: 'Airdrop' },
        { key: 'airstreeem', value: 'airstreeem', label: 'Airstreeem' },
        { key: 'alchemy', value: 'alchemy', label: 'Alchemy' },
        { key: 'alias', value: 'alias', label: 'Alias' },
        { key: 'all_city', value: 'all_city', label: 'All-City' },
        { key: 'alutech', value: 'alutech', label: 'Alutech' },
        { key: 'amber', value: 'amber', label: 'Amber' },
        { key: 'amity', value: 'amity', label: 'Amity' },
        { key: 'anesi', value: 'anesi', label: 'Anesi' },
        { key: 'apollo', value: 'apollo', label: 'Apollo' },
        { key: 'arc8', value: 'arc8', label: 'Arc8' },
        { key: 'argon_18', value: 'argon_18', label: 'Argon 18' },
        { key: 'atalanta', value: 'atalanta', label: 'Atalanta' },
        { key: 'atomz', value: 'atomz', label: 'Atomz' },
        { key: 'avanti', value: 'avanti', label: 'Avanti' },
        { key: 'aventon', value: 'aventon', label: 'Aventon' },
        { key: 'ax_lightness', value: 'ax_lightness', label: 'AX Lightness' },
        { key: 'bakfiets', value: 'bakfiets', label: 'bakfiets' },
        { key: 'banshee', value: 'banshee', label: 'Banshee' },
        { key: 'basso', value: 'basso', label: 'Basso' },
        { key: 'batavus', value: 'batavus', label: 'Batavus' },
        { key: 'batch_bicycles', value: 'batch_bicycles', label: 'Batch Bicycles' },
        { key: 'bergamont', value: 'bergamont', label: 'Bergamont' },
        { key: 'berria', value: 'berria', label: 'Berria' },
        { key: 'black_eye', value: 'black_eye', label: 'Black Eye' },
        { key: 'blank', value: 'blank', label: 'Blank' },
        { key: 'bombtrack', value: 'bombtrack', label: 'Bombtrack' },
        { key: 'bottecchia', value: 'bottecchia', label: 'Bottecchia' },
        { key: 'cadence', value: 'cadence', label: 'Cadence' },
        { key: 'calibre', value: 'calibre', label: 'Calibre' },
        { key: 'califeira', value: 'califeira', label: 'Califeira' },
        { key: 'canfield', value: 'canfield', label: 'Canfield' },
        { key: 'carraro', value: 'carraro', label: 'Carraro' },
        { key: 'chromag', value: 'chromag', label: 'Chromag' },
        { key: 'civia', value: 'civia', label: 'Civia' },
        { key: 'clean', value: 'clean', label: 'Clean' },
        { key: 'cleary', value: 'cleary', label: 'Cleary' },
        { key: 'comas', value: 'comas', label: 'Comas' },
        { key: 'commercal', value: 'commercal', label: 'Commercal' },
        { key: 'contura', value: 'contura', label: 'Contura' },
        { key: 'conway', value: 'conway', label: 'Conway' },
        { key: 'corratec', value: 'corratec', label: 'Corratec' },
        { key: 'czar', value: 'czar', label: 'Czar' },
        { key: 'daccordi', value: 'daccordi', label: 'Daccordi' },
        { key: 'dahon', value: 'dahon', label: 'Dahon' },
        { key: 'dartmoor', value: 'dartmoor', label: 'Dartmoor' },
        { key: 'de_rosa', value: 'de_rosa', label: 'De Rosa' },
        { key: 'deviate', value: 'deviate', label: 'Deviate' },
        { key: 'diamant', value: 'diamant', label: 'Diamant' },
        { key: 'diamondback', value: 'diamondback', label: 'Diamondback' },
        { key: 'ebikemanufaktur', value: 'ebikemanufaktur', label: 'e.bikemanufaktur' },
        { key: 'early_rider', value: 'early_rider', label: 'Early Rider' },
        { key: 'eastern_bikes', value: 'eastern_bikes', label: 'Eastern Bikes' },
        { key: 'electra', value: 'electra', label: 'Electra' },
        { key: 'enigma', value: 'enigma', label: 'Enigma' },
        { key: "gasgas", value: "gasgas", label: "GasGas" },
        { key: "gazelle", value: "gazelle", label: "Gazelle" },
        { key: "gepida", value: "gepida", label: "Gepida" },
        { key: "garmanxia", value: "garmanxia", label: "GarmanXia" },
        { key: "grace", value: "grace", label: "Grace" },
        { key: "guerrilla_gravity", value: "guerrilla_gravity", label: "Guerrilla Gravity" },
        { key: "haibike", value: "haibike", label: "Haibike" },
        { key: "harthe_manufaktur", value: "harthe_manufaktur", label: "Harthe Manufaktur" },
        { key: "head", value: "head", label: "Head" },
        { key: "hnf_nicolai", value: "hnf_nicolai", label: "HNF-Nicolai" },
        { key: "hoffman_bikes", value: "hoffman_bikes", label: "Hoffman Bikes" },
        { key: "husqvarna", value: "husqvarna", label: "Husqvarna" },
        { key: "ideal", value: "ideal", label: "Ideal" },
        { key: "juliana", value: "juliana", label: "Juliana" },
        { key: "kebra", value: "kebra", label: "Kebra" },
        { key: "kalkhoff", value: "kalkhoff", label: "Kalkhoff" },
        { key: "kavenz", value: "kavenz", label: "Kavenz" },
        { key: "kayza", value: "kayza", label: "Kayza" },
        { key: "kestrel_usa", value: "kestrel_usa", label: "Kestrel USA" },
        { key: "koga", value: "koga", label: "Koga" },
        { key: "lapierre", value: "lapierre", label: "Lapierre" },
        { key: "last", value: "last", label: "Last" },
        { key: "lee_cougan", value: "lee_cougan", label: "Lee Cougan" },
        { key: "louis_garneau", value: "louis_garneau", label: "Louis Garneau" },
        { key: "lynskey_performance", value: "lynskey_performance", label: "Lynskey Performance" },
        { key: "maestro", value: "maestro", label: "Maestro" },
        { key: "mafia_bikes", value: "mafia_bikes", label: "Mafia Bikes" },
        { key: "manhattan", value: "manhattan", label: "Manhattan" },
        { key: "magemo", value: "magemo", label: "Magemo" },
        { key: "merida", value: "merida", label: "Merida" },
        { key: "mirraco", value: "mirraco", label: "Mirraco" },
        { key: "mondraker", value: "mondraker", label: "Mondraker" },
        { key: "moustache", value: "moustache", label: "Moustache" },
        { key: "octane-one", value: "octane-one", label: "Octane One" },
        { key: "olympia", value: "olympia", label: "Olympia" },
        { key: "orange", value: "orange", label: "Orange" },
        { key: "patrol", value: "patrol", label: "Patrol" },
        { key: "pegasus", value: "pegasus", label: "Pegasus" },
        { key: "pinarello", value: "pinarello", label: "Pinarello" },
        { key: "privateer", value: "privateer", label: "Privateer" },
        { key: "propain", value: "propain", label: "Propain" },
        { key: "pyga", value: "pyga", label: "Pyga" },
        { key: "quietkat", value: "quietkat", label: "QuietKat" },
        { key: "raaw", value: "raaw", label: "RAAW" },
        { key: "rabeneick", value: "rabeneick", label: "Rabeneick" },
        { key: "radio", value: "radio", label: "Radio" },
        { key: "radon", value: "radon", label: "Radon" },
        { key: "ragley", value: "ragley", label: "Ragley" },
        { key: "raleigh_electric", value: "raleigh_electric", label: "Raleigh Electric" },
        { key: "ramlon", value: "ramlon", label: "Ramlon" },
        { key: "raymon", value: "raymon", label: "Raymon" },
        { key: "resonator", value: "resonator", label: "Resonator" },
        { key: "rock_machine", value: "rock_machine", label: "Rock Machine" },
        { key: "rockman", value: "rockman", label: "Rockman" },
        { key: "saracen", value: "saracen", label: "Saracen" },
        { key: "saxonette", value: "saxonette", label: "Saxonette" },
        { key: "shindelhauer", value: "shindelhauer", label: "Shindelhauer" },
        { key: "se_racing", value: "se_racing", label: "SE Racing" },
        { key: "ns_bikes", value: "ns_bikes", label: "NS Bikes" },
        { key: "steven_bikes", value: "stevens_bikes", label: "Stevens Bikes" }
      ],
    },
  },
  {
    id: 'year',
    label: 'Year',
    isOpen: false,
    type: 'RangeFilter',
    isBaseFilter: true,
    isFilterable: true,
    group: 'primary',
    sortingOrder: 7,
    queryParamNames: ['pub_year'],
    translationGroup: 'year',
    skipItemPageTranslations: true,
    config: {
      schemaType: 'enum',
      min: 1990,
      max: 2023,
      labelKey: 'year',

      options: [
        { key: '2023', value: '2023', label: '2023' },
        { key: '2022', value: '2022', label: '2022' },
        { key: '2021', value: '2021', label: '2021' },
        { key: '2020', value: '2020', label: '2020' },
        { key: '2019', value: '2019', label: '2019' },
        { key: '2018', value: '2018', label: '2018' },
        { key: '2017', value: '2017', label: '2017' },
        { key: '2016', value: '2016', label: '2016' },
        { key: '2015', value: '2015', label: '2015' },
        { key: '2014', value: '2014', label: '2014' },
        { key: '2013', value: '2013', label: '2013' },
        { key: '2012', value: '2012', label: '2012' },
        { key: '2010', value: '2010', label: '2010' },
        { key: '2009', value: '2009', label: '2009' },
        { key: '2008', value: '2008', label: '2008' },
        { key: '2007', value: '2007', label: '2007' },
        { key: '2006', value: '2006', label: '2006' },
        { key: '2005', value: '2005', label: '2005' },
        { key: '2004', value: '2004', label: '2004' },
        { key: '2003', value: '2003', label: '2003' },
        { key: '2002', value: '2002', label: '2002' },
        { key: '2001', value: '2001', label: '2001' },
        { key: '2000', value: '2000', label: '2000' },
        { key: '1999', value: '1999', label: '1999' },
        { key: '1998', value: '1998', label: '1998' },
        { key: '1997', value: '1997', label: '1997' },
        { key: '1996', value: '1996', label: '1996' },
        { key: '1995', value: '1995', label: '1995' },
        { key: '1994', value: '1994', label: '1994' },
        { key: '1993', value: '1993', label: '1993' },
        { key: '1992', value: '1992', label: '1992' },
        { key: '1991', value: '1991', label: '1991' },
        { key: '1990', value: '1990', label: '1990' },
      ],
    },
  },
  {
    id: 'frameSize',
    label: 'Frame size',
    isOpen: true,
    isFilterable: true,
    type: 'DropDownFilter',
    group: 'primary',
    sortingOrder: 3,
    queryParamNames: ['pub_frameSize'],
    translationGroup: 'frame_size',
    skipItemPageTranslations: true,
    config: {
      schemaType: 'enum',

      options: [
        { key: 'xxl', value: 'xxl', label: 'XXL' },
        { key: 'xl', value: 'xl', label: 'XL' },
        { key: 'l', value: 'l', label: 'L' },
        { key: 'm', value: 'm', label: 'M' },
        { key: 's', value: 's', label: 'S' },
        { key: 'xs', value: 'xs', label: 'XS' },
        { key: 'xxs', value: 'xxs', label: 'XSS' },
      ],
    },
  },
  {
    id: 'wheelSize',
    label: 'Wheel size',
    translationGroup: 'wheel_size',
    isOpen: false,
    isFilterable: true,
    type: 'SelectMultipleFilter',
    group: 'primary',
    sortingOrder: 10,
    queryParamNames: ['pub_wheelSize'],
    config: {
      schemaType: 'enum',

      options: [
        { key: '26', value: '26', label: '26”' },
        { key: '27.5', value: '27.5', label: '27.5” (650b)' },
        { key: '700C', value: '700C', label: '28” (700c)' },
        { key: '29', value: '29', label: '29”' },
        { key: 'other', value: 'other', label: 'Other' },
      ],
    },
  },
  {
    id: 'frameMaterial',
    label: 'Frame material',
    translationGroup: 'frame_material',
    isOpen: false,
    isFilterable: true,
    type: 'SelectMultipleFilter',
    group: 'primary',
    sortingOrder: 8,
    queryParamNames: ['pub_frameMaterial'],
    config: {
      schemaType: 'enum',

      options: [
        { key: 'aluminum', value: 'aluminum', label: 'Aluminum' },
        { key: 'carbon', value: 'carbon', label: 'Carbon' },
        { key: 'steel', value: 'steel', label: 'Steel' },
        { key: 'other', value: 'other', label: 'Other' },
      ],
    },
  },
  {
    id: 'frameColor',
    label: 'Color',
    translationGroup: 'frame_color',
    isOpen: false,
    isFilterable: true,
    type: 'SelectMultipleFilter',
    group: 'primary',
    sortingOrder: 11,
    queryParamNames: ['pub_frameColor'],
    config: {
      schemaType: 'enum',

      options: [
        { key: 'pink', value: 'pink', label: 'Pink', style: { background: '#E84176' } },
        { key: 'green', value: 'green', label: 'Green', style: { background: '#62C97D' } },
        { key: 'orange', value: 'orange', label: 'Orange', style: { background: '#E4672B' } },
        { key: 'blue', value: 'blue', label: 'Blue', style: { background: '#2767C5' } },
        { key: 'red', value: 'red', label: 'Red', style: { background: '#DA4942' } },
        { key: 'white', value: 'white', label: 'White', style: { background: '#FFFFFF', border: '1px solid #C9C9C9' } },
        { key: 'yellow', value: 'yellow', label: 'Yellow', style: { background: '#FEEF50' } },
        { key: 'black', value: 'black', label: 'Black', style: { background: '#000000' } },
        { key: 'grey', value: 'grey', label: 'Grey/metal', style: { background: '#808080' } },
        { key: 'other', value: 'other', label: 'Other', style: { background: 'linear-gradient(180deg, #FF0000 0%, #3DDD23 26.56%, #D04BFF 58.33%, rgba(45, 91, 255, 0.94) 100%)' } },
      ],
    },
  },
  {
    id: 'receiptAvailable',
    label: 'Receipt',
    translationGroup: 'receipt',
    isOpen: false,
    isFilterable: true,
    type: 'SelectMultipleFilter',
    group: 'primary',
    sortingOrder: 12,
    queryParamNames: ['pub_receiptAvailable'],
    config: {
      schemaType: 'enum',

      options: [
        { key: 'yes', value: 'yes', label: 'Yes' },
        { key: 'no', value: 'no', label: 'No' }
      ],
    },
  },
  {
    id: 'condition',
    label: 'Condition',
    translationGroup: 'condition',
    isOpen: false,
    isFilterable: true,
    type: 'SelectMultipleFilter',
    group: 'primary',
    sortingOrder: 5,
    queryParamNames: ['pub_condition'],
    config: {
      schemaType: 'enum',

      options: [
        { key: 'bad', value: 0, label: 'Ooof', labelInfo: `✔ Cosmetic blemishes\n✔ Might need regular service\n✔ Brake pads might need change\n✔ Optically in good condition` },
        { key: 'partly_bad', value: 25, label: 'Bad', labelInfo: `✔ Bad Cosmetic blemishes\n✔ Might need regular service\n✔ Brake pads might need change\n✔ Optically in good condition` },
        { key: 'good', value: 50, label: 'Good', labelInfo: `✔ Good Cosmetic blemishes\n✔ Might need regular service\n✔ Brake pads might need change\n✔ Optically in good condition` },
        { key: 'perfect', value: 75, label: 'Great', labelInfo: `✔ Great Cosmetic blemishes\n✔ Might need regular service\n✔ Brake pads might need change\n✔ Optically in good condition` },
        { key: 'brand_new', value: 100, label: 'New', labelInfo: `✔ New Cosmetic blemishes\n✔ Might need regular service\n✔ Brake pads might need change\n✔ Optically in good condition` },
      ],
    },
  },
  {
    id: 'shipping',
    label: 'Shipping',
    translationGroup: 'shipping',
    isOpen: false,
    isFilterable: true,
    type: 'SelectMultipleFilter',
    group: 'primary',
    sortingOrder: 13,
    queryParamNames: ['pub_shipping'],
    config: {
      schemaType: 'enum',

      options: [
        { key: 'no_shipping', value: 'no_shipping', label: 'No Shipping' },
        { key: 'domestic_shipping', value: 'domestic_shipping', label: 'Will ship within country' },
        { key: 'international_shipping', value: 'international_shipping', label: 'Will ship outside country' }
      ],
    },
  },
  {
    id: 'country',
    label: 'Country',
    translationGroup: 'country',
    isOpen: true,
    isBaseFilter: true,
    isFilterable: false,
    type: 'DropDownFilter',
    group: 'primary',
    sortingOrder: 2,
    queryParamNames: ['pub_country'],
    translatableLabels: true,
    config: {
      schemaType: 'enum',

      options: [
        { key: 'ALL', value: 'ALL', label: 'country.all' },
        { key: 'LT', value: 'LT', label: 'country.LT' },
        { key: 'LV', value: 'LV', label: 'country.LV' },
        { key: 'EE', value: 'EE', label: 'country.EE' },
        { key: 'PL', value: 'PL', label: 'country.PL' },
      ]
    }
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    isBaseFilter: true,
    isFilterable: true,
    sortingOrder: 1,
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      step: 10,
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    sortingOrder: -1,
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  }
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Relevance key is used with keywords filter.
  // Keywords filter also sorts results according to relevance.
  relevanceFilter: 'keywords',

  // Keyword filter is sorting the results by relevance.
  // If keyword filter is active, one might want to disable other sorting options
  // by adding 'keyword' to this list.
  conflictingFilters: [],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

export const listing = {
  enumFieldDetails: ['brand', 'model', 'year', 'frameMaterial', 'wheelSize', 'frameColor', 'receiptAvailable'],
};
