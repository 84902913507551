/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react'
import PropTypes from 'prop-types'

import { propTypes } from '../../../util/types'
import { ensureCurrentUser, userAbbreviatedName } from '../../../util/data'

import { NamedLink } from '../../../components'

import css from './TopbarMobileMenu.module.css'

import { LOCALES } from '../../../translations/locales'
import Select from 'react-select'

import LtFlag from '../../../assets/icons/lt-flag.png'
import UkFlag from '../../../assets/icons/uk-flag.png'
import PlFlag from '../../../assets/icons/pl-flag.png'

import ArrowIcon from '../../../assets/icons/right-arrow.png'
import MessageIcon from '../../../assets/icons/message.png'
import SettingIcon from '../../../assets/icons/setting.png'
import FavoriteIcon from '../../../assets/icons/hearts/outlined-24.png'
import InfoIcon from '../../../assets/icons/info-24.png'

import { compose } from 'redux'
import { withRouter } from 'react-router'

import Spacer, { HORIZONTALL_AXIS, LARGE_SPACER, MEDIUM_SPACER, SMALL_SPACER, VERTICAL_AXIS } from '../../Spacer/Spacer'

const TopbarMobileMenuComponent = props => {
  const {
    isAuthenticated,
    currentUser,
    notificationCount,
    onLogout,
    intl,
    history,
    onLocaleChange,
    currentLocale,
    onClose
  } = props

  const user = ensureCurrentUser(currentUser)

  const onLogoutAction = (e) => {
    e.preventDefault()
    e.stopPropagation()
    onLogout()
    onClose()
  }

  const CountrySwitcher = () => {
    const options = [
      { 
        value: LOCALES.ENGLISH, 
        label: intl.formatMessage({ id: 'locales.english' }), 
        icon: UkFlag
      },
      { 
        value: LOCALES.LITHUANIAN, 
        label: intl.formatMessage({ id: 'locales.lithuanian' }), 
        icon: LtFlag
      },
      { 
        value: LOCALES.POLISH, 
        label: intl.formatMessage({ id: 'locales.polish' }), 
        icon: PlFlag
      },
    ]

    return (
      <div className={css.countrySwitch}>
        <Select
          options={options}
          value={options ? options.find((option) => option.value === currentLocale) : ""}
          onChange={onLocaleChange}
          menuPlacement='auto'
          isSearchable={false}
          formatOptionLabel={option => (
            <div className={css.localeOption}>
              <img className={css.countryLogo} src={option.icon} alt={`language-image-${option.label}`} />
              <span className={css.countryName}>{option.label}</span>
            </div>
          )}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              height: '48px',
              fontSize: '16px',
              borderColor: '#D1D5DB',
              borderRadius: '4px',
              '&:hover': {
                borderColor: '#235641',
              }
            }),
          }}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: '#D1D5DB',
              primary: '#235641',
            },
          })}
        />
      </div>
    )
  }

  const sellBikeLink = (
    <NamedLink name="NewListingPage" className={css.loginLink}>
      <h5>{intl.formatMessage({ id: 'top_bar.sell' })}</h5>
      <img className={css.arrowImage} src={ArrowIcon}/>
    </NamedLink>
  )

  const buyBikeLink = (
    <NamedLink
      name="SearchPage"
      className={css.loginLink}
      state={{ prevPath: history.location.pathname }}
    >
      <h5>{intl.formatMessage({ id: 'top_bar.mobile_menu.buy' })}</h5>
      <img className={css.arrowImage} src={ArrowIcon}/>
    </NamedLink>
  )

  const aboutLink = (
    <NamedLink name="AboutPage" className={css.loginLink}>
      <img className={css.menuCellIcon} src={InfoIcon}/>
      <Spacer axis={HORIZONTALL_AXIS} size={MEDIUM_SPACER}/>
      <h5>{intl.formatMessage({ id: 'top_bar.mobile_menu.about_page' })}</h5>
      <img className={css.arrowImage} src={ArrowIcon}/>
    </NamedLink>
  )

  const favoriteListingsLink = (
    <NamedLink 
      name="FavoriteListingsPage"
      className={css.loginLink}
      params={{ id: currentUser?.id?.uuid || 1 }}
      state={{ prevPath: history.location.pathname }}
    >
      <img className={css.menuCellIcon} src={FavoriteIcon}/>
      <Spacer axis={HORIZONTALL_AXIS} size={MEDIUM_SPACER}/>
      <h5>{intl.formatMessage({ id: 'top_bar.mobile_menu.favorite_listings' })}</h5>
      <img className={css.arrowImage} src={ArrowIcon}/>
    </NamedLink>
  )

  if (!isAuthenticated) {
    return (
      <div className={css.root}>
        <div className={css.content} >
          <div className={css.menuWrapper} onClick={onClose}>
            <div className={css.menuCell} onClick={onClose}>
              {buyBikeLink}
            </div>
            <div className={css.menuCell} onClick={onClose}>
              {sellBikeLink}
            </div>
            <div className={css.menuCell} onClick={onClose}>
              {aboutLink}
            </div>
          </div>
          <Spacer size={8}/>
          <CountrySwitcher/>
          <div className={css.loginButtonWrapper}>
            <NamedLink name="LoginPage" className={css.actionButton}>
              {intl.formatMessage({ id: "top_bar.log_in" })}
            </NamedLink>
          </div>
        </div>
      </div>
    )
  }

  const notificationCountBadge =
    notificationCount > 0 ? <div className={css.notificationDot} /> : null

  const displayName = user.attributes.profile.firstName

  const inboxLink = (
    <NamedLink
      className={css.loginLink}
      name="InboxPage"
      params={{ lastLocation: history.location.pathname }}
      state={{ prevPath: history.location.pathname }}
    >
      <div className={css.inboxIconWrapper}>
        <img className={css.menuCellIcon} src={MessageIcon}/>
        {notificationCountBadge}
      </div>
      <Spacer axis={HORIZONTALL_AXIS} size={MEDIUM_SPACER}/>
      <h5>{intl.formatMessage({ id: 'top_bar.inbox_label' })}</h5>
      <img className={css.arrowImage} src={ArrowIcon}/>
    </NamedLink>
  )

  const accountSettingsLink = (
    <NamedLink className={css.loginLink} name="AccountSettingsPage">
      <img className={css.menuCellIcon} src={SettingIcon}/>
      <Spacer axis={HORIZONTALL_AXIS} size={MEDIUM_SPACER}/>
      <h5>{intl.formatMessage({ id: 'top_bar.account_settings_label' })}</h5>
      <img className={css.arrowImage} src={ArrowIcon}/>
    </NamedLink>
  )

  const abbreviatedName = userAbbreviatedName(currentUser, '')

  return (
    <div className={css.root}>
      <div onClick={onClose}>
        <NamedLink 
        className={css.userCellWrapper}
        name="ProfilePage"
        params={{ id: currentUser?.id?.uuid || 1 }}
      >
        <div className={css.userProfileWrapper}>
          <h2 className={css.userName}>{abbreviatedName}</h2>
        </div>
        <div className={css.userInfo}>
          <label className={css.displayName}>{displayName}</label>
          <span>{intl.formatMessage({ id: 'top_bar.mobile_menu.show_profile' })}</span>
        </div>
        <img className={css.arrowImage} src={ArrowIcon}/>
      </NamedLink>
      </div>
      
      <div className={css.menuWrapper} onClick={onClose}>
        <div className={css.menuCell}>
          {inboxLink}
        </div>
        <div className={css.menuCell}>
          {favoriteListingsLink}
        </div>
        <div className={css.menuCell}>
          {accountSettingsLink}
        </div>
      </div>
      <Spacer size={30}/>
      <div className={css.menuWrapper} onClick={onClose}>
        <div className={css.menuCellTitleWrapper}>
          <span className={css.menuCellTitle}>NextVelo</span>
        </div>
        <div className={css.menuCell}>
          {aboutLink}
        </div>
        <Spacer size={8}/>
        <div className={css.menuCellLogOut} onClick={(e) => onLogoutAction(e)}>
          <span className={css.menuCellLogOutTitle}>{intl.formatMessage({ id: 'top_bar.log_out' })}</span>
        </div>
      </div>
      <CountrySwitcher/>
      <Spacer size={LARGE_SPACER}/>
    </div>
  )
}

TopbarMobileMenuComponent.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null }

const { bool, func, number, string } = PropTypes

TopbarMobileMenuComponent.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
}

const TopbarMobileMenu = compose(withRouter)(TopbarMobileMenuComponent)

export default TopbarMobileMenu
