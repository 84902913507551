import React from 'react';
import PropTypes from 'prop-types';

import BrandLogo from './nextvelo-logo.svg'

const Logo = props => {
  const { className, format, ...rest } = props;

  return <img className={className} src={BrandLogo}/>
};

const { oneOf, string } = PropTypes;

Logo.defaultProps = {
  className: null,
  format: 'desktop',
};

Logo.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile']),
};

export default Logo;
