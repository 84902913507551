import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';

import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, StaticRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { IntlProvider } from './util/reactIntl';
import { IncludeMapLibraryScripts } from './util/includeScripts';
import configureStore from './store';
import routeConfiguration from './routing/routeConfiguration';
import Routes from './routing/Routes';

import { LOCALES } from './translations/locales'
import { messages } from './translations/messages'

export const ClientApp = props => {
  const { store } = props;

  const host = window.location.host;
  const defaultLocale = host === 'nextvelo.pl' || host === 'www.nextvelo.pl' || host === "sandbox-pl.nextvelo.net"
    ? LOCALES.POLISH
    : LOCALES.ENGLISH;

  const getInitialLocale = () => {
    return localStorage.getItem("language_locale") || defaultLocale;
  }

  const [currentLocale, setCurrentLocale] = useState(getInitialLocale())

  const changeLocale = (option) => {
    setCurrentLocale(option.value)
    localStorage.setItem("language_locale", option.value);
  }

  return (
    <IntlProvider
      locale={currentLocale}
      messages={messages[currentLocale]}
      defaultLocale={LOCALES.ENGLISH}
      textComponent="span"
    >
      <Provider store={store}>
        <HelmetProvider>
          <IncludeMapLibraryScripts />
          <BrowserRouter>
            <Routes
              host={host}
              routes={routeConfiguration()} 
              onLocaleChange={changeLocale}
              currentLocale={currentLocale}
            />
          </BrowserRouter>
        </HelmetProvider>
      </Provider>
    </IntlProvider>
  );
};

const { any, string } = PropTypes;

ClientApp.propTypes = { store: any.isRequired };

export const ServerApp = props => {
  const { url, host, context, helmetContext, store } = props;

  const defaultLocale = host === 'nextvelo.pl' || host === 'www.nextvelo.pl' || host === "sandbox-pl.nextvelo.net"
    ? LOCALES.POLISH
    : LOCALES.ENGLISH;

  const [currentLocale, setCurrentLocale] = useState(defaultLocale)

  const changeLocale = (option) => {
    setCurrentLocale(option.value)
  }

  HelmetProvider.canUseDOM = false;
  return (
    <IntlProvider
      locale={currentLocale}
      messages={messages[currentLocale]}
      defaultLocale={LOCALES.ENGLISH}
      textComponent="span"
    >
      <Provider store={store}>
        <HelmetProvider context={helmetContext}>
          <IncludeMapLibraryScripts />
          <StaticRouter location={url} context={context}>
            <Routes 
              host={host}
              routes={routeConfiguration()} 
              onLocaleChange={changeLocale}
              currentLocale={currentLocale}
            />
          </StaticRouter>
        </HelmetProvider>
      </Provider>
    </IntlProvider>
  );
};

ServerApp.propTypes = { url: string.isRequired, context: any.isRequired, store: any.isRequired };

/**
 * Render the given route.
 *
 * @param {String} url Path to render
 * @param {Object} serverContext Server rendering context from react-router
 *
 * @returns {Object} Object with keys:
 *  - {String} body: Rendered application body of the given route
 *  - {Object} head: Application head metadata from react-helmet
 */
export const renderApp = (
  url,
  host,
  serverContext,
  preloadedState,
  hostedTranslations,
  collectChunks
) => {
  // Don't pass an SDK instance since we're only rendering the
  // component tree with the preloaded store state and components
  // shouldn't do any SDK calls in the (server) rendering lifecycle.
  const store = configureStore(preloadedState);

  const helmetContext = {};

  // When rendering the app on server, we wrap the app with webExtractor.collectChunks
  // This is needed to figure out correct chunks/scripts to be included to server-rendered page.
  // https://loadable-components.com/docs/server-side-rendering/#3-setup-chunkextractor-server-side
  const WithChunks = collectChunks(
    <ServerApp
      url={url}
      host={host}
      context={serverContext}
      helmetContext={helmetContext}
      store={store}
      hostedTranslations={hostedTranslations}
    />
  );
  const body = ReactDOMServer.renderToString(WithChunks);
  const { helmet: head } = helmetContext;
  return { head, body };
};
