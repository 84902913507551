import {createPortal} from 'react-dom'
import { useDispatch } from 'react-redux'
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import { closeListing } from './../../../containers/ProfilePage/ProfilePage.duck'
import { Button } from './../../../components';
import css from './../ListingCard.module.css'
import ModalComponent from './../../ModalComponent/ModalComponent'
import FieldCheckboxGroup from './../../FieldCheckboxGroup/FieldCheckboxGroup';
import { requiredFieldArrayCheckbox } from './../../../util/validators';

const DeleteListingModal = props => {
    const { onCloseModal, intl, listingId } = props
    const dispatch = useDispatch()
    const translate = id => intl.formatMessage({ id: `profile_page.delete_modal.${id}` })

    const handleDeleteListing = (values) => {
        const reason = values.reason[0]

        dispatch(closeListing(listingId, reason))
        onCloseModal()
    }

    const handleModalClose = () => {
        onCloseModal()
    }

    const options = [
      {
        key: 'nextvelo',
        label: translate('reason.nextvelo'),
      }, {
        key: 'outside',
        label: translate('reason.outside'),
      }, {
        id: 'other',        key: 'other',
        label: translate('reason.other'),
      }
    ]

    const Modal =  (
        <ModalComponent onClose={onCloseModal}>
          <div className={css.deleteListingModal}>
            <h1 className={css.deleteListingModalTitle}>
              {translate('title' )}
            </h1>
            <Form
              onSubmit={handleDeleteListing}
              mutators={{ ...arrayMutators }}
              render={props => {
                const { handleSubmit, invalid, form } = props

                const handleOnChange = (event) => {
                  form.change('reason', [event.target.value])
                }

                return (
                  <form onSubmit={handleSubmit} onChange={handleOnChange}>
                    <FieldCheckboxGroup
                      className={css.checkboxes}
                      validate={requiredFieldArrayCheckbox('not ok')}
                      id="reason"
                      name='reason'
                      label={translate('subtitle')}
                      options={options}
                    />
                    <Button
                      disabled={invalid}
                      type="submit"
                      className={css.deleteButton}
                    >
                      {translate('delete_action')}
                    </Button>
                    <Button
                      className={css.closeButton}
                      onClick={handleModalClose}
                    >
                      {translate('cancel_action')}
                    </Button>
                </form>
                )
              }}
            />
          </div>
        </ModalComponent>
    )

    return createPortal(Modal, document.body)
  }

  export default DeleteListingModal
