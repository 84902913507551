import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import css from './TermsOfService.module.css'
import Spacer, { MEDIUM_SPACER } from '../Spacer/Spacer'

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Paskutinis atnaujinimas: Gegužės 15, 2023</p>
      <h1 className={css.title}>ELEKTRONINĖS PARDUOTUVĖS NAUDOJIMOSI TAISYKLĖS</h1>
      <ol>
        <li className={css.listHeader}>
            Bendrosios nuostatos
          <Spacer size={MEDIUM_SPACER}/>
          <ol>
            <li>
                Šios elektroninės parduotuvės Nextvelo (toliau – Parduotuvė)
                naudojimosi taisyklės (toliau –Taisyklės) yra taikomos kiekvienam
                kliento (toliau – Klientas) apsipirkimui Parduotuvėje bei kiekvienai
                tarp Kliento ir pardavėjo sudaromai pirkimo-pardavimo sutarčiai.
              <Spacer size={MEDIUM_SPACER}/>
            </li>
            <li>
                Klientas patvirtina Taisykles su jomis susipažinęs bei pažymėdamas
                atitinkamą žymę užsakymo metu. Tokiu būdu patvirtintos Taisyklės
                tampa šalims privalomas teisinis dokumentas, kuriame nustatomos
                Kliento ir pardavėjo teisės bei pareigos, paslaugų-prekių įsigijimo
                bei apmokėjimo už jas sąlygos, prekių pristatymo ir grąžinimo
                tvarka, šalių atsakomybė bei kitos su paslaugų-prekių pirkimu
                susijusios sąlygos.
              <Spacer size={MEDIUM_SPACER}/>
            </li>
            <li>
                Aiškumo tikslas nurodoma, kad nėra galimos situacijos, kai Klientas
                sutinka ir įsipareigoja laikytis Taisyklių su tam tikromis
                išimtimis.
              <Spacer size={MEDIUM_SPACER}/>
            </li>
            <li>
                Pardavėjas turi teisę atsisakyti teikti paslaugas asmenims,
                pažeidusiems bent vieną iš apačioje pateikiamų Taisyklių nuostatų.
              <Spacer size={MEDIUM_SPACER}/>
            </li>
            <li>
                Pardavėjas pasilieka teisę bet kuriuo metu pakeisti, taisyti ar
                papildyti šias Taisykles. Apie Taisyklių pasikeitimus Klientas
                informuojamas Parduotuvės tinklapyje. Klientui darant užsakymus
                Parduotuvėje, taikomos Taisyklės, galiojančios užsakymo pateikimo
                momentu.
              <Spacer size={MEDIUM_SPACER}/>
            </li>
          </ol>
        </li>
        <li className={css.listHeader}>
          Prekių užsakymas ir apmokėjimas
          <Spacer size={MEDIUM_SPACER}/>
          <ol>
            <li>
              Pirkimo-pardavimo sutartis tarp Kliento ir pardavėjo laikoma sudaryta, 
              kuomet Klientas Parduotuvėje suformuoja ir pateikia paslaugų-prekių užsakymą, 
              prieš tai susipažinęs su šiomis Taisyklėmis, Parduotuvės privatumo politika.
              <Spacer size={MEDIUM_SPACER}/>
            </li>
            <li>
              Pasirinkdamas paslaugas-prekes Klientas patvirtina turintis pakankamai kompetencijos 
              pasirinkti jam tinkamų savybių paslaugas-prekes, atitinkamai Klientas negali reikšti 
              pretenzijų šiuo pagrindu.
              <Spacer size={MEDIUM_SPACER}/>
            </li>
            <li>
              Suformavus paslaugų-prekių užsakymą, Klientas turi suvesti duomenis, būtinus pasirinktoms 
              paslaugoms-prekėms suteikti-pristatyti: savo vardą, pavardę, adresą, kuriuo bus pristatomos 
              paslaugos-prekės, telefoną bei papildomą informaciją, kuri gali būti svarbi paslaugoms ir (ar) 
              pristatant užsakytas prekes ar išrašant sąskaitą. Parduotuvė patvirtina, kad Kliento pateikti duomenys 
              bus naudojami išskirtinai tik paslaugų-prekių pardavimo bei pristatymo ir sąskaitų išrašymo tikslais, 
              nepažeidžiant Lietuvos Respublikos teisės aktų numatytų reikalavimų asmens duomenų apsaugai ir kaip nurodyta 
              Privatumo politikoje.
              <Spacer size={MEDIUM_SPACER}/>
            </li>
            <li>
              Prieš patvirtinant užsakymą Klientas turi pasirinkti vieną iš atsiskaitymo bei paslaugų-prekių pristatymo 
              būdų iš galimų Parduotuvės pateikiamų variantų. Atsiskaityti galima:
              <ul className={css.dashed}>
                <li className={css.dashed_item}>naudojantis įvairių bankų elektroninės bankininkystės paslaugomis. Mokėjimus apdoroja [________________] mokėjimų platforma;</li>
                <li className={css.dashed_item}>Paypal.com mokėjimų platforma;</li>
                <li className={css.dashed_item}>tiesioginiu pavedimu į MB Nextvelo, sąskaitą [_____] banke: [_____________].</li>
              </ul>
              <Spacer size={MEDIUM_SPACER}/>
            </li>
            <li>
              Klientui patvirtinus užsakymą, jis nusiunčiamas į Parduotuvę. Užsakymas laikomas pateiktu 
              nuo to momento, kai jį gauna Parduotuvė. Tačiau užsakymas laikomas vykdytinu tik nuo to 
              momento, kai Parduotuvė gauna patvirtinimą apie apmokėjimą už užsakymą iš institucijos, 
              įstaigos, kurią atsiskaitymams pasirinko Klientas.
              <Spacer size={MEDIUM_SPACER}/>
            </li>
            <li>
              Klientui pateikus užsakymą, Parduotuvės sistema automatiškai atsiunčia Klientui elektroninį 
              laišką apie gautą užsakymą. Klientas taip pat yra informuojamas elektroniniu laišku apie pradėta 
              vykdyti užsakymą. 
              <Spacer size={MEDIUM_SPACER}/>
            </li>
            <li>
              Jei užsakymas neapmokamas per 5 (penkias) darbo dienas, užsakymas Pardavėjo pasirinkimu gali būti anuliuojamas.
              <Spacer size={MEDIUM_SPACER}/>
            </li>
            <li>
              Kliento pateiktas užsakymas atitinkamą laikotarpį gali būti saugomas Parduotuvės duomenų bazėje remiantis 
              Lietuvos Respublikos galiojančių teisės aktų reikalavimais dėl tokių duomenų saugojimo termino.
              <Spacer size={MEDIUM_SPACER}/>
            </li>
            <li>
              Elektroninė sąskaita už užsakymą suformuojama ir Klientui išsiunčiama Kliento nurodytu elektroniniu 
              paštu tuomet, kai Parduotuvė išsiunčia užsakymą. Klientas taip gali atsisiųsti elektroninę sąskaitą 
              prisijungęs prie savo paskyros Parduotuvėje, jei tokią turi. 
              <Spacer size={MEDIUM_SPACER}/>
            </li>
            <li>
              Visais atvejais laikoma, jog Klientas, pateikdamas užsakymą, yra susipažinęs ir sutinka su 
              visomis Parduotuvės Taisyklėmis, kaip ir su visomis kitomis prie užsakymo nurodytomis sąlygomis.
              <Spacer size={MEDIUM_SPACER}/>
            </li>
          </ol>
        </li>
        <li className={css.listHeader}>
          Kainos nustatymas ir garantijos
          <Spacer size={MEDIUM_SPACER}/>
          <ol>
            <li>
              Kiekvienos paslaugos-prekės kaina bei ypatybės bendrai nurodomos prie kiekvienos prekės 
              Parduotuvėje, kartu ar atskirai nurodant paslaugas, kurias Klientas gali įsigyti. 
              <Spacer size={MEDIUM_SPACER}/>
            </li>
            <li>
              Klientas sutinka, jog jam elektroniniu būdu pateikiant užsakymą Parduotuvėje, 
              už paslaugas-prekes jis įsipareigoja mokėti kainą eurais, nurodomą galutiniame užsakyme. 
              Kainos Parduotuvėje bei užsakyme nurodomos su Pridėtinės vertės mokesčiu (PVM). Taip pat prie bendros 
              paslaugų-prekių sumos prisideda pristatymo mokestis, jeigu toks yra taikomas.
              <Spacer size={MEDIUM_SPACER}/>
            </li>
            <li>
              Parduotuvė nurodo, o Klientas patvirtina, jog jam žinoma, kad prekės, nurodytos 
              Parduotuvėje, savo spalva, forma ar kitais parametrais gali neatitikti realaus prekių 
              dydžio, formų ir spalvos dėl Kliento naudojamos techninės įrangos (pvz. monitoriaus) ypatybių.
              <Spacer size={MEDIUM_SPACER}/>
            </li>
            <li>
              Parduotuvė garantuoja parduoti prekes, teikti paslaugas atitinkančias užsakyme 
              numatytas sąlygas. Tais atvejais, kai Klientui perduotos prekės, suteiktos 
              paslaugos neatitinka tam tikrų užsakymo reikalavimų, Klientas sutinka pirmiausia 
              nedelsiant pranešti apie tai Parduotuvei el. paštu arba kitais jos nurodytais kontaktais, 
              o ši įsipareigoja imtis visų veiksmų esamiems trūkumams pašalinti, jeigu tokie trūkumai 
              atsirado dėl Parduotuvės. Parduotuvei per su Klientu suderintą protingą terminą trūkumų 
              nepašalinus, Klientas turi teisę savo teises ginti Lietuvos Respublikos teisės aktuose 
              numatyta tvarka.
              <Spacer size={MEDIUM_SPACER}/>
            </li>
          </ol>
        </li>
        <li className={css.listHeader}>
          Pardavėjo teisės ir įsipareigojimai
          <Spacer size={MEDIUM_SPACER}/>
          <ol>
            <li>
              Pardavėjas turi teisę:
              <Spacer size={MEDIUM_SPACER}/>
              <ol>
                <li>
                  Susidarius svarbioms aplinkybėms, pardavėjas gali laikinai arba visiškai sustabdyti Parduotuvės veiklą, apie tai iš anksto nepranešęs Klientui;
                  <Spacer size={MEDIUM_SPACER}/>
                </li>
                <li>
                  Jeigu Klientas bando pakenkti Parduotuvės darbui, stabilumui ir saugumui ar pažeidžia savo įsipareigojimus, pardavėjas turi teisę be perspėjimo apriboti ar sustabdyti jam galimybę naudotis Parduotuve.
                  <Spacer size={MEDIUM_SPACER}/>  
                </li>
                <li>
                  Kai dėl nenumatytų aplinkybių, pardavėjas negali pristatyti Parduotuvėje užsakytų paslaugų-prekų, pardavėjas turi teisę nutraukti pirkimo-pardavimo sutartį, prieš tai informavęs Klientą. Pardavėjas gali pasiūlyti analogišką prekę, tačiau Klientui atsisakius, pardavėjas įsipareigoja grąžinti sumokėtus pinigus per 10 (dešimt) darbo dienų.
                  <Spacer size={MEDIUM_SPACER}/>
                </li>
              </ol>
              <Spacer size={MEDIUM_SPACER}/>
            </li>
            <li>
              Pardavėjas įsipareigoja:
              <Spacer size={MEDIUM_SPACER}/>
              <ol>
                <li>
                  Sudaryti galimybę naudotis Parduotuve, kurios veikimo sąlygas nustato šios Taisyklės;
                  <Spacer size={MEDIUM_SPACER}/>
                </li>
                <li>
                  Kliento užsakytas paslaugas-prekes kaip pristatyti Kliento pasirinktu pristatymo būdu, adresu remdamasis šiomis Taisyklės;
                  <Spacer size={MEDIUM_SPACER}/>
                </li>
                <li>
                  Klientui pasinaudojus Taisyklių 5.1.2. punkte numatyta teise, pardavėjas įsipareigoja grąžinti Klientui sumokėtus pinigus ne vėliau kaip per 10 (dešimt) darbo dienų, nuo grąžinamos prekės gavimo dienos.
                  <Spacer size={MEDIUM_SPACER}/>
                </li>
              </ol>
              <Spacer size={MEDIUM_SPACER}/>
            </li>
            <li>
              Pardavėjas gali negrąžinti Klientui jo sumokėtos pinigų sumos tol, kol prekės negrąžintos pardavėjui.
              <Spacer size={MEDIUM_SPACER}/>
            </li>
          </ol>
        </li>
        <li className={css.listHeader}>
          Kliento teisės ir įsipareigojimai
          <Spacer size={MEDIUM_SPACER}/>
          <ol>
            <li>
              Klientas turi teisę:
              <Spacer size={MEDIUM_SPACER}/>
              <ol>
                <li>
                  pirkti paslaugas-prekes Parduotuvėje vadovaudamasis šiomis Taisyklėmis.
                  <Spacer size={MEDIUM_SPACER}/>
                </li>
                <li>
                  atsisakyti Parduotuvėje sudarytos prekių pirkimo-pardavimo sutarties, pranešdamas apie tai pardavėjui raštu ne vėliau kaip per 14 (keturiolika) kalendorinių dienų nuo prekės pristatymo dienos.
                  <Spacer size={MEDIUM_SPACER}/>
                </li>
              </ol>
              <Spacer size={MEDIUM_SPACER}/>
            </li>
            <li>
              5.1.2. punkte aptarta Kliento teise Klientas gali pasinaudoti tik tuo atveju, 
              jeigu prekė nebuvo sugadinta ir (ar) iš esmės nepasikeitė jos išvaizda, taip pat 
              jei ji nebuvo naudojama.
              <Spacer size={MEDIUM_SPACER}/>
            </li>
            <li>
              Prekių pristatymo metu, dalyvaujant kurjeriui ar pašto darbuotojui, Klientas 
              turi teisę patikrinti, ar siunta nebuvo atidaryta, ar pažeista pristatymo metu. 
              Jeigu pakuotė buvo atidaryta ar pažeista transportavimo metu, Klientas privalo 
              kartu su kurjeriu ar pašto darbuotoju surašyti aktą bei el. paštu informuoti Parduotuvę. 
              Tuo atveju jei Klientas pastebi, jog prekė yra pažeista, turi trūkumų, defektų, Klientas 
              nedelsiant turi pranešti pardavėjui.
              <Spacer size={MEDIUM_SPACER}/>
            </li>
            <li>
              Klientas įsipareigoja:
              <Spacer size={MEDIUM_SPACER}/>
              <ol>
                <li>
                  sumokėti už įsigytas paslaugas-prekes ir jas priimti šiose Taisyklėse numatyta tvarka.
                  <Spacer size={MEDIUM_SPACER}/>
                </li>
                <li>
                  jeigu Klientas, prekių pristatymo metu ar paslaugų suteikimo metu, nepagrįstai atsisako priimti paslaugas-prekes, pardavėjui pareikalavus Klientas privalo padengti prekių grąžinimo išlaidas ir (ar) suteiktų paslaugų išlaidas.
                  <Spacer size={MEDIUM_SPACER}/>
                </li>
                <li>
                  pasinaudojus Taisyklių 5.1.2 punkte nurodyta teise, grąžinti prekes prieš tai raštu informavus pardavėją bei vykdyti kitas Taisyklėse numatytas pareigas, kiek tai susiję su Taisyklių 5.1.2 punktu.
                  <Spacer size={MEDIUM_SPACER}/>
                </li>
              </ol>
              <Spacer size={MEDIUM_SPACER}/>
            </li>
          </ol>
        </li>
        <li className={css.listHeader}>
          Prekių išsiuntimas ir pristatymas
          <Spacer size={MEDIUM_SPACER}/>
          <ol>
            <li>
              Pristačiusiu siuntą (paslaugas-prekes) patikrinti siuntos ir prekės(-ių) būklę. 
              Tuo atveju, jei Klientas šiame minimo patikrinimo metu pastebi trūkumų, defektų, 
              bet apie juos nepraneša pardavėjui kaip nurodyta Taisyklių 5.3. punkte, Klientas 
              netenka teisės reikšti pretenzijų dėl paslaugų-prekių ateityje.
              <Spacer size={MEDIUM_SPACER}/>
            </li>
            <li>
              Klientas, užsakydamas paslaugas-prekes, įsipareigoja nurodyti paslaugų-prekių pristatymo vietą.
              <Spacer size={MEDIUM_SPACER}/>
            </li>
            <li>
              Klientas įsipareigoja prekes priimti pats. Tuo atveju, kai jis prekių pats priimti negali, 
              o prekės pristatytos nurodytu adresu ir remiantis Kliento pateiktais duomenimis, Klientas 
              neturi teisės reikšti pardavėjui pretenzijų dėl paslaugų suteikimo, prekių pristatymo 
              netinkamai ar netinkamam subjektui. Parduotuvė taip pat neatsako už užsakymų pristatymo 
              vėlavimus ar nepristatymą Klientui nurodžius klaidingą adresą.
              <Spacer size={MEDIUM_SPACER}/>
            </li>
            <li>
              Paslaugas-prekes pristato pardavėjas, jo įgaliotas atstovas ir (ar) pardavėjo pasitelkti asmenys. 
              Tuo atveju, jei paslaugas-prekes pristato pasitelkti asmenys, pardavėjas nėra atsakingas už 
              pasitelktų asmenų veiksmus, kai pastarieji yra nulemti pasitelktų asmenų neatsargumo ar tyčinių veiksmų. 
              <Spacer size={MEDIUM_SPACER}/>
            </li>
            <li>
              Užsakymai išsiunčiami pardavėjo darbo metu, nebent Klientas ir pardavėjas susitaria kitaip.
              <Spacer size={MEDIUM_SPACER}/>
            </li>
            <li>
              Pardavėjas įsipareigoja išsiųsti prekes Klientui laikydamasis prekių aprašymuose nurodytų 
              terminų. Šie terminai netaikomi tais atvejais, kai pardavėjo sandėlyje nėra reikiamų prekių, 
              o Klientas informuojamas apie tai. Kartu Klientas sutinka, jog išimtinais atvejais prekės gali 
              neatvykti, paslauga nebūti atlikta ir (ar) prekių išsiuntimas gali vėluoti dėl nenumatytų, 
              nuo pardavėjo nepriklausančių aplinkybių.
              <Spacer size={MEDIUM_SPACER}/>
            </li>
            <li>
              Visais atvejais pardavėjas atleidžiamas nuo atsakomybės už paslaugų nesuteikimą, 
              prekių pristatymo terminų pažeidimą, jeigu paslaugos, prekės Klientui nėra pristatomos 
              arba pristatomos ne laiku dėl Kliento kaltės, dėl nuo Kliento priklausančių aplinkybių 
              arba jei tai įvyksta dėl ne nuo pardavėjo priklausančių aplinkybių. 
              <Spacer size={MEDIUM_SPACER}/>
            </li>
          </ol>
        </li>
        <li className={css.listHeader}>
          Komentarų talpinimas
          <Spacer size={MEDIUM_SPACER}/>
          <ol>
            <li>
              Visas komentarų talpinimo komentaruose taisykles nustato ir jų laikymąsi kontroliuoja 
              Parduotuvė. Bet koks komentaro talpinimas Parduotuvėje automatiškai reiškia sutikimą 
              su visomis nustatytomis komentarų talpinimo taisyklėmis.
              <Spacer size={MEDIUM_SPACER}/>
            </li>
            <li>
              Parduotuvė visais atvejais turi teisę šalinti komentarus, pažeidžiančius 
              tiek Lietuvos Respublikos teisės aktus, kitų asmenų teises. Kartu pasilieka 
              teisę šalinti bet kokius komentaruose patalpintus komentarus savo nuožiūra.
              <Spacer size={MEDIUM_SPACER}/>
            </li>
            <li>
              Kiekvienas asmuo, talpindamas savo komentarą Parduotuvėje, patvirtina, 
              jog toks jo komentaras nepažeidžia ir nepažeis jokių trečiųjų asmenų teisių 
              bei prisiima atsakomybę dėl savo veiksmų, kurie pažeistų aukščiau nurodytus patvirtinimus.
              <Spacer size={MEDIUM_SPACER}/>
            </li>
          </ol>
        </li>
        <li className={css.listHeader}>
          Baigiamosios nuostatos
          <Spacer size={MEDIUM_SPACER}/>
          <ol>
            <li>
              Visi pranešimai Parduotuvei turi būti teikiami kontaktų puslapyje nurodytais 
              rekvizitais arba specialiose tam skirtose formose.
              <Spacer size={MEDIUM_SPACER}/>
            </li>
            <li>
              Šios Taisyklės sudarytos vadovaujantis Lietuvos Respublikos teisės aktais.
              <Spacer size={MEDIUM_SPACER}/>
            </li>
            <li>
              Šių Taisyklių pagrindu kylantiems santykiams taikoma Lietuvos Respublikos teisė.
              <Spacer size={MEDIUM_SPACER}/>
            </li>
            <li>
              Visi nesutarimai, kilę dėl šių Taisyklių vykdymo, sprendžiami derybų būdu. 
              Nepavykus susitarti, ginčai sprendžiami Lietuvos Respublikos įstatymų nustatyta tvarka.
              <Spacer size={MEDIUM_SPACER}/>
            </li>
          </ol>
        </li>
        <li>
          Rekvizitai
          <ul>
            <li>Adresas:</li>
            <li>Tel. nr.:</li>
            <li>El. paštas:</li>
            <li>Internetinė svetainė:</li>
          </ul>
          <Spacer size={MEDIUM_SPACER}/>
        </li>
      </ol>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
