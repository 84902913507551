import React from 'react';

export const SMALL_SPACER = 8
export const MEDIUM_SPACER = 16
export const LARGE_SPACER = 32
export const X_LARGE_SPACER = 64

export const VERTICAL_AXIS = 'vertical'
export const HORIZONTALL_AXIS = 'horizontal'

const Spacer = ({ size, axis = VERTICAL_AXIS }) => {
  const width = axis === 'vertical' ? '100%' : size;
  const height = axis === 'horizontal' ? '100%' : size;
  return (
    <div
      style={{
        width,
        height,
      }}
    />
  );
};
export default Spacer; 