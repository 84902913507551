import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { fetchConversationById, fetchUserConversations, markConversationAsRead, sendConversationMessage } from '../../util/api'
import { fetchCurrentUserNotifications } from '../../ducks/user.duck';

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/InboxPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/InboxPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/InboxPage/FETCH_ORDERS_OR_SALES_ERROR';

export const FETCH_ALL_CONVERSATIONS_REQUEST = 'app/InboxPage/FETCH_ALL_CONVERSATIONS_REQUEST'
export const FETCH_ALL_CONVERSATIONS_SUCCESS = 'app/InboxPage/FETCH_ALL_CONVERSATIONS_SUCCESS'
export const FETCH_ALL_CONVERSATIONS_ERROR = 'app/InboxPage/FETCH_ALL_CONVERSATIONS_ERROR'

export const FETCH_CURRENT_CONVERSATION_REQUEST = 'app/InboxPage/FETCH_CURRENT_CONVERSATION_REQUEST'
export const FETCH_CURRENT_CONVERSATION_SUCCESS = 'app/InboxPage/FETCH_CURRENT_CONVERSATION_SUCCESS'
export const FETCH_CURRENT_CONVERSATION_ERROR = 'app/InboxPage/FETCH_CURRENT_CONVERSATION_ERROR'

export const SEND_MESSAGE_REQUEST = 'app/InboxPage/SEND_MESSAGE_REQUEST'
export const SEND_MESSAGE_SUCCESS = 'app/InboxPage/SEND_MESSAGE_SUCCESS'
export const SEND_MESSAGE_ERROR = 'app/InboxPage/SEND_MESSAGE_ERROR'

export const MARK_AS_READ_REQUEST = 'app/InboxPage/MARK_AS_READ_REQUEST'
export const MARK_AS_READ_SUCCESS = 'app/InboxPage/MARK_AS_READ_SUCCESS'
export const MARK_AS_READ_ERROR = 'app/InboxPage/MARK_AS_READ_ERROR'

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  conversations: [],
  conversationMessages: [],
  transactionRefs: [],
  fetchConversationsInProgress: true,
  fetchConversationsError: false,
  conversations: [],
  fetchCurrentConversationInProgress: false,
  fetchCurrentConversationError: false,
  currentConversation: null,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload, options } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };
    
    case FETCH_ALL_CONVERSATIONS_REQUEST:
      return { ...state, fetchConversationsInProgress: true, fetchConversationsError: false }
    case FETCH_ALL_CONVERSATIONS_SUCCESS: {
      return {
        ...state,
        fetchConversationsInProgress: false,
        conversations: payload.conversations,
      }
    }
    case FETCH_ALL_CONVERSATIONS_ERROR:
      return { ...state, fetchConversationsInProgress: false, fetchConversationsError: true }

    case FETCH_CURRENT_CONVERSATION_REQUEST:
      return { 
        ...state, 
        currentConversation: options.resetConversation ? null : state.currentConversation,
        fetchCurrentConversationInProgress: true,
        fetchCurrentConversationError: false
      }
    case FETCH_CURRENT_CONVERSATION_SUCCESS: {
      return {
        ...state,
        fetchCurrentConversationInProgress: false,
        currentConversation: payload.conversation,
      }
    }
    case FETCH_CURRENT_CONVERSATION_ERROR:
      return { ...state, fetchCurrentConversationInProgress: false, fetchCurrentConversationError: true }
    
    case SEND_MESSAGE_REQUEST:
      return { ...state, sendMessageInProgress: true, sendMessageError: false }
    case SEND_MESSAGE_SUCCESS:
      return { ...state, sendMessageInProgress: false, sendMessageError: false }
    case SEND_MESSAGE_ERROR:
      return { ...state, sendMessageInProgress: false, sendMessageError: true }

    case MARK_AS_READ_REQUEST:
      return { ...state, markAsReadInProgress: true, markAsReadError: false }
    case MARK_AS_READ_SUCCESS:
      return { ...state, markAsReadInProgress: false, markAsReadError: false }
    case MARK_AS_READ_ERROR:
      return { ...state, markAsReadInProgress: false, markAsReadError: true }
    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

const fetchAllConversationsRequest = () => ({ type: FETCH_ALL_CONVERSATIONS_REQUEST })
const fetchAllConversationsSuccess = response => ({
  type: FETCH_ALL_CONVERSATIONS_SUCCESS,
  payload: response,
})
const fetchAllConversationsError = () => ({ type: FETCH_ALL_CONVERSATIONS_ERROR })

const fetchCurrentConversationRequest = (resetConversation) => ({ type: FETCH_CURRENT_CONVERSATION_REQUEST, options: {resetConversation: resetConversation} })
const fetchCurrentConversationSuccess = response => ({
  type: FETCH_CURRENT_CONVERSATION_SUCCESS,
  payload: response,
})
const fetchCurrentConversationError = () => ({ type: FETCH_CURRENT_CONVERSATION_ERROR })

const sendMessageRequest = () => ({ type: SEND_MESSAGE_REQUEST })
const sendMessageSuccess = () => ({ type: SEND_MESSAGE_SUCCESS })
const sendMessageError = () => ({ type: SEND_MESSAGE_ERROR })

const markAsReadRequest = () => ({ type: MARK_AS_READ_REQUEST })
const markAsReadSuccess = () => ({ type: MARK_AS_READ_SUCCESS})
const markAsReadError = () => ({ type: MARK_AS_READ_ERROR })

// ================ Thunks ================ //

const fetchCurrentConversation = (conversationId, resetConversation = true) => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentConversationRequest(resetConversation))

  return fetchConversationById(conversationId)
    .then(response => {
      dispatch(fetchCurrentConversationSuccess(response.data))
    })
    .catch(e => {
      dispatch(fetchCurrentConversationError())
      throw e
    })
}

export const sendMessage = (conversationId, body, photos) => (dispatch, getState, sdk) => {
  dispatch(sendMessageRequest())

  return sendConversationMessage(conversationId, body, photos)
    .then(response => {
      return dispatch(fetchCurrentConversation(conversationId, false))
        .then(res => {
          dispatch(sendMessageSuccess())
          return res
        })
        .catch(() => dispatch(sendMessageSuccess()))
    })
    .catch(e => {
      dispatch(sendMessageError())
    })
}

export const markAsRead = (conversationId) => (dispatch, getState, sdk) => {
  dispatch(markAsReadRequest())

  return markConversationAsRead(conversationId)
    .then(response => {
      return dispatch(fetchAllConversations())
        .then(res => {
          dispatch(markAsReadSuccess())
          return res
        })
        .catch(() => dispatch(markAsReadSuccess()))
    })
    .catch(e => {
      dispatch(markAsReadError())
    })
}

export const fetchAllConversations = () => (dispatch, getState, sdk) => {
  dispatch(fetchAllConversationsRequest())

  return fetchUserConversations()
    .then(response => {
      dispatch(fetchCurrentUserNotifications())
      dispatch(fetchAllConversationsSuccess(response.data))
      return response.data.conversations[0]?._id
    })
    .catch(e => {
      dispatch(fetchAllConversationsError())
      throw e
    })
}

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { tab } = params;

  return Promise.all([dispatch(fetchCurrentConversation(tab))])
};
