import React, { useState } from 'react'
import { string, func, bool } from 'prop-types'
import { intlShape, injectIntl } from '../../util/reactIntl'
import classNames from 'classnames'
import { propTypes } from '../../util/types'
import { formatMoney } from '../../util/currency'
import { ensureListing } from '../../util/data'
import { createSlug, LISTING_PAGE_PARAM_TYPE_EDIT } from '../../util/urlHelpers'
import { pathByRouteName } from '../../util/routes';
import routeConfiguration from '../../routing/routeConfiguration';
import { NamedLink } from '../../components'

import css from './ListingCard.module.css'
import { withRouter } from 'react-router-dom'
import ChevronIcon from '../../assets/icons/right-arrow.png'
import Spacer, { HORIZONTALL_AXIS, SMALL_SPACER } from '../Spacer/Spacer'
import DeleteListingModal from './DeleteListingModal'

const priceData = (price, intl) => {
  const formattedPrice = formatMoney(intl, price)
  return { formattedPrice, priceTitle: formattedPrice }
}

const ListingCardActionButtons = props => {
  const { id, slug, intl, listingType, onDeleteClick } = props

  const type = listingType != undefined
    ? listingType === 'parts'
      ? 'parts'
      : 'bike'
    : 'bike'

  const tab = type === 'bike' ? 'location' : 'categories'

  return (
    <>
      <div className={css.actionButtons}>
        <NamedLink
          name="EditListingPage"
          className={css.editListingButton}
          params={{ listingType: type, id: id, slug: slug, type: LISTING_PAGE_PARAM_TYPE_EDIT, tab: tab }}
        >
          {intl.formatMessage({ id: 'profile_page.edit_listing' })}
        </NamedLink>
        <Spacer size={SMALL_SPACER} axis={HORIZONTALL_AXIS}/>
        <button
          className={css.deleteListingButton}
          onClick={onDeleteClick}
          type='button'
        >
          {intl.formatMessage({ id: 'profile_page.delete_listing' })}
        </button>
      </div>
    </>
  )
}

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    history,
    isOwner
  } = props

  const classes = classNames(rootClassName || css.root, className)
  const currentListing = ensureListing(listing)
  const id = currentListing.id.uuid
  const { title = '', price, publicData } = currentListing.attributes
  const { listingType } = publicData
  const slug = createSlug(title)
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null

  const { formattedPrice, priceTitle } = priceData(price, intl)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const handleListingClick = (e) => {
    const element = e.target.nodeName.toLocaleLowerCase()

    if (element === 'button' || element === 'a')  return

    const routes = routeConfiguration()
    const path = pathByRouteName('ListingPageWithReturn', routes, { id, slug })

    const location = {
      pathname: path,
      state: { prevPath: history.location.pathname }
    }

    history.push(location)
  }

  const handleDeleteClick = () => {
    setIsDeleteModalOpen(true)
  }

  const handleModalClose = () => {
    setIsDeleteModalOpen(false)
  }

  return (
    <>
      <div className={classes} onClick={handleListingClick}>
        <img className={css.listingImage} src={firstImage.attributes.variants['listing-card'].url}/>
        <Spacer size={SMALL_SPACER} axis={HORIZONTALL_AXIS}/>
        <div className={css.info}>
          <div>
            <span className={css.itemTitle}>{title}</span>
            <div className={css.priceValue} title={priceTitle}>{formattedPrice}</div>
          </div>
          { isOwner ? <ListingCardActionButtons
            id={id}
            onDeleteClick={handleDeleteClick}
            slug={slug}
            intl={intl}
            listingId={currentListing.id}
            listingType={listingType}
          /> : null }
        </div>
        <div className={css.chevronWrapper}>
          <span className={css.chevronDescription}>
            {intl.formatMessage({ id: 'profile_page.listing.view' })}
          </span>
          <img className={css.chevron} src={ChevronIcon}/>
        </div>
      </div>
      { isDeleteModalOpen
        && <DeleteListingModal
            onCloseModal={handleModalClose}
            intl={intl}
            listingId={currentListing.id}
          />
      }
    </>
  )
}

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
}

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
}

export default injectIntl(withRouter(ListingCardComponent))
